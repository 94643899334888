/** @format */

// @flow

import React from 'react';
import styled, { type StyledComponent } from 'styled-components';

import { type Theme } from '@mollybet/ui/dist/theme';
import { Checkbox as Chx } from '@mollybet/ui';

export const Checkbox: StyledComponent<React.ElementConfig<typeof Chx>, Theme, *> = styled(
  Chx
).attrs((props) => ({
  ...props,
  theme: {
    ...props.theme,
    foreground: props.disabled
      ? props.theme.separator
      : props.color
      ? props.color
      : props.theme.foreground,
  },
}))``;
