/** @format */

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const XenaDiv = styled.div`
  background: ${(props) => props.theme[props.background] || props.theme.defaultbackground};
  color: ${(props) => props.theme[props.fontColor] || props.theme.defaultfontColor};
  border-color: ${(props) => props.theme[props.borderColor] || props.theme.defaultbordercolor};
`;

export const XenaSpan = styled.span`
  background: ${(props) => props.theme[props.background] || props.theme['defaultbackground']};
  color: ${(props) => props.theme[props.fontColor] || props.theme['defaultfontColor']};
  border-color: ${(props) => props.theme[props.borderColor] || props.theme['defaultbordercolor']};
`;

export const XenaLink = styled(Link)`
  background: ${(props) => props.theme[props.background] || props.theme['defaultbackground']};
  color: ${(props) => props.theme[props.fontColor] || props.theme['defaultfontColor']};
  border-color: ${(props) => props.theme[props.borderColor] || props.theme['defaultbordercolor']};
`;

export const XenaTd = styled.td`
  background: ${(props) => props.theme[props.background] || props.theme['defaultbackground']};
  color: ${(props) => props.theme[props.fontColor] || props.theme['defaultfontColor']};
  border-color: ${(props) => props.theme[props.borderColor] || props.theme['defaultbordercolor']};
`;
