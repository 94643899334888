/** @format */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  formatHandicap,
  toBetParams,
} from '@mollybet/frontend-common/dist/lib/betTypes';

export function inferTextStatus(status) {
  if (status === 'full_void')
    return <FormattedMessage id="order.statuses.fullVoid" defaultMessage="Full Void" />;
  else if (status === 'partial_void')
    return <FormattedMessage id="order.statuses.partialVoid" defaultMessage="Partial Void" />;
  else if (status === 'failed')
    return <FormattedMessage id="order.statuses.failed" defaultMessage="Failed" />;
  else if (status === 'done')
    return <FormattedMessage id="order.statuses.done" defaultMessage="Done" />;
  else if (status === 'reconciled')
    return <FormattedMessage id="order.statuses.reconciled" defaultMessage="Settled" />;
  else if (status === 'settled')
    return <FormattedMessage id="order.statuses.settled" defaultMessage="Settled" />;
  else if (status === 'placing')
    return <FormattedMessage id="order.statuses.placing" defaultMessage="Placing" />;
  else if (status === 'preparing')
    return <FormattedMessage id="order.statuses.preparing" defaultMessage="Preparing" />;
  else if (status === 'pending')
    return <FormattedMessage id="order.statuses.pending" defaultMessage="Pending" />;
  else if (status === 'open')
    return <FormattedMessage id="order.statuses.open" defaultMessage="Placing" />;
  else if (status === 'order')
    return <FormattedMessage id="order.statuses.order" defaultMessage="Order" />;
  else if (status === 'danger')
    return <FormattedMessage id="order.statuses.danger" defaultMessage="Danger" />;
  else if (status === 'tracking')
    return <FormattedMessage id="order.statuses.tracking" defaultMessage="Tracking" />;
  else if (status === 'unknown')
    return <FormattedMessage id="order.statuses.unknown" defaultMessage="Unknown" />;
  else return <span>{status}</span>;
}

export function translateBetbarStatus(status, closed) {
  if (status === 'success-stake')
    return <FormattedMessage id="betbar.stakeStatus.success" defaultMessage="success" />;
  else if (status === 'danger-stake')
    return <FormattedMessage id="order.stakeStatus.danger" defaultMessage="danger" />;
  else if (status === 'inprogress-stake')
    return <FormattedMessage id="order.stakeStatus.inprogress" defaultMessage="in progress" />;
  else if (status === 'unplaced-stake')
    return !closed ? (
      <FormattedMessage id="order.stakeStatus.unassigned" defaultMessage="unassigned" />
    ) : (
      <FormattedMessage id="order.statuses.unplaced" defaultMessage="unplaced" />
    );
  else return <span>{status}</span>;
}

export function translateBetType(betType, home, away, intl) {
  if (betType) {
    let isIr = betType.indexOf('ir') !== -1;
    let isAgainst = betType.startsWith('against');
    let betParams = toBetParams(betType);
    let offerGroup = betParams.offerGroup;
    let betTypeConfig = betParams.config;
    let handicap = betType.split(betParams.baseBetType + ',')[1];

    let shouldInvert = false;
    let atoms = betType.split(',');
    //has away or player 2
    if (atoms.indexOf('a') !== -1 || atoms.indexOf('p2') !== -1) {
      shouldInvert = true;
    }

    let homeScore = null;
    let awayScore = null;

    if (isIr) {
      let _last = betType.split('ir,')[1];
      homeScore = _last.split(',')[0];
      awayScore = _last.split(',')[1];
    }

    if (!betTypeConfig || !offerGroup) {
      console.warn('Unsupported bet type translation: ', betType);
      return '';
    }

    let descr = betTypeConfig.template;
    if (isIr && betTypeConfig.irTemplate) {
      descr = betTypeConfig.irTemplate;
    }

    return (
      (isAgainst
        ? intl.formatMessage({
            id: 'against',
            defaultMessage: 'AGAINST',
          }) + ' '
        : '') +
      intl.formatMessage(descr, {
        home,
        away,
        handicap: formatHandicap(handicap, shouldInvert, offerGroup),
        homeScore,
        awayScore,
      })
    );
  } else {
    return betType;
  }
}
