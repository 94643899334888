/** @format */

// @flow
import styled, { type StyledComponent } from 'styled-components';
import { type Theme } from '@mollybet/ui/dist/theme';

export const Paper: StyledComponent<
  { elevation?: number, zIndex?: number, overflow?: string, shadow?: boolean, ... },
  Theme,
  *
> = styled.div`
  font-size: ${(props) => props.theme.fontSize};

  border-radius: ${(props) => (props.noBr ? 0 : props.theme.paper.borderRadius)};
  box-shadow: ${(props) => (props.noShadow ? 'none' : props.theme.shadows[props.elevation || 0])};

  box-sizing: border-box;
  overflow: ${(props) => props.overflow || 'hidden'};
  z-index: ${(props) => props.zIndex || 1};
`;
