/** @format */

import { createTheme } from '@mollybet/ui';
import { colors, sizes } from './oldSassTheme.js';

const commonThemeData = {
  primary: '#838DFF',
  warning: '#ffb74d',
  negative: '#ff0000',
  positive: '#008060',
  fontColorBlack: '#000000',
  fontColorWhite: '#FFFFFF',
  xenaColorHighlight: '#838DFF',
  defaultbackground: 'inherit',
  paper: {
    background: ['#060606', '#333333', '#DDDDDD', '#F9F9FB', '#FFFFFF'],
    fontColor: ['#FFFFFF', '#FFFFFF', '#181818', '#181818', '#000000'],
    borderRadius: '4px',
  },
  ...colors,
  ...sizes,
};

export const darkUITheme = createTheme({
  ...commonThemeData,
  background: 'black',
  foreground: 'white',
  xenaColorHeader: '#000000',
  xenaColorTwo: '#313131',
  xenaColorDark: '#121212',
  xenaColorDarkest: '#000000',
  xenaColorWhite: '#FFFFFF',
  xenaColorGradient: 'linear-gradient(270deg, #313131 0%, #464646 100%)',
  fontColorContrast: '#FFFFFF',
  defaultfontColor: '#FFFFFF',
  defaultbordercolor: '#838DFF',
  marketHeader: '#111111',
});

export const lightUITheme = createTheme({
  ...commonThemeData,
  background: 'white',
  foreground: 'black',
  xenaColorHeader: '#000000',
  xenaColorTwo: '#D7CDCC',
  xenaColorDark: '#F8F8FF',
  xenaColorDarkest: '#F2F3F4',
  xenaColorWhite: '#DDDDDD',
  xenaColorGradient: 'linear-gradient(270deg, #EBEBEB 100%, #E7E7E7 100%)',
  fontColorContrast: '#000000',
  defaultfontColor: '#000000',
  defaultbordercolor: '#FFFFFF',
  marketHeader: '#FFFFFF',
});
