/** @format */

// @flow
import styled, { type StyledComponent } from 'styled-components';
import { type Theme } from '@mollybet/ui/dist/theme';

export const View: StyledComponent<{ ... }, Theme, *> = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  position: relative;
  z-index: 1;

  .view-contents {
    flex-grow: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
`;
