/** @format */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import config from '../../config';
import { actions } from '../../reducers/app';
import ParlayEntry from './ParlayEntry';
import currencies from '@mollybet/frontend-common/dist/lib/currencies';
import { SettingsContext } from '../shared/SettingsContext';
import { formatPrice, formatAmount } from '@mollybet/frontend-common/dist/lib/formatters';
import { withTheme } from 'styled-components';
import Telemetry from '@mollybet/frontend-common/dist/lib/Telemetry';
import { Button } from '../interface';
import { TextField } from '@mollybet/ui';
import {Parlays, ParlayBody, ParlayPlacement, AccaConfirmation} from './elements';
import {cleanNumberInput} from '../../lib/formatters'

// GBP MAX STAKE FOR PARLAY
const PARLAY_MAX_STAKE = 500;

class ParlayComp extends React.PureComponent {
  static contextType = SettingsContext;

  state = {
    fullCompare: false,
    selectedBookie: 'accas',
    showConfirmation: false,
  };

  _stakeInput = React.createRef();

  selectStakeInput = () => {
    setTimeout(() => {
      if (this._stakeInput.current) {
        this._stakeInput.current.select();
      }
    }, 0);
  };

  changeStake = (event) => {
    const { value: stake } = cleanNumberInput(event.target.value, 2, false);
    this.props.actions.parlayUpdateStake({
      stake,
      stakeParlayCcy: formatAmount(
        stake,
        this.context.displayCcy,
        this.props.parlayCcy,
        this.context.xrates,
        false,
        true
      ),
      stakeGBP: formatAmount(
        stake,
        this.context.displayCcy,
        'GBP',
        this.context.xrates,
        false,
        true
      ), //we need this for reasons
      parlayId: this.props.parlayId,
    });
  };

  cancelAcca = () => {
    this.setState({ showConfirmation: false });
  };

  confirmAcca = () => {
    this.placeOrder(true);
  };

  placeOrder = (orderConfirmed = false) => {
    if (
      this.props.confirmBet === false ||
      (this.props.confirmBet === true && orderConfirmed === true)
    ) {
      this.setState({ showConfirmation: false });
      this.props.actions.parlayPlace({
        parlayId: this.props.parlayId,
        priceType: this.context.priceType,
        bookie: this.state.selectedBookie,
        price: this.props.prices.get(this.state.selectedBookie, 1),
        actions: this.props.actions,
      });
      Telemetry.startRecording(`${this.props.parlayId}/as`, 'acca_speed');
    } else {
      this.setState({ showConfirmation: true });
    }
  };

  clearAllParlays = () => {
    this.props.actions.closeAllParlays({
      actions: this.props.actions,
    });
  };

  componentDidMount = () => {
    this.selectStakeInput();
  };

  deleteAcca = () => {
    if (this.props.accBarOpen) {
      this.props.actions.toggleAccBar();
    }
    this.props.actions.closeParlay({
      parlayId: this.props.parlayId,
      actions: this.props.actions,
    });
    this.props.actions.closeAllParlays({
      actions: this.props.actions,
    });
  }

  clearLocalAccas = () => {
    this.props.actions.clearAllParlaysInState();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.error === 'acc_not_found') {
      this.clearAllParlays();
      if (this.props.accBarOpen) {
        this.props.actions.toggleAccBar();
      }
    }
    if (!prevProps.hasPlaced && this.props.hasPlaced === true) {
      setTimeout(() => {
        if (this.props.accBarOpen) {
          this.props.actions.toggleAccBar();
        }
      }, 999)
      setTimeout(this.clearLocalAccas, 1000)
    }
  };

  render() {
    let entries = [];
    let _eventCount = {};

    if (this.props.entries && this.props.entries.size) {
      this.props.entries.forEach((entry, entryId) => {
        entries.push(
          <ParlayEntry
            key={entryId}
            entryId={entryId + ''}
            parlayId={this.props.parlayId}
            selectedBookie={this.state.selectedBookie}
            fullCompare={this.state.fullCompare}
          />
        );

        let prices = entry.get('prices', null);
        if (prices) {
          prices.forEach((price, bookie) => {
            if (price) {
              if (_eventCount[bookie] === undefined) {
                _eventCount[bookie] = 0;
              }
              _eventCount[bookie]++;
            }
          });
        }
      });
    }

    let reasonCannotPlaceAcc;
    if (!entries.length) {
      reasonCannotPlaceAcc = (
        <FormattedMessage id="trade.parlay.noSelections" defaultMessage="no selections" />
      );
    } else if (entries.length < config.parlays.minEvent) {
      reasonCannotPlaceAcc = (
        <FormattedMessage
          id="trade.parlay.tooFewEvents"
          defaultMessage="too few selections (min. {num})"
          values={{
            num: config.parlays.minEvent,
          }}
        />
      );
    } else if (!this.props.stake) {
      reasonCannotPlaceAcc = (
        <FormattedMessage id="trade.parlay.noStake" defaultMessage="no stake" />
      );
    } else if (parseFloat(this.props.stake) > this.props.availableCredit) {
      reasonCannotPlaceAcc = (
        <FormattedMessage id="trade.parlay.lowCredit" defaultMessage="low credit" />
      );
    } else if (!(parseFloat(this.props.stake) > 0)) {
      reasonCannotPlaceAcc = (
        <FormattedMessage id="trade.parlay.notAValidStake" defaultMessage="not a valid stake" />
      );
    } else if (
      config.parlays.minStake >
      formatAmount(
        this.props.stake,
        this.context.displayCcy,
        'GBP',
        this.context.xrates,
        false,
        true
      )
    ) {
      reasonCannotPlaceAcc = (
        <FormattedMessage
          id="trade.parlay.tooLow"
          defaultMessage="stake too low; min {amount}"
          values={{
            amount: formatAmount(
              config.parlays.minStake,
              'GBP',
              this.context.displayCcy,
              this.context.xrates
            ),
          }}
        />
      );
    } else if (
      formatAmount(
        this.props.stake,
        this.context.displayCcy,
        'GBP',
        this.context.xrates,
        false,
        true
      ) > PARLAY_MAX_STAKE
    ) {
      reasonCannotPlaceAcc = (
        <FormattedMessage
          id="trade.parlay.tooMuchStakeDetailed"
          defaultMessage="stake is over max amount: {amount}"
          values={{
            amount: formatAmount(
              PARLAY_MAX_STAKE,
              'GBP',
              this.context.displayCcy,
              this.context.xrates
            ),
          }}
        />
      );
    } else if (this.props.isPlacing) {
      reasonCannotPlaceAcc = <span></span>; //a blank reason
    } else if (this.props.hasPlaced) {
      reasonCannotPlaceAcc = <span></span>; //a blank reason
    } else if (_eventCount[this.state.selectedBookie] !== entries.length) {
      reasonCannotPlaceAcc = (
        <FormattedMessage
          id="trade.parlay.noBookieEvents"
          defaultMessage="bookie does not have all events"
        />
      );
    } else if (this.props.error === 'too_many_selections') {
      reasonCannotPlaceAcc = (
        <FormattedMessage
          id="trade.parlay.tooManySelections"
          defaultMessage="Too many bets in Acca"
        />
      );
    } else if (
      entries.length &&
      this.props.maxPlGbp !== undefined &&
      Math.floor((this.props.maxPlGbp / this.props.prices?.get('accas')) * 100) / 100 <
      formatAmount(
        this.props.stake,
          this.context.displayCcy,
          'GBP',
          this.context.xrates,
          false,
          true
        )
    ) {
      reasonCannotPlaceAcc = (
        <FormattedMessage
          id="trade.parlay.aboveMaxStake"
          defaultMessage="stake is above accumulator max"
        />
      );
    }

    return (
      <ParlayBody>
        <Parlays elevation={3} noBr="true" noShadow="true">
          {entries.length ? (
            entries
          ) : (
            <div className="no-entries">
              <FormattedMessage
                id="trade.parlay.noEntries"
                defaultMessage="no accumulator entries"
              />
            </div>
          )}
        </Parlays>
        <ParlayPlacement>
          {this.state.showConfirmation ? (
            <AccaConfirmation>
              <div className="confirmation-header">
                <div className="confirmation-title">
                  <FormattedMessage
                    id="trade.parlay.confirmAccaOrderPlacement"
                    defaultMessage="Confirm Acca Order"
                  />
                </div>
                <div className="order-actions">
                  <div className="cancel" onClick={this.cancelAcca}>
                    <FormattedMessage id="trade.betslip.cancel" defaultMessage="Cancel" />
                  </div>
                  <div className="confirm" onClick={this.confirmAcca}>
                    <FormattedMessage id="trade.betslip.confirm" defaultMessage="Confirm" />
                  </div>
                </div>
              </div>
              <div className="order-details">
                <div className="selected-bet">
                  {`${entries.length}x Acca ${formatAmount(
                    this.props.stake,
                    this.context.displayCcy,
                    this.props.parlayCcy,
                    this.context.xrates,
                    false,
                    true
                  )} @ ${formatPrice(this.props.prices.get('accas', 0), this.context.priceType)}`}
                </div>
              </div>
            </AccaConfirmation>
          ) : this.props.hasPlaced ? (
            <div className="order-placed">
              <FormattedMessage id="trade.parlay.hasPlaced" defaultMessage="Accumulator placed" />
            </div>
          ) : this.props.isPlacing ? (
            <div className="descriptor">
              <FormattedMessage id="trade.parlay.placingOrder" defaultMessage="Placing order..." />
            </div>
          ) : this.props.priceIsMaxed ? (
            <div className="parlay-error">
              <FormattedMessage
                id="trade.parlay.cannotAddMoreEvents"
                defaultMessage="Cannot add more selections because maximum price is {price}."
                values={{
                  price: formatPrice(config.parlays.maxPrice, this.context.priceType),
                }}
              />
            </div>
          ) : this.props.error === 'missing_price' ? (
            <div className="parlay-error">
              <FormattedMessage
                id="trade.parlay.selectionNotAvailable"
                defaultMessage="Selection not currently available for accumulator bet"
              />
            </div>
          ) : this.props.error && this.props.error !== 'too_many_selections' ? (
            <div className="parlay-error">
              {this.props?.error?.replace(/_/g, ' ')}
              <Button
                onClick={this.placeOrder}
                variant="primary"
                className="place-acca"
                disabled={!!reasonCannotPlaceAcc}
              >
                <FormattedMessage id="trade.parlay.retry" defaultMessage="Retry" />
              </Button>
            </div>
          ) : (
            <div className="parlay-placement-container">
              <div className="parlay-placement-input">
                <TextField
                  onChange={this.changeStake}
                  value={this.props.stake}
                  shrinked={true}
                  type="number"
                  inputRef={this._stakeInput}
                  inputmode="decimal"
                  label={
                    <FormattedMessage
                      id="trade.parlay.stake"
                      defaultMessage="Stake ({ccyCode})"
                      values={{
                        ccyCode: this.context.displayCcy
                          ? currencies[this.context.displayCcy].symbol
                          : '',
                      }}
                    />
                  }
                />
                <TextField
                  value={
                    this.props.prices
                      ? formatPrice(this.props.prices.get('accas', 0), this.context.priceType)
                      : '-'
                  }
                  disabled={true}
                  shrinked={true}
                  type="number"
                  inputmode="decimal"
                  label={<FormattedMessage id="trade.parlay.price" defaultMessage="Price" />}
                />
                <Button
                  onClick={this.placeOrder}
                  variant="primary"
                  className="place-acca"
                  disabled={!!reasonCannotPlaceAcc}
                >
                  <FormattedMessage id="trade.parlay.place" defaultMessage="Place" />
                </Button>
              </div>
              <div className="reason-cant-place">{reasonCannotPlaceAcc}</div>
            </div>
          )}
        </ParlayPlacement>
      </ParlayBody>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// turn state of combined reducers into state required by component
const mapStateToProps = (state, ownProps) => {
  let parlay = state.getIn(['parlays', 'parlays', ownProps.parlayId], null);

  return parlay
    ? {
        parlay,
        entries: parlay.get('entries', null),
        prices: parlay.get('prices', null),
        isPlacing: parlay.get('isPlacing', false),
        hasPlaced: parlay.get('hasPlaced', false),
        parlayCcy: parlay.get('parlayCcy', ''),
        maxPlGbp: parlay.get('maxPlGbp', 0),
        priceIsMaxed: parlay.get('priceIsMaxed', false),
        error: parlay.get('error', null),
        x: parlay.get('x', 100),
        y: parlay.get('y', 100),
        stake: parlay.get('stake', ''),
        accBarOpen: state.getIn(['ui', 'accBarOpen'], false),
        availableCredit: state.getIn(['base', 'profile', 'credit'], 0),
        confirmBet: state.getIn(['base', 'settings', 'trade', 'confirmBet'], false),
      }
    : {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(injectIntl(ParlayComp)));
