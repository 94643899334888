import styled, { withTheme } from 'styled-components';

export const ParlayBody = styled.div`
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  background: ${(props) => props.theme.xenaColorTwo};
  color: ${(props) => props.theme.fontColorContrast};

  .parlay-close {
    background-color: ${(props) => props.theme.warningColor};
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    color: ${(props) => props.theme.fontColorBlack};

    svg {
      vertical-align: middle;
      margin-right: 0.25em;
    }
  }
`;

export const Parlays = styled.div`
  display: flex;
  flex-flow: column;
  height: -moz-calc(100% - 120px);
  height: -webkit-calc(100% - 120px);
  height: -o-calc(100% - 120px);
  height: calc(100% - 120px);
  overflow-y: scroll;

  .no-entries {
    text-align: center;
    padding: 40px;
  }
`;

export const ParlayPlacement = styled.div`
  height: 120px;
  flex-grow: 1;
  background: ${(props) => props.theme.xenaColorDark};
  color: ${(props) => props.theme.fontColorContrast};

  .descriptor {
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 200;
    background: ${(props) => props.theme.xenaColorHighlight};
    color: ${(props) => props.theme.fontColorWhite};
  }

  .order-placed {
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 200;
    background: ${(props) => props.theme.positive};
    color: ${(props) => props.theme.fontColorWhite};
  }

  .parlay-error {
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: 200;
    background: ${(props) => props.theme.warning};
    color: ${(props) => props.theme.fontColorWhite};
  }

  .parlay-placement-container {
    height: 120px;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;

    .parlay-placement-input {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      > div {
        width: 100px;
        margin-right: 15px;
      }

      .place-acca {
        margin-bottom: -10px;
      }

      .reason-cant-place {
        padding: 10px;
        padding-left: 0px;
        font-weight: 200;
        width: 100%;
        margin-right: 0;
        color: ${(props) => props.theme.errorColor};
        text-align: left;
      }
    }
  }
`;

export const AccaConfirmation = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-template-rows: 40px 80px;

  .confirmation-header {
    grid-column: 1/3;
    grid-row: 1/2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 4px;
    padding-left: 14px;
    font-size: 16px;
    font-weight: bold;

    .confirmation-title {
      font-size: 16px;
      font-weight: bold;
    }

    .order-actions {
      display: flex;
      flex-direction: row;
      align-items: center;

      .confirm {
        margin-right: 15px;
        font-size: 12px;
        padding: 6px;
        background-color: green;
        border-radius: 2px;
        color: white;
      }

      .cancel {
        margin-right: 15px;
        font-size: 12px;
        padding: 6px;
        background-color: red;
        border-radius: 2px;
        color: white;
      }
    }
  }

  .order-details {
    grid-column: 1/3;
    grid-row: 2/3;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 18px;
  }
`;
