/** @format */

import _ from 'lodash';
import config from '@mollybet/frontend-common/dist/config';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';

let local = {
  supportedLanguages: ['en', 'pt', 'ru', 'es', 'tr'], //you have to have the default language in here
  defaultLanguage: 'en', //default interface language
  defaultTheme: 'default', //default interface theme

  betbar: {
    maxOrders: 25, //max number of orders in the betbar
  },

  pageSizes: {
    historyOrders: 25,
  },

  settingsOptions: {
    //timeouts available in betslip
    timeouts: [
      5,
      10,
      15,
      20,
      30,
      60,
      120,
      300,
      600,
      900,
      1200,
      1800,
      3600,
      7200,
      14400,
      21600,
      43200,
      86400,
      172800,
      259200,
    ],
    //price type options
    priceType: [
      {
        type: 'dec',
        name: { id: 'settingsOptions.priceType.dec', defaultMessage: 'Decimal' },
      },
      {
        type: 'ol',
        name: { id: 'settingsOptions.priceType.ol', defaultMessage: 'Hong Kong' },
      },
      {
        type: 'uso',
        name: { id: 'settingsOptions.priceType.uso', defaultMessage: 'United States' },
      },
      // {
      //   'type': 'maly',
      //   'name': {id: 'settingsOptions.priceType.maly', defaultMessage: 'Malay Odds'}
      // },
      {
        type: 'indo',
        name: { id: 'settingsOptions.priceType.indo', defaultMessage: 'Indo Odds' },
      },
    ],
    //theme options
    themes: [
      {
        type: 'default',
        name: { id: 'settingsOptions.themes.dark', defaultMessage: 'Dark' },
      },
      {
        type: 'light',
        name: { id: 'settingsOptions.themes.light', defaultMessage: 'Light' },
      },
    ],
    //languages options (also depends on the supported laanguages)
    languages: [
      {
        type: 'en',
        name: { id: 'settingsOptions.language.en', defaultMessage: 'English' },
        code: 'en',
        flag: 'UK',
        enabled: true,
      },
      {
        type: 'zh',
        name: { id: 'settingsOptions.language.zh', defaultMessage: 'Chinese' },
        code: 'zh_CN',
        flag: 'CN',
        enabled: true,
      },
      // {
      //   'type': 'ko',
      //   'name': {id: 'settingsOptions.language.ko', defaultMessage: 'Korean'},
      //   'code': 'ko',
      //   'flag': 'KR',
      //   'enabled': true
      // },
      // {
      //   'type': 'ro',
      //   'name': {id: 'settingsOptions.language.ro', defaultMessage: 'Romanian'},
      //   'code': 'ro_RO',
      //   'flag': 'RO',
      //   'enabled': true
      // },
      {
        type: 'pt',
        name: { id: 'settingsOptions.language.pt', defaultMessage: 'Portuguese' },
        code: 'pt_PT',
        flag: 'BR',
        enabled: true,
      },
      {
        type: 'ru',
        name: { id: 'settingsOptions.language.ru', defaultMessage: 'Russian' },
        code: 'ru_RU',
        flag: 'RU',
        enabled: true,
      },
      {
        type: 'es',
        name: { id: 'settingsOptions.language.es', defaultMessage: 'Spanish' },
        code: 'es_ES',
        flag: 'ES',
        enabled: true,
      },
      {
        type: 'tr',
        name: { id: 'settingsOptions.language.tr', defaultMessage: 'Turkish' },
        code: 'tr_TR',
        flag: 'TR',
        enabled: true,
      },
    ],
    //countries options and translations
    countries: [
      {
        type: 'AL',
        name: {
          id: 'settingsOptions.countries.Albania',
          defaultMessage: 'Albania',
        },
      },
      {
        type: 'AD',
        name: {
          id: 'settingsOptions.countries.Andorra',
          defaultMessage: 'Andorra',
        },
      },
      {
        type: 'AR',
        name: {
          id: 'settingsOptions.countries.Argentina',
          defaultMessage: 'Argentina',
        },
      },
      {
        type: 'AM',
        name: {
          id: 'settingsOptions.countries.Armenia',
          defaultMessage: 'Armenia',
        },
      },
      {
        type: 'AT',
        name: {
          id: 'settingsOptions.countries.Austria',
          defaultMessage: 'Austria',
        },
      },
      {
        type: 'AZ',
        name: {
          id: 'settingsOptions.countries.Azerbaijan',
          defaultMessage: 'Azerbaijan',
        },
      },
      {
        type: 'BA',
        name: {
          id: 'settingsOptions.countries.Bosnia and Herzegovina',
          defaultMessage: 'Bosnia and Herzegovina',
        },
      },
      {
        type: 'BD',
        name: {
          id: 'settingsOptions.countries.Bangladesh',
          defaultMessage: 'Bangladesh',
        },
      },
      {
        type: 'BY',
        name: {
          id: 'settingsOptions.countries.Belarus',
          defaultMessage: 'Belarus',
        },
      },
      {
        type: 'BR',
        name: {
          id: 'settingsOptions.countries.Brazil',
          defaultMessage: 'Brazil',
        },
      },
      {
        type: 'CI',
        name: {
          id: "settingsOptions.countries.Côte d'Ivoire",
          defaultMessage: "Côte d'Ivoire",
        },
      },
      {
        type: 'CW',
        name: {
          id: 'settingsOptions.countries.Curacao',
          defaultMessage: 'Curacao',
        },
      },
      {
        type: 'DO',
        name: {
          id: 'settingsOptions.countries.Dominican Republic',
          defaultMessage: 'Dominican Republic',
        },
      },
      {
        type: 'KH',
        name: {
          id: 'settingsOptions.countries.Cambodia',
          defaultMessage: 'Cambodia',
        },
      },
      {
        type: 'CL',
        name: {
          id: 'settingsOptions.countries.Chile',
          defaultMessage: 'Chile',
        },
      },
      {
        type: 'CR',
        name: {
          id: 'settingsOptions.countries.Costa Rica',
          defaultMessage: 'Costa Rica',
        },
      },
      {
        type: 'CY',
        name: {
          id: 'settingsOptions.countries.Cyprus',
          defaultMessage: 'Cyprus',
        },
      },
      {
        type: 'EC',
        name: {
          id: 'settingsOptions.countries.Ecuador',
          defaultMessage: 'Ecuador',
        },
      },
      {
        type: 'EG',
        name: {
          id: 'settingsOptions.countries.Egypt',
          defaultMessage: 'Egypt',
        },
      },
      {
        type: 'EE',
        name: {
          id: 'settingsOptions.countries.Estonia',
          defaultMessage: 'Estonia',
        },
      },
      {
        type: 'FI',
        name: {
          id: 'settingsOptions.countries.Finland',
          defaultMessage: 'Finland',
        },
      },
      {
        type: 'GE',
        name: {
          id: 'settingsOptions.countries.Georgia',
          defaultMessage: 'Georgia',
        },
      },
      {
        type: 'GI',
        name: {
          id: 'settingsOptions.countries.Gibraltar',
          defaultMessage: 'Gibraltar',
        },
      },
      {
        type: 'HR',
        name: {
          id: 'settingsOptions.countries.Croatia (Hrvatska)',
          defaultMessage: 'Croatia (Hrvatska)',
        },
      },
      {
        type: 'HU',
        name: {
          id: 'settingsOptions.countries.Hungary',
          defaultMessage: 'Hungary',
        },
      },
      {
        type: 'IS',
        name: {
          id: 'settingsOptions.countries.Iceland',
          defaultMessage: 'Iceland',
        },
      },
      {
        type: 'IM',
        name: {
          id: 'settingsOptions.countries.Isle of Man',
          defaultMessage: 'Isle of Man',
        },
      },
      {
        type: 'IN',
        name: {
          id: 'settingsOptions.countries.India',
          defaultMessage: 'India',
        },
      },
      {
        type: 'JP',
        name: {
          id: 'settingsOptions.countries.Japan',
          defaultMessage: 'Japan',
        },
      },
      {
        type: 'KZ',
        name: {
          id: 'settingsOptions.countries.Kazakhstan',
          defaultMessage: 'Kazakhstan',
        },
      },
      {
        type: 'KG',
        name: {
          id: 'settingsOptions.countries.Kyrgyzstan',
          defaultMessage: 'Kyrgyzstan',
        },
      },
      {
        type: 'LV',
        name: {
          id: 'settingsOptions.countries.Latvia',
          defaultMessage: 'Latvia',
        },
      },
      {
        type: 'LI',
        name: {
          id: 'settingsOptions.countries.Liechtenstein',
          defaultMessage: 'Liechtenstein',
        },
      },
      {
        type: 'LT',
        name: {
          id: 'settingsOptions.countries.Lithuania',
          defaultMessage: 'Lithuania',
        },
      },
      {
        type: 'LU',
        name: {
          id: 'settingsOptions.countries.Luxembourg',
          defaultMessage: 'Luxembourg',
        },
      },
      {
        type: 'MO',
        name: {
          id: 'settingsOptions.countries.Macau',
          defaultMessage: 'Macau',
        },
      },
      {
        type: 'MK',
        name: {
          id: 'settingsOptions.countries.Macedonia',
          defaultMessage: 'Macedonia',
        },
      },
      {
        type: 'MY',
        name: {
          id: 'settingsOptions.countries.Malaysia',
          defaultMessage: 'Malaysia',
        },
      },
      {
        type: 'MT',
        name: {
          id: 'settingsOptions.countries.Malta',
          defaultMessage: 'Malta',
        },
      },
      {
        type: 'MX',
        name: {
          id: 'settingsOptions.countries.Mexico',
          defaultMessage: 'Mexico',
        },
      },
      {
        type: 'MD',
        name: {
          id: 'settingsOptions.countries.Moldova',
          defaultMessage: 'Moldova',
        },
      },
      {
        type: 'MC',
        name: {
          id: 'settingsOptions.countries.Monaco',
          defaultMessage: 'Monaco',
        },
      },
      {
        type: 'ME',
        name: {
          id: 'settingsOptions.countries.Montenegro',
          defaultMessage: 'Montenegro',
        },
      },
      {
        type: 'MA',
        name: {
          id: 'settingsOptions.countries.Morocco',
          defaultMessage: 'Morocco',
        },
      },
      {
        type: 'NL',
        name: {
          id: 'settingsOptions.countries.Netherlands',
          defaultMessage: 'Netherlands',
        },
      },
      {
        type: 'NC',
        name: {
          id: 'settingsOptions.countries.New Caledonia',
          defaultMessage: 'New Caledonia',
        },
      },
      {
        type: 'NZ',
        name: {
          id: 'settingsOptions.countries.New Zealand',
          defaultMessage: 'New Zealand',
        },
      },
      {
        type: 'NI',
        name: {
          id: 'settingsOptions.countries.Nicaragua',
          defaultMessage: 'Nicaragua',
        },
      },
      {
        type: 'NG',
        name: {
          id: 'settingsOptions.countries.Nigeria',
          defaultMessage: 'Nigeria',
        },
      },
      {
        type: 'NO',
        name: {
          id: 'settingsOptions.countries.Norway',
          defaultMessage: 'Norway',
        },
      },
      {
        type: 'PA',
        name: {
          id: 'settingsOptions.countries.Panama',
          defaultMessage: 'Panama',
        },
      },
      {
        type: 'PY',
        name: {
          id: 'settingsOptions.countries.Paraguay',
          defaultMessage: 'Paraguay',
        },
      },
      {
        type: 'PH',
        name: {
          id: 'settingsOptions.countries.Philippines',
          defaultMessage: 'Philippines',
        },
      },
      {
        type: 'QA',
        name: {
          id: 'settingsOptions.countries.Qatar',
          defaultMessage: 'Qatar',
        },
      },
      {
        type: 'SM',
        name: {
          id: 'settingsOptions.countries.San Marino',
          defaultMessage: 'San Marino',
        },
      },
      {
        type: 'SA',
        name: {
          id: 'settingsOptions.countries.Saudi Arabia',
          defaultMessage: 'Saudi Arabia',
        },
      },
      {
        type: 'RS',
        name: {
          id: 'settingsOptions.countries.Serbia',
          defaultMessage: 'Serbia',
        },
      },
      {
        type: 'RU',
        name: {
          id: 'settingsOptions.countries.Russian Federation',
          defaultMessage: 'Russian Federation',
        },
      },
      {
        type: 'SC',
        name: {
          id: 'settingsOptions.countries.Seychelles',
          defaultMessage: 'Seychelles',
        },
      },
      {
        type: 'SI',
        name: {
          id: 'settingsOptions.countries.Slovenia',
          defaultMessage: 'Slovenia',
        },
      },
      {
        type: 'KR',
        name: {
          id: 'settingsOptions.countries.South Korea',
          defaultMessage: 'South Korea',
        },
      },
      {
        type: 'SK',
        name: {
          id: 'settingsOptions.countries.Slovakia (Slovak Republic)',
          defaultMessage: 'Slovakia (Slovak Republic)',
        },
      },
      {
        type: 'CH',
        name: {
          id: 'settingsOptions.countries.Switzerland',
          defaultMessage: 'Switzerland',
        },
      },
      {
        type: 'TH',
        name: {
          id: 'settingsOptions.countries.Thailand',
          defaultMessage: 'Thailand',
        },
      },
      {
        type: 'TN',
        name: {
          id: 'settingsOptions.countries.Tunisia',
          defaultMessage: 'Tunisia',
        },
      },
      {
        type: 'UA',
        name: {
          id: 'settingsOptions.countries.Ukraine',
          defaultMessage: 'Ukraine',
        },
      },
      {
        type: 'AE',
        name: {
          id: 'settingsOptions.countries.United Arab Emirates',
          defaultMessage: 'United Arab Emirates',
        },
      },
      {
        type: 'VE',
        name: {
          id: 'settingsOptions.countries.Venezuela',
          defaultMessage: 'Venezuela',
        },
      },
      {
        type: 'VN',
        name: {
          id: 'settingsOptions.countries.Vietnam',
          defaultMessage: 'Vietnam',
        },
      },
    ],
    //terms for translation purposes
    terms: {
      beta: {
        name: { id: 'terms.side.beta', defaultMessage: 'Beta' },
        url: 'beta.html',
        lastUpdated: '2018-10-16',
      },
      terms: {
        name: { id: 'terms.side.termsOfUse', defaultMessage: 'Terms of Use' },
        url: 'terms.html',
        lastUpdated: '2018-10-16',
      },
      suppTerms: {
        name: { id: 'terms.side.supplementaryTerms', defaultMessage: 'Supplementary Terms' },
        url: 'sup_terms.html',
        lastUpdated: '2020-07-26',
      },
      privacy: {
        name: { id: 'terms.side.privacy', defaultMessage: 'Privacy Policy' },
        url: 'privacy.html',
        lastUpdated: '2018-10-16',
      },
      acceptable: {
        name: { id: 'terms.side.acceptable', defaultMessage: 'Acceptable Use' },
        url: 'acceptable.html',
        lastUpdated: '2018-10-16',
      },
      responsible: {
        name: { id: 'terms.side.responsible', defaultMessage: 'Responsible Gambling' },
        url: 'responsible.html',
        lastUpdated: '2018-10-16',
      },
    },
  },

  support: {
    sentry: {
      dsn: 'https://94f99d147c9148a3b056b4defef56eff@o592703.ingest.sentry.io/5857316',
    },

    tools: {
      dontLog: ['closeAllXenaModals'],
      logBuffer: 500, //how many messages to save in the log buffer
    },

    recordGA: {
      toggleXenaSideMenu: {
        value: '',
        label: [],
      },
      toggleMarketSelector: {
        value: '',
        label: [],
      },
      toggleSearchBar: {
        value: '',
        label: [],
      },
      toggleBalance: {
        value: '',
        label: [],
      },
      toggleAccBar: {
        value: '',
        label: [],
      },
      placerPopConfirm: {
        value: '',
        label: [],
        nonInteraction: true,
      },
      placerAcceptConfirm: {
        value: '',
        label: [],
      },
      placerRejectConfirm: {
        value: '',
        label: [],
      },
      changeMarketInFocus: {
        value: '',
        label: ['market'],
      },
      changeSport: {
        value: '',
        label: ['sport'],
      },
    },
  },

  enabledGroupsXena: {
    fb: {
      wdw: true,
      ah: true,
      ahou: true,
      win: true,
    },
    fb_htft: {
      htft: true,
    },
    basket: {
      ml: true,
      ah: true,
      ahou: true,
      win: true,
    },
    golf: {
      win: true,
    },
    tennis: {
      'tennisMatch,all': true,
      'tennisAh,all,set': true,
      'tennisAh,all,game': true,
      'tennisAhou,all,game': true,
      win: true,
    },
    ih: {
      'timeWin,tp,all,ml': true,
      TPReg1X2: true,
      TPRegAsianHandicap: true,
      'timeAhou,tp,reg': true,
      win: true,
    },
    baseball: {
      'timeWin,tp,all,ml': true,
      TPAllAsianHandicap: true,
      'timeAhou,tp,all': true,
      TPReg1X2: true,
      win: true,
    },
    cricket: {
      'timeWin,tp,all,ml': true,
      TPAllAsianHandicap: true,
      'timeWin,tp,reg,ml': true,
      TPReg1X2: true,
      win: true,
    },
    boxing: {
      ml: true,
      ah: true,
      ahou: true,
      win: true,
    },
    esports: {
      'timeWin,tp,all,ml': true,
      TPAllAsianHandicap: true,
      'timeAhou,tp,all': true,
      win: true,
    },
    mma: {
      ml: true,
      ah: true,
      ahou: true,
      win: true,
    },
    rl: {
      'timeWin,tp,all,ml': true,
      TPRegAsianHandicap: true,
      TPAllAsianHandicap: true,
      'timeAh,tp,all': true,
      'timeWin,tp,reg,wdw': true,
      'timeWin,tp,all,mlssop': true,
      win: true,
    },
    ru: {
      TPAllAsianHandicap: true,
      TPReg1X2: true,
      TPRegAsianHandicap: true,
      'timeAh,tp,all': true,
      'timeWin,tp,reg,wdw': true,
      win: true,
    },
    af: {
      'timeWin,tp,all,ml': true,
      'timeWin,tp,reg,ml': true,
      TPAllAsianHandicap: true,
      'timeAhou,tp,all': true,
      TPReg1X2: true,
      TPRegAsianHandicap: true,
      'timeAhou,tp,reg': true,
      win: true,
    },
    arf: {
      TPAllAsianHandicap: true,
      TPReg1X2: true,
      TPRegAsianHandicap: true,
      win: true,
    },
    hand: {
      'timeWin,tp,all,ml': true,
      'timeWin,tp,reg,ml': true,
    },
  },
};

_.merge(local, config);

//need to re-apply whitelabel config because the local default might override code configs that came from the whitelabelin the first place
//this is mainly for dev-mode whitelabel to work correctly
_.merge(local, Whitelabel.config);

if (window && window.WL_CONFIG) {
  _.merge(local, window.WL_CONFIG);
  if (window.WL_CONFIG.config.supportedLanguages) {
    local.supportedLanguages = window.WL_CONFIG.config.supportedLanguages;
  }
}

export default local;
