/** @format */

import './BetBarStatusBar.sass';

import config from '../../../config';
import { actions } from '../../../reducers/app';

import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';

import { SettingsContext } from '../../../components/shared/SettingsContext';
import { formatAmount } from '@mollybet/frontend-common/dist/lib/formatters';
import { translateBetbarStatus } from '../../../lib/orders';

const BetBarStatusBar = (props) => {
  const context = useContext(SettingsContext);
  let bets = [];
  let calc;
  if (props.calc) {
    calc = props.calc.toJS();
  } else {
    calc = {
      openOrderBetWidth: {},
      calcStake: {},
      betsStatus: 'bets-unknown',
      averagePrice: 1,
    };
  }

  if (context.featureBetbarFromAPI && props.betBarValues) {
    calc = {
      openOrderBetWidth: {},
      calcStake: {},
      betsStatus: calc.betsStatus,
      averagePrice: props.price,
    };

    let betBarValues = props.betBarValues.toJS();
    for (let status in betBarValues) {
      calc.calcStake[`${status}-stake`] = betBarValues[status][1];
      let st = (betBarValues[status][1] / props.wantStake) * 100;
      if (st >= 99) {
        st = 100;
      } else if (st < 1) {
        st = 0;
      }
      calc.openOrderBetWidth[`${status}-stake`] = st;
    }
  }

  for (let status in calc.calcStake) {
    if (calc.calcStake[status] && calc.calcStake[status] > config.betbar.stakeMin) {
      bets.push(
        <span
          variant="body2"
          component="span"
          color="secondary"
          key={status}
          className={'betbar-status ' + status}
          style={{
            width:
              calc.openOrderBetWidth[status] !== Infinity
                ? `${calc.openOrderBetWidth[status]}%`
                : 'auto',
          }}
        >
          {`${
            calc.calcStake[status]
              ? formatAmount(
                  calc.calcStake[status],
                  props.ccy,
                  props.placer === context.currentUser ? context.displayCcy : '',
                  props.xrates
                )
              : '?'
          }`}
          &nbsp;({translateBetbarStatus(status, props.closed)})
        </span>
      );
    }
  }

  let status = props.status;
  //if it has pending bets the order is pending
  if (status !== 'placing' && calc.betsStatus !== 'bets-final') {
    status = 'pending';
  }
  if (props.closeReason === 'server_error') {
    let now = new Date();
    let exp = new Date(props.expiryTime);
    exp.setTime(exp.getTime() + config.timings.serverErrorOrderGracePeriod);

    if (now < exp) {
      status = 'pending';
    }
  }
  return (
    <div
      className={`smart-bar-betbar-order narrow ${props.shell ? ' shell' : ''}${
        props.slim ? ' slim' : ''
      }${props.closed ? ' closed' : ' open'} ${calc.betsStatus}`}
    >
      <div className="body">
        <div className="bets">{bets}</div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state, props) => {
  let order = state.getIn(['betbar', 'orders', props.orderId], null);

  return order
    ? {
        eventId: order.getIn(['eventInfo', 'eventId'], null),
        sport: order.get('sport', '?'),
        shell: order.get('shell', false),

        closed: order.get('closed', false),
        closeReason: order.get('closeReason', ''),
        status: order.get('status', ''),
        expiryTime: order.get('expiryTime', null),
        wantStake: order.getIn(['wantStake', '1'], 0),
        wantPrice: order.get('wantPrice', null),
        placer: order.get('placer', state.getIn(['base', 'profile', 'username'], '?')),

        //calculated stuff
        calc: order.get('calc'),

        ccy: order.getIn(['wantStake', '0'], '?').toLowerCase(),
        //ccyRate: order.get('ccyRate', 1),
        xrates: state.getIn(['base', 'xrates'], null), //need this
      }
    : {};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual: (next, prev) => {
    return prev.getIn(['betbar', 'orders'], null) === next.getIn(['betbar', 'orders'], null);
  },
})(injectIntl(BetBarStatusBar));
