/** @format */

/**
 * Takes input string from html input and cleans it to be a valid number as agreed:
 *  - The first instance of , or . will be considered the decimal separator
 *
 * @param {string} inputValue - The text inputted provided by the user.
 * @param {number} noDecimalPlaces - The number of decimal places to allow.
 * @param {boolean} isNegativeAllowed - If negative numbers are allowed or not.
 *
 * @returns {{ value: string, changes: Array.<string>}}
 */
export const cleanNumberInput = (value, noDecimalPlaces = 2, isNegativeAllowed = true) => {
  let valueLength = value.length;
  const changes = [];

  // Clean out undesirables
  value = value.replace(/[^0-9,.-]/g, '');
  if (value.length !== valueLength) changes.push('invalid-characters-removed');
  if (value === '') return { value: value, changes };
  valueLength = value.length;

  // Handle negative allowed
  const isNegative = isNegativeAllowed && value.indexOf('-') === 0;
  value = `${isNegative ? '-' : ''}${value.replace(/-/g, '')}`;
  if (value.length !== valueLength) changes.push('negative-signs-removed');
  if (value === '') return { value, changes };
  valueLength = value.length;

  // Handle decimals
  let firstPointIndex = value.indexOf('.');
  let firstCommaIndex = value.indexOf(',');

  if (firstPointIndex >= 0 || firstCommaIndex >= 0) {
    const decimalSeparator =
      (firstPointIndex >= 0 ? firstPointIndex : Infinity) <=
      (firstCommaIndex >= 0 ? firstCommaIndex : Infinity)
        ? '.'
        : ',';
    const removalCharacter = decimalSeparator === '.' ? ',' : '.';

    // Remove secondary decimal separator
    value = value.replace(new RegExp(removalCharacter, 'g'), '');
    if (value.length !== valueLength) changes.push('secondary-decimal-separators-removed');
    if (value === '') return { value, changes };
    valueLength = value.length;

    // Remove extra decimal characters and trim decimal places
    const valueSplit = value.split(decimalSeparator);
    if (valueSplit[1].length > noDecimalPlaces) changes.push('extra-decimal-places-removed');
    if (valueSplit.length > 1 + Math.min(noDecimalPlaces, 1))
      changes.push('primary-decimal-separators-removed');
    if (noDecimalPlaces > 0 && valueSplit[1] != null) {
      valueSplit[1] = valueSplit[1].slice(0, noDecimalPlaces);
      value = `${valueSplit[0]}${decimalSeparator}${valueSplit[1]}`;
    } else {
      value = valueSplit[0];
    }
    if (value === '') return { value, changes };
    valueLength = value.length;
  }

  return { value, changes };
};
