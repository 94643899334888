/** @format */

// @flow

import React from 'react';
import styled, { type StyledComponent } from 'styled-components';

import { type Theme } from '@mollybet/ui/dist/theme';
import { Button as Bttn } from '@mollybet/ui';

export const Button: StyledComponent<React.ElementConfig<typeof Bttn>, Theme, *> = styled(
  Bttn
).attrs((props) => ({
  ...props,
}))``;

export const NegativeButton: StyledComponent<React.ElementConfig<typeof Bttn>, Theme, *> = styled(
  Bttn
).attrs((props) => ({
  ...props,
  theme: { ...props.theme, primary: props.theme.negative },
  variant: 'primary',
}))``;

export const PositiveButton: StyledComponent<React.ElementConfig<typeof Bttn>, Theme, *> = styled(
  Bttn
).attrs((props) => ({
  ...props,
  theme: { ...props.theme, primary: props.theme.positive },
  variant: 'primary',
}))``;
