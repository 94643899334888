/** @format */

import React from 'react';
import { FormattedMessage } from 'react-intl';

export function keyToLabel(key) {
  switch (key) {
    case 'balance':
      return <FormattedMessage id="stream.balance" defaultMessage="Balance" />;
    case 'stake':
      return <FormattedMessage id="stream.stake" defaultMessage="Open Stake" />;
    case 'xfer':
      return <FormattedMessage id="stream.xfer" defaultMessage="Transfer" />;
    case 'commission':
      return <FormattedMessage id="stream.commission" defaultMessage="Commission" />;
    case 'discount':
      return <FormattedMessage id="stream.discount" defaultMessage="Discount" />;
    case 'commissionDue':
      return <FormattedMessage id="stream.commissionDue" defaultMessage="Commission Due" />;
    case 'currentBalance':
      return <FormattedMessage id="stream.currentBalance" defaultMessage="Current Balance" />;
    case 'openStakes':
      return <FormattedMessage id="stream.openStakes" defaultMessage="Open Stake" />;
    case 'todayPl':
      return <FormattedMessage id="stream.todayPl" defaultMessage="Today P/L" />;
    case 'yesterdayPl':
      return <FormattedMessage id="stream.yesterdayPl" defaultMessage="Yesterday P/L" />;
    case 'creditLimit':
      return <FormattedMessage id="stream.creditLimit" defaultMessage="Credit Limit" />;
    case 'commissionRate':
      return <FormattedMessage id="stream.commissionRate" defaultMessage="Commission Rate" />;
    case 'availableCredit':
      return <FormattedMessage id="stream.availableCredit" defaultMessage="Available Credit" />;
    default:
      return null;
  }
}

export function keyToDelta(key) {
  switch (key) {
    case 'balance':
      return <FormattedMessage id="stream.pl" defaultMessage="P/L" />;
    case 'stake':
      return <FormattedMessage id="stream.openStake" defaultMessage="Stake" />;
    default:
      return null;
  }
}

/**
 * Extract the relevant paths from a router configuration
 * @param {Object} src - the source router configuration
 * @param {Function} [filter] - filters paths based on a criteria
 * @return {string[]} - list of the paths that match the filter criteria
 */

export function extractPaths(src, filter) {
  let paths = [];

  if (src.items && Array.isArray(src.items)) {
    for (let it of src.items) {
      if (filter ? filter(it) : true) {
        //has it's own route?
        if (it.route && it.component) {
          paths.push({
            path: it.route,
            component: it.component,
          });
        }
        //does it have its own items
        if (it.items) {
          paths = paths.concat(extractPaths(it, filter));
        }
      }
    }
  }

  return paths;
}
