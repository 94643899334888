/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../../reducers/app';
import config from '../../config';
import Details from '@material-ui/icons/KeyboardArrowDown';

import { injectIntl } from 'react-intl';

import { getBaseSport } from '@mollybet/frontend-common/dist/lib/trade';
import { XenaDiv } from '../shared/Common';
import { SettingsContext } from '../shared/SettingsContext';
import './MarketSelector.sass';

import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';

class MarketSelector extends React.Component {
  static contextType = SettingsContext;

  shouldShow = (sport, inParlay = false) => {
    //is a main sport and (is enabled or user has advanced trade settings)
    return (
      sport === getBaseSport(sport, true) &&
      (config.sports[sport].enabled || this.context.featureAdvancedTradeSettings) &&
      (!inParlay || inParlay && config.parlays.allowedSports.includes(sport))
    );
  };

  render() {
    let shortenedName = config.sportNames[this.props.sport]
      ? this.props.intl.formatMessage(
          config.sportNames[this.props.sport].shortName || config.sportNames[this.props.sport].name
        )
      : '?';
    return (
      <div className={`market-selector`}>
        <div
          className={`current-sport-container`}
          onClick={this.props.actions.toggleMarketSelector}
        >
          <div className="current-sport">{shortenedName}</div>
          <Details className="drop-down-icon" />
        </div>
        {this.props.marketSelectorOpen ? (
          <XenaDiv background={'xenaColorHeader'} className="sports-list">
            {Object.keys(config.sportNames)
              .filter((sport) => sport==='horse' ? this.context.featureHorse : this.shouldShow(sport, this.props.parlayMode))
              .map((sport) => {
                let listName = this.props.intl
                  .formatMessage(config.sportNames[sport].name)
                  .replace(': Full-Time', '')
                  .replace('Extra-Time', 'ET')
                  .replace('Half-Time', 'HT');
                return (
                  <div className="sport-option-container" key={listName}>
                    <Link
                      className={`sport-option${sport === this.props.sport ? ' selected' : ''}`}
                      to={`/trade/${sport}`}
                      onClick={this.props.actions.toggleMarketSelector}
                    >
                      <div className={`sport-details`}>
                        <div className="sport-name">{listName}</div>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </XenaDiv>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// turn state of combined reducers into state required by component
const mapStateToProps = (state, ownProps) => {
  let hash = state.getIn(ownProps.hash || [], '');
  if (typeof hash !== 'string') {
    hash = '';
  }

  return {
    hash,
    sport: state.getIn(['trade', 'sport'], ''),
    marketSelectorOpen: state.getIn(['ui', 'marketSelectorOpen'], false),
    parlayMode: state.getIn(['ui', 'parlayMode'], false),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MarketSelector));
