/** @format */

import React, { useContext } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { formatPrice } from '@mollybet/frontend-common/dist/lib/formatters';
import { SettingsContext } from '../shared/SettingsContext';
import TranslatedBetType from '../shared/TranslatedBetType';
import styled from 'styled-components';
import config from '../../config';
import { actions } from '../../reducers/app';

import { Icon } from '../interface';

import { mdiClose } from '@mdi/js';

const ParlayEntry = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 13px;
  margin: 5px;
  padding-bottom: 6px;
  border-bottom: white 1px solid;
  min-height: 76px;
  color: ${(props) => props.theme.fontColorContrast};
`;

const ParlayAccounts = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 4em;
  color: ${(props) => props.theme.fontColorContrast};

  .parlay-entry-account {
    display: flex;
    justify-content: center;
    width: 4em;

    .parlay-entry-account-pmms {
      display: flex;

      &:last-child {
        margin-right: 0;
      }

      .parlay-entry-pmm {
        list-style: none;
        justify-content: center;
        width: 4em;
        display: flex;
        flex-flow: column;

        .parlay-entry-pmm-price {
          font-weight: bold;
          line-height: 18px;
          text-align: center;
        }
      }
    }
  }
`;

const ParlayTitle = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 0.25em;

  .left-padded {
    padding-left: 0.5em;
  }

  .remove-button {
    padding-right: 4px;
  }

  div {
    > span {
      display: inline-block;
      width: 100%;
      line-height: 1.5em;
    }

    .parlay-entry-title-bet-type {
      font-weight: bold;
    }

    .parlay-entry-title-team-names {
      .dash {
        margin: 0 0.5em;
      }
    }
  }
`;

/**
 * This component is a navigation button that wraps react-router's Link component.
 * It requires at least a title and optionally a route and/or an onClick
 */

const ParlayEntryComp = (props) => {
  const context = useContext(SettingsContext);

  const remove = () => {
    props.actions.parlayRemoveEntry({
      parlayId: props.parlayId,
      eventId: props.eventId,
      sport: props.sport,
      betType: props.betType,
      actions: props.actions,
    });
  };

  let _bsa = {};
  let bsa = [];

  _bsa[props.selectedBookie] = (
    <div key={props.selectedBookie} className="parlay-entry-account">
      <div className="parlay-entry-account-pmms">
        <div className="parlay-entry-pmm">
          <div className="parlay-entry-pmm-price">
            {props.prices
              ? formatPrice(props.prices.get(props.selectedBookie, 0), context.priceType)
              : '-'}
          </div>
        </div>
      </div>
    </div>
  );

  for (let bookie in config.bookies) {
    if (config.bookies[bookie].parlay) {
      if (_bsa[bookie]) {
        bsa.push(_bsa[bookie]);
      } else if (props.fullCompare) {
        bsa.push(
          <div key={bookie} className="parlay-entry-account">
            <div className="parlay-entry-account-pmms">
              <div className="parlay-entry-pmm">
                <div className="parlay-entry-pmm-price">
                  {props.prices ? formatPrice(props.prices.get(bookie, 0), context.priceType) : '-'}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
  return (
    <ParlayEntry className={props.marketId}>
      <ParlayTitle>
        {!props.hasPlaced && (
          <div className="remove-button">
            <Icon
              width={24}
              height={24}
              path={mdiClose}
              button="true"
              onClick={remove}
              title={props.intl.formatMessage({
                id: 'trade.parlay.removeBetTooltip',
                defaultMessage: 'Remove this bet from parlay',
              })}
            />
          </div>
        )}
        <div className={props.hasPlaced ? 'left-padded' : ''}>
          <span className="parlay-entry-title-competitions" title={props.competitionId}>
            {props.compName}
          </span>
          <span className="parlay-entry-title-team-names">
            {props.home}
            <span className="dash">-</span>
            {props.away}
          </span>
          <span className="parlay-entry-title-sport">
            {config.sportNames[props.sport]
              ? props.intl.formatMessage(config.sportNames[props.sport].name)
              : props.sport}
          </span>
          <span className="parlay-entry-title-bet-type">
            <TranslatedBetType
              betTypeDescription={props.betTypeDescription}
              betType={props.betType}
              home={props.home}
              away={props.away}
            />
          </span>
        </div>
      </ParlayTitle>
      <ParlayAccounts>{bsa}</ParlayAccounts>
    </ParlayEntry>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// turn state of combined reducers into state required by component
const mapStateToProps = function (state, ownProps) {
  let entry = state.getIn(
    ['parlays', 'parlays', ownProps.parlayId, 'entries', ownProps.entryId],
    null
  );

  if (!entry) {
    return {};
  }

  let sport = entry.get('sport', '');
  let eventId = entry.get('eventId', '');

  return {
    sport,
    eventId,
    betType: entry.get('betType', ''),
    prices: entry.get('prices', null),
    marketId: state.getIn(['trade', 'events', eventId, 'marketId'], ''),

    betTypeDescription: entry.get('betTypeDesc', '?'),
    compName: entry.get('compName', '?'),

    home: entry.get('homeName', 'Home'),
    away: entry.get('awayName', 'Away'),
    competitionId: entry.get('compId', '?'),
    hasPlaced: state.getIn(['parlays', 'parlays', ownProps.parlayId, 'hasPlaced'], false),

    featureShowInternalInfo: state.getIn(['base', 'switches', 'showInternalInfo'], false),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ParlayEntryComp));
