/** @format */

// @flow
import styled, { type StyledComponent } from 'styled-components';
import { type Theme } from '@mollybet/ui/dist/theme';
import { Paper } from './Paper';

export const Card: StyledComponent<
  { elevation?: number, zIndex?: number, overflow?: string, ... },
  Theme,
  *
> = styled(Paper).attrs((props) => ({
  ...props,
  shadow: true,
}))`
  border-radius: 12px;
`;
