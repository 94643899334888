/** @format */

import './App.sass';
import config from './config';
import routes from './routes';
import pkg from '../package.json';

import React from 'react';

import { BrowserRouter as Router, Route, Redirect, Switch, withRouter } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from './reducers/app';

import FourOughFour from './views/FourOughFour';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import ErrorBoundary from './components/shared/ErrorBoundary';
import { SettingsContext } from './components/shared/SettingsContext';
import { extractPaths } from './lib/nav';
import { toCamelCaseAll } from '@mollybet/frontend-common/dist/lib/camelSnake';
import BetBar from './components/trade/BetBar';
import AccBar from './components/trade/AccBar';
import BetBarButton from './components/BetBarButton';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
import { XenaDiv } from './components/shared/Common';

import Telemetry from '@mollybet/frontend-common/dist/lib/Telemetry';
import { lightUITheme, darkUITheme } from './theme';

/*eslint-disable*/
//basically we need to sneak in the translations used by this.props.intl.formattedMessage
//react-intl babel plugin will not pick these up :(
import Common from './translations/Common';
/*eslint-enable*/

//Internationalization
import { IntlProvider } from 'react-intl';
//this contains all the languages
import * as messages from './translations';
import Loading from './components/shared/Loading';

/**
 * This is the core application singleton instantiated in `index.js`.
 * Apart from authentication logic, you probably don't want to touch this.
 */

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      rest.isAuth === 'yes' ? (
        <>
          <React.Suspense fallback={<Loading />}>
            <Component {...props} />
          </React.Suspense>
        </>
      ) : rest.isAuth === 'no' ? (
        <Redirect to={`${routes.notAuthenticated}?next=${props.location.pathname}`} />
      ) : (
        <Loading />
      )
    }
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <React.Suspense fallback={<Loading />}>
        <Component {...props} />
      </React.Suspense>
    )}
  />
);

const FontStyles = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.fonts.base};

    h1, h2, h3, h4, h5, h6 {
      font-family: ${(props) => props.theme.fonts.headings};
    }
  }
`;

class _ChangeDetector extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      Telemetry.pageView(this.props.location.pathname);
    }
  }

  render() {
    return null;
  }
}

let ChangeDetector = withRouter(_ChangeDetector);

class App extends React.Component {
  constructor(props) {
    super(props);

    if (config.support.tools.actionHack) {
      window._callAction = (action, data) => {
        this.props.actions[action](toCamelCaseAll(data));
      };
    }

    this.state = {
      _statsInterval: null,
      _pingInterval: setInterval(
        this.props.actions.collectPings,
        config.timings.collectStreamPings
      ),
    };

    this._collectionBackoff = null;
    this._pingBackoff = null;
  }

  componentDidMount() {
    if (config.support.tools.recordLatency) {
      this._collectionBackoff = setTimeout(() => {
        this.props.actions.recordLatencyStats();
        this.setState({
          _statsInterval: setInterval(
            this.props.actions.recordLatencyStats,
            config.timings.collectDataStreamLatency
          ),
        });
      }, config.timings.collectBackoff);
    }

    this._pingBackoff = setTimeout(() => {
      this.props.actions.collectPings();
      this.setState({
        _statsInterval: setInterval(
          this.props.actions.collectPings,
          config.timings.collectStreamPings
        ),
      });
    }, config.timings.pingBackoff);

    //set ui version
    this.props.actions.setUIVersion({
      version: pkg.version,
      basename: routes.basename,
      application: pkg.name,
    });

    //check if user is authenticated
    if (this.props.isAuth === 'pending') {
      this.props.actions.isUserAuth({
        actions: this.props.actions,
      });
    }

    Telemetry.pageView(window.location.pathname);
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('resize', this.props.actions.windowResize);
    }

    if (this._collectionBackoff) {
      clearTimeout(this._collectionBackoff);
    }

    if (this._pingBackoff) {
      clearTimeout(this._pingBackoff);
    }

    if (this.state._statsInterval) {
      clearInterval(this.state._statsInterval);
    }

    if (this.state._pingInterval) {
      clearInterval(this.state._pingInterval);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isAuth !== prevProps.isAuth && prevProps.isAuth === 'yes') {
      //force-cleanup of things
      this.props.actions.baseStreamDisconnect();
    }

    if (this.props.isAuth !== prevProps.isAuth && this.props.isAuth === 'yes') {
      let pricesBookies = this.props.pricesBookies ? this.props.pricesBookies.toJS() : [];
      this.props.actions.baseStreamConnect({
        monitorHeartBeats: false,
        recover: true,
        currentUser: this.props.currentUser,
        language: this.props.language,
        bookies: pricesBookies,
        sessionId: this.props.sessionId,
        actions: this.props.actions,
      });
      this.props.actions.tradeLoadData({
        bookies: this.context.pricesBookies ? this.context.pricesBookies.toJS() : [],
        initParlayRestrictions: this.props.featureParlay,
        language: this.context.language,
        actions: this.props.actions,
      });
    }
  }

  clickOffPane = () => {
    if (this.props.searchBarOpen) {
      this.props.actions.toggleSearchBar();
    }
    if (this.props.balanceOpen) {
      this.props.actions.toggleBalance();
    }
    if (this.props.marketSelectorOpen) {
      this.props.actions.toggleMarketSelector();
    }
    if (this.props.sideMenuOpen) {
      this.props.actions.toggleXenaSideMenu();
    }
  };

  render() {
    //auth routes
    let expAuth = extractPaths(routes, (route) => route.needsAuth);
    let routesAuth = expAuth.map((rt, index) => (
      <PrivateRoute
        key={index}
        isAuth={this.props.isAuth}
        path={rt.path}
        component={rt.component}
      ></PrivateRoute>
    ));
    //unauth routes
    let expUnAuth = extractPaths(routes, (route) => !route.needsAuth);
    let routesUnAuth = expUnAuth.map((rt, index) => (
      <PublicRoute key={index} path={rt.path} component={rt.component}></PublicRoute>
    ));
    return (
      <SettingsContext.Provider value={{ ...this.props }}>
        <ThemeProvider theme={this.props.theme === 'light' ? lightUITheme : darkUITheme}>
          <FontStyles />
          <IntlProvider locale={this.props.language} messages={messages[this.props.language]}>
            <Router basename={routes.basename}>
              <ErrorBoundary>
                <XenaDiv background={'xenaColorDarkest'} className="app-container" id="app">
                  <div className="app-header">
                    <Header />
                  </div>
                  <div className={`side-menu${this.props.sideMenuOpen === true ? '-active' : ''}`}>
                    <SideMenu />
                  </div>
                  <div className="app-main">
                    <Switch>
                      {routesAuth}
                      {routesUnAuth}
                      <Route exact path="/" component={() => <Redirect to={routes.default} />} />
                      <Route component={FourOughFour} />
                    </Switch>
                    <ChangeDetector />
                    {this.props.parlayMode ? (
                      <AccBar apiSync={this.context.apiSync} slim={true} />
                    ) : (
                      <BetBar apiSync={this.context.apiSync} slim={true} />
                    )}
                    {!this.props.parlayMode &&
                      !this.props.expanded &&
                      this.props.isAuth !== 'no' && <BetBarButton />}
                  </div>
                </XenaDiv>
                {(this.props.balanceOpen ||
                  this.props.marketSelectorOpen ||
                  this.props.sideMenuOpen) && (
                  <div onClick={this.clickOffPane} className="app-click-off-pane" />
                )}
              </ErrorBoundary>
            </Router>
          </IntlProvider>
        </ThemeProvider>
      </SettingsContext.Provider>
    );
  }
}

// turn state of combined reducers into state required by component
const mapStateToProps = (state) => {
  let baseSport = state.getIn(['trade', 'baseSport'], '');
  let sport = state.getIn(['trade', 'sport'], '');

  let displayCcy = state.getIn(['base', 'settings', 'general', 'displayCcy'], '').toLowerCase();

  let pricesBookies = state.getIn(['base', 'settings', 'trade', 'pricesBookies'], null);

  let apiSync = state.getIn(['base', 'flags', 'apiSync'], false);
  let waitForPmms = 0;

  let confirmBet;
  if (!apiSync) {
    confirmBet = true;
  } else {
    confirmBet = state.getIn(['base', 'settings', 'trade', 'confirmBet'], true);
  }

  const advancedBetslipInfo = state.getIn(
    ['base', 'settings', 'trade', 'advancedBetslipInfo'],
    true
  );

  return {
    sideMenuOpen: state.getIn(['ui', 'sideMenuOpen'], false),
    accBarOpen: state.getIn(['ui', 'accBarOpen'], false),
    expanded: state.getIn(['ui', 'settings', 'trade', 'betbar', 'expanded'], false),

    marketSelectorOpen: state.getIn(['ui', 'marketSelectorOpen'], false),
    balanceOpen: state.getIn(['ui', 'balanceOpen'], false),
    searchBarOpen: state.getIn(['ui', 'searchBarOpen'], false),

    //basic
    language: state.getIn(
      ['base', 'settings', 'general', 'language'],
      navigator.language.split('-')[0] || config.defaultLanguage
    ),
    timezone: state.getIn(['base', 'settings', 'general', 'timezone'], 'UTC'),
    isAuth: state.getIn(['base', 'isAuth'], 'pending'),
    canSeeBrokerage: state.getIn(['base', 'canSeeBrokerage'], false),

    //connection related
    apiSync: state.getIn(['base', 'flags', 'apiSync'], false),
    pricesSync: state.getIn(['trade', 'flags', 'pricesSync'], false),

    //profile-ish
    xrates: state.getIn(['base', 'xrates'], null),
    placer: state.getIn(['base', 'profile', 'username'], '?'),
    currentUser: state.getIn(['base', 'profile', 'username'], '?'),
    sessionId: state.getIn(['base', 'session', 'sessionId'], '?'),
    name: state.getIn(['base', 'profile', 'name'], '?'),
    sudoer: state.getIn(['base', 'profile', 'sudoer'], ''),
    agent: state.getIn(['base', 'profile', 'config', 'agent'], false),
    superuser: state.getIn(['base', 'profile', 'config', 'superuser'], false),
    netPrices: state.getIn(['base', 'profile', 'config', 'netPrices'], false),

    hideCredit: state.getIn(['base', 'settings', 'general', 'hideCredit'], false),
    hideUsername: state.getIn(['base', 'settings', 'general', 'hideUsername'], false),

    //visual
    priceType: state.getIn(['base', 'settings', 'general', 'priceType'], 'dec'),
    userTradeView: state.getIn(['base', 'settings', 'trade', 'userTradeView'], 'default'),
    ccyCode: state.getIn(['base', 'profile', 'ccyCode'], '?').toLowerCase(),
    displayCcy: displayCcy || state.getIn(['base', 'profile', 'ccyCode'], '').toLowerCase(),

    spyOnGroup: state.getIn(['base', 'profile', 'config', 'spyOnGroup'], false),
    betslipTint: state.getIn(['base', 'settings', 'trade', 'betslipTint'], false),

    sport,
    baseSport,

    enabledGroups: state.getIn(['base', 'settings', 'trade', 'enabledGroups', baseSport], null),
    allEnabledGroups: state.getIn(['base', 'settings', 'trade', 'enabledGroups'], null),
    hideExchangeOnlyLines: state.getIn(
      ['base', 'settings', 'trade', 'hideExchangeOnlyLines'],
      true
    ),
    exchangeMode: state.getIn(['base', 'settings', 'trade', 'exchangeMode'], 'make_and_take'),

    seeAccounts: state.getIn(['base', 'profile', 'config', 'seeAccounts'], false),
    orderLogCategories: state.getIn(['base', 'profile', 'preferences', 'orderLogCategories'], null),
    sideOpen: state.getIn(['base', 'settings', 'trade', 'side', 'open'], true),
    hideFavsCompetitionsHeaders: state.getIn(
      ['base', 'settings', 'trade', 'hideFavsCompetitionsHeaders'],
      false
    ),

    //betslip
    equivalentBets: state.getIn(['base', 'settings', 'trade', 'equivalentBets'], true),
    bookieMinBalances: state.getIn(['base', 'settings', 'trade', 'bookieMinBalances'], null),
    multipleAccounts:
      state.getIn(['base', 'profile', 'config', 'canUseMultipleAccounts'], false) &&
      state.getIn(['base', 'settings', 'trade', 'multipleAccounts'], false),
    waitForPmms: Math.max(
      waitForPmms,
      state.getIn(['base', 'settings', 'trade', 'waitForPmms'], 0)
    ),
    parlayMode: state.getIn(['ui', 'parlayMode'], false),
    maxBetslips: state.getIn(['base', 'profile', 'config', 'settings', 'maxBetslips'], 8),
    multipleHandicapLines: state.getIn(
      ['base', 'settings', 'trade', 'multipleHandicapLines'],
      false
    ),
    autoAddBetsToFavs: state.getIn(['base', 'settings', 'trade', 'autoAddBetsToFavs'], false),
    allTermsAccepted: state.getIn(['base', 'flags', 'allTermsAccepted'], true),
    allowPutOfferOnExchange: state.getIn(
      ['base', 'settings', 'trade', 'allowPutOfferOnExchange'],
      false
    ),
    ignoreSystemMaintenance: state.getIn(
      ['base', 'settings', 'trade', 'ignoreSystemMaintenance'],
      false
    ),
    advancedOrderPlacementOptions: state.getIn(
      ['base', 'settings', 'trade', 'advancedOrderPlacementOptions'],
      false
    ),
    reselectAccounts: state.getIn(['base', 'settings', 'trade', 'reselectAccounts'], 'always'),
    betslipDefaultTimeout: state.getIn(['base', 'settings', 'trade', 'betslipDefaultTimeout'], 20),
    defaultPrice: state.getIn(['base', 'settings', 'trade', 'defaultPrice'], ''),
    defaultStake: state.getIn(['base', 'settings', 'trade', 'defaultStake'], ''),
    webPlaceBets: state.getIn(['base', 'profile', 'config', 'webPlaceBets'], false),
    maxOrder: state.getIn(['base', 'settings', 'trade', 'maxOrder'], null),
    minOrder: state.getIn(['base', 'settings', 'trade', 'minOrder'], 0),
    confirmBet,
    advancedBetslipInfo,

    betslipAgent: state.getIn(['base', 'settings', 'trade', 'betslipAgent'], false),
    useSuggested: state.getIn(['base', 'settings', 'trade', 'useSuggested'], true),

    pricesBookies,
    //because unticked bookies shouldn't matter here
    disabledBookies:
      state.getIn(['base', 'settings', 'trade', 'reselectAccounts'], 'always') !== 'always'
        ? state.getIn(['base', 'settings', 'trade', 'disabledBookies'], null)
        : null,

    //agent limitations
    theme: state.getIn(['base', 'settings', 'general', 'theme'], config.defaultTheme),
    //features
    agentPositionGrids:
      (state.getIn(['base', 'profile', 'config', 'spyOnGroup'], false) ||
        state.getIn(['base', 'profile', 'config', 'agent'], false)) &&
      state.getIn(['base', 'settings', 'trade', 'agentPositionGrids'], false),
    featureDepositButton: state.getIn(['base', 'switches', 'depositButton'], false),
    featureShowInternalInfo:
      state.getIn(['base', 'profile', 'config', 'superuser'], false) ||
      state.getIn(['base', 'profile', 'config', 'limitedAdmin'], false),
    featureAdvancedTradeSettings: state.getIn(['base', 'switches', 'advancedTradeSettings'], false),
    featureParlay: state.getIn(['base', 'switches', 'parlay'], false), //use this, it does the same thing
    featureTranslatedBetTypes: state.getIn(['base', 'switches', 'translatedBetTypes'], false),
    featureLognoice: state.getIn(['base', 'switches', 'lognoice'], false),
    featureFlatten: state.getIn(['base', 'switches', 'flatten'], false),
    featureMultirunner: state.getIn(['base', 'switches', 'multirunner'], false),
    featureSmartCredit: state.getIn(['base', 'switches', 'smartCredit'], false),
    featureHorse: state.getIn(['base', 'switches', 'horse'], false),
    //parlay id
    parlayId: state.getIn(['parlays', 'parlays']).keySeq().first(),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual: (next, prev) => {
    return (
      prev.getIn(['base'], null) === next.getIn(['base'], null) &&
      prev.getIn(['trade', 'sport'], null) === next.getIn(['trade', 'sport'], null) &&
      prev.getIn(['trade', 'baseSport'], null) === next.getIn(['trade', 'baseSport'], null) &&
      prev.getIn(['ui', 'sideMenuOpen'], null) === next.getIn(['ui', 'sideMenuOpen'], null) &&
      prev.getIn(['ui', 'marketSelectorOpen'], null) ===
        next.getIn(['ui', 'marketSelectorOpen'], null) &&
      prev.getIn(['ui', 'balanceOpen'], null) === next.getIn(['ui', 'balanceOpen'], null) &&
      prev.getIn(['ui', 'searchBarOpen'], null) === next.getIn(['ui', 'searchBarOpen'], null) &&
      prev.getIn(['ui', 'parlayMode'], false) === next.getIn(['ui', 'parlayMode'], false) &&
      prev.getIn(['ui', 'settings', 'trade', 'betbar', 'expanded'], false) ===
        next.getIn(['ui', 'settings', 'trade', 'betbar', 'expanded'], false) &&
      prev.getIn(['parlays', 'parlays']).keySeq().first() ===
        next.getIn(['parlays', 'parlays']).keySeq().first()
    );
  },
})(App);
