/** @format */

import config from '../../config';

import * as Sentry from '@sentry/react';
import React from 'react';
import { injectIntl } from 'react-intl';
import { XenaDiv } from './Common';
import { withRouter } from 'react-router';
import styled from 'styled-components';

const ErrorContainer = styled(XenaDiv)`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;

  .error-header {
    padding: 20px;
    padding-top: 70px;
    font-size: 16px;
    font-weight: 300;
  }

  .error-message {
    padding: 20px;
    font-size: 16px;
    font-weight: 300;
  }
`;

class ErrorBoundary extends React.Component {
  state = {
    error: null,
  };

  timerId = null;

  reloadPage() {
    if (window) {
      window.location.reload();
    }
  }

  redirectHome = () => {
    this.props.history.push(`/trade`);
    window.location.reload();
  };

  componentDidCatch(error) {

    let errStr = error ? error.toString() : '?';
    //if it is a JS chunk loading error it means that we've probably deployed
    if (
      errStr.indexOf('Loading chunk') !== -1 ||
      errStr.indexOf('Loading CSS') !== -1 ||
      errStr.indexOf('Unexpected token') !== -1 ||
      errStr.indexOf('expected expression') !== -1 ||
      errStr.indexOf('ChunkLoadError') !== -1
    ) {
      setTimeout(function () {
        if (window) {
          window.location.reload();
        }
      }, config.timings.reloadAferChunkFailure);
      this.setState({ error: null });
    } else {
      Sentry.captureException(error);
      if (config.support.tools.aggressiveErrorMessage) {
        this.setState({ error: errStr });
      }
    }
  }

  componentWillUnmount() {
    if (this.timerId != null) {
      clearTimeout(this.timerId);
    }
  }

  render() {
    if (this.state.error) {
      if (process.env.NODE_ENV === 'production') {
        this.timerId = setTimeout(this.redirectHome, 3500);
      }

      return (
        <ErrorContainer background={'xenaColorDarkest'} color={'fontColorContrast'}>
          <XenaDiv
            background={'xenaColorDarkest'}
            color={'fontColorContrast'}
            className="error-header"
          >
            An error has been recorded and sent to the development team.
          </XenaDiv>
          <XenaDiv
            background={'xenaColorDarkest'}
            color={'fontColorContrast'}
            className="error-message"
          >
            You will now be redirected to the trade page.
          </XenaDiv>
        </ErrorContainer>
      );
    }

    return this.props.children;
  }
}

export default withRouter(injectIntl(ErrorBoundary));
