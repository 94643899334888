/** @format */

import styled, { type StyledComponent } from 'styled-components';
import { type Theme } from '@mollybet/ui/dist/theme';

export const Table: StyledComponent<Theme, *> = styled.table`
  border-spacing: 0;
`;

export const TableCell: StyledComponent<
  { elevation?: number, colspan?: number, rowspan?: number, ... },
  Theme,
  *
> = styled.td`
  background-color: ${(props) =>
    props.elevation !== undefined ? props.theme.paper.background[props.elevation] : 'inherit'};
  color: ${(props) =>
    props.elevation !== undefined ? props.theme.paper.fontColor[props.elevation] : 'inherit'};
  padding: 3px;
`;

export const TableHead: StyledComponent<{ elevation?: number, ... }, Theme, *> = styled.thead`
  background-color: ${(props) => props.theme.paper.background[props.elevation || 1]};
  color: ${(props) => props.theme.paper.fontColor[props.elevation || 1]};

  ${TableCell} {
    text-align: center;
    padding: 12px;
  }
`;

export const TableBody: StyledComponent<Theme, *> = styled.tbody`
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.foreground};
`;

export const TableRow: StyledComponent<{ elevation?: number, ... }, Theme, *> = styled.tr`
  background-color: ${(props) =>
    props.elevation !== undefined ? props.theme.paper.background[props.elevation] : 'inherit'};
  color: ${(props) =>
    props.elevation !== undefined ? props.theme.paper.fontColor[props.elevation] : 'inherit'};
`;
