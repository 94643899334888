/** @format */

// @flow
import * as React from 'react';

import styled, { type StyledComponent } from 'styled-components';
import { type Theme } from '@mollybet/ui/dist/theme';
import { Paper } from './Paper';
import { Button, NegativeButton, PositiveButton } from './Button';

import { Modal } from '@mollybet/ui';

export const Dialog = (props) => (
  <Modal {...props}>
    <Paper elevation={1}>{props.children}</Paper>
  </Modal>
);

export const DialogTitle: StyledComponent<{ ... }, Theme, *> = styled.h1`
  text-align: left;
  font-size: 120%;
  padding: 0 20px;
  margin: 20px 0;
  display: block;
  width: 100%;
  box-sizing: border-box;

  svg {
    margin-right: 20px;
    vertical-align: middle;
  }
`;

export const DialogContent: StyledComponent<{ ... }, Theme, *> = styled.div`
  display: block;
  width: 100%;
  padding: 0 20px;
  margin: 0 0 20px 0;
  box-sizing: border-box;
  max-width: 800px;
`;

export const DialogActions: StyledComponent<{ ... }, Theme, *> = styled.div`
  display: block;
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  ${Button},
  ${NegativeButton},
  ${PositiveButton} {
    padding: 10px 20px;
    display: flex;
    flex-grow: 1;
    text-transform: uppercase;
    border-radius: 0;
    text-align: center;

    span {
      width: 100%;
      display: block;
    }
  }
`;
