/** @format */

import config from '../../config';
import { actions } from '../../reducers/app';

import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Transition } from 'react-spring/renderprops';
import styled from 'styled-components';

import Order from './betbar/Order';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const BetBarContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 6px;
  right: 0;
  width: 100%;
  z-index: 1000;
  height: 130px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  touch-action: pan-x;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;

  .betbar-orders-container {
    width: 100%;
    height: 130px;
  }
`;

const BetBar = (props) => {

  const _orders = useRef();
  const _statsInterval = useRef();
  const _collectionBackoff = useRef();
  const orderList = useRef();

  const prevProps = usePrevious(props);

  const toggle = () => {
    props.actions.toggleBetbarExpanded();
  };

  // deals with component mounting and unmounting
  useEffect(() => {
    _orders.current = {};
    _statsInterval.current = {};
    _collectionBackoff.current = {};

    if (config.support.tools.recordBetbarSnapshots && !props.agent && !props.spyOnGroup) {
      //agent is too much
      _collectionBackoff.current = setTimeout(() => {
        props.actions.betbarSnapshot();
        _statsInterval.current = setInterval(
          props.actions.betbarSnapshot,
          config.timings.takeBetbarSnapshot
        );
      }, config.timings.collectBackoff);
    }

    return function cleanUp() {
      _orders.current = null;

      if (_collectionBackoff.current) {
        clearTimeout(_collectionBackoff.current);
      }

      if (_statsInterval.current) {
        clearInterval(_statsInterval.current);
      }
    };
  }, [props.actions, props.agent, props.spyOnGroup]);

  useEffect(() => {
    // compare prevProps order length to see if should scroll orders left to show new order
    if (props.orders) {
      if (prevProps && prevProps.orders && prevProps.orders.size < props.orders.size) {
        // new order made scroll to the top of the list
        if (orderList.current) {
          orderList.current.scrollLeft = 0;
        }
      }
    }
  });

  let orders = [];
  let orderIds = [];

  if (props.orders) {
    props.orders.forEach((order, orderId) => {
      orderIds.push(orderId);
    });
  }

  let hasOrders = orderIds.length;

  if (!hasOrders && props.apiSync) {
    orders.push(
      <div key={0} className="no-orders">
        <FormattedMessage id="trade.betbar.noOrders" defaultMessage="No recent orders" />
      </div>
    );
  } else {
    orders = orderIds
      .sort((a, b) => {
        let ptA = new Date(props.orders.getIn([a, 'placementTime'], ''));
        let ptB = new Date(props.orders.getIn([b, 'placementTime'], ''));
        if (ptA < ptB) {
          return 1;
        } else if (ptA > ptB) {
          return -1;
        } else {
          return 0;
        }
      })
      .slice(0, config.betbar.maxOrders)
      .map((orderId) => {
        if (!_orders.current || !_orders.current[orderId]) {
          if (_orders.current) {
            _orders.current[orderId] = Order(orderId);
          } else {
            _orders.current = {};
            _orders.current[orderId] = Order(orderId);
          }
        }
        let Ord = _orders.current[orderId];
        return <Ord toggle={toggle} key={orderId} orderId={orderId} slim={props.slim} />;
      });
  }
  if (orders && !orders.length) {
    return null;
  }
  return (
    <Transition
      items={props.expanded}
      from={{ transform: 'translate3d(0px,130px,0)' }}
      enter={{ transform: 'translate3d(0,0px,0)' }}
      leave={{ transform: 'translate3d(0px,130px,0)' }}
    >
      {(expanded) => (styles) =>
        expanded && (
          <BetBarContainer ref={orderList} style={styles} className="betbar">
            {orders}
          </BetBarContainer>
        )}
    </Transition>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// turn state of combined reducers into state required by component
const mapStateToProps = (state, ownProps) => {
  return {
    orders: state.getIn(['betbar', 'orders'], null),
    expanded: state.getIn(['ui', 'settings', 'trade', 'betbar', 'expanded'], false),
    agent: state.getIn(['base', 'profile', 'config', 'agent'], false), //need
    spyOnGroup: state.getIn(['base', 'profile', 'config', 'spyOnGroup'], false), //need
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual: (next, prev) => {
    return (
      prev.get('base', null) === next.get('base', null) &&
      prev.get('betbar', null) === next.get('betbar', null) &&
      prev.get('ui', null) === next.get('ui', null)
    );
  },
})(injectIntl(BetBar));
