/** @format */

//last generated: Tue Apr 19 2022 13:28:18 GMT+0100 (British Summer Time)
import React from 'react';
import { FormattedMessage } from 'react-intl';
export default (
  <>
    <FormattedMessage id="bookies.18bet" defaultMessage="18bet" />
    <FormattedMessage id="bookies.3et" defaultMessage="3et" />
    <FormattedMessage id="bookies.bdaq" defaultMessage="bdaq" />
    <FormattedMessage id="bookies.bf" defaultMessage="bf" />
    <FormattedMessage id="bookies.bf-es" defaultMessage="bf-es" />
    <FormattedMessage id="bookies.bf-it" defaultMessage="bf-it" />
    <FormattedMessage id="bookies.bill" defaultMessage="bill" />
    <FormattedMessage id="bookies.boom" defaultMessage="boom" />
    <FormattedMessage id="bookies.bookeu" defaultMessage="bookeu" />
    <FormattedMessage id="bookies.broker1" defaultMessage="broker1" />
    <FormattedMessage id="bookies.broker2" defaultMessage="broker2" />
    <FormattedMessage id="bookies.broker3" defaultMessage="broker3" />
    <FormattedMessage id="bookies.broker5" defaultMessage="broker5" />
    <FormattedMessage id="bookies.broker6" defaultMessage="broker6" />
    <FormattedMessage id="bookies.broker7" defaultMessage="broker7" />
    <FormattedMessage id="bookies.broker8" defaultMessage="broker8" />
    <FormattedMessage id="bookies.broker9" defaultMessage="broker9" />
    <FormattedMessage id="bookies.brokie" defaultMessage="brokie" />
    <FormattedMessage id="bookies.cmd" defaultMessage="cmd" />
    <FormattedMessage id="bookies.accas" defaultMessage="accas" />
    <FormattedMessage id="bookies.dummy" defaultMessage="dummy" />
    <FormattedMessage id="bookies.dummy2" defaultMessage="dummy2" />
    <FormattedMessage id="bookies.ga288" defaultMessage="ga288" />
    <FormattedMessage id="bookies.ibc" defaultMessage="ibc" />
    <FormattedMessage id="bookies.isn" defaultMessage="isn" />
    <FormattedMessage id="bookies.iwin" defaultMessage="iwin" />
    <FormattedMessage id="bookies.jabet" defaultMessage="jabet" />
    <FormattedMessage id="bookies.lsm" defaultMessage="lsm" />
    <FormattedMessage id="bookies.mbook" defaultMessage="mbook" />
    <FormattedMessage id="bookies.metric" defaultMessage="metric" />
    <FormattedMessage id="bookies.mmm" defaultMessage="mmm" />
    <FormattedMessage id="bookies.molly" defaultMessage="molly" />
    <FormattedMessage id="bookies.penta88" defaultMessage="penta88" />
    <FormattedMessage id="bookies.pin" defaultMessage="pin" />
    <FormattedMessage id="bookies.pin88" defaultMessage="pin88" />
    <FormattedMessage id="bookies.redzone" defaultMessage="redzone" />
    <FormattedMessage id="bookies.sbo" defaultMessage="sbo" />
    <FormattedMessage id="bookies.sbo2" defaultMessage="sbo2" />
    <FormattedMessage id="bookies.sing2" defaultMessage="sing2" />
    <FormattedMessage id="bookies.sjodds" defaultMessage="sjodds" />
    <FormattedMessage id="bookies.smk" defaultMessage="smk" />
    <FormattedMessage id="bookies.spm" defaultMessage="spm" />
    <FormattedMessage id="bookies.winft" defaultMessage="winft" />
    <FormattedMessage id="bookies.wlg" defaultMessage="wlg" />
    <FormattedMessage id="bookies.wuming" defaultMessage="wuming" />
    <FormattedMessage id="bookies.other" defaultMessage="other" />
    <FormattedMessage id="marketNames.favs" defaultMessage="Favourites" />
    <FormattedMessage id="marketNames.ir" defaultMessage="In Running" />
    <FormattedMessage id="marketNames.today" defaultMessage="Today" />
    <FormattedMessage id="marketNames.early" defaultMessage="Early" />
    <FormattedMessage id="groupNames.wdw" defaultMessage="1 X 2" />
    <FormattedMessage id="groupNames.short.wdw" defaultMessage="1X2" />
    <FormattedMessage id="betTypes.wdw,h" defaultMessage="{home}" />
    <FormattedMessage id="columnNames.wdw,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.wdw,d" defaultMessage="Draw" />
    <FormattedMessage id="columnNames.wdw,d" defaultMessage="X" />
    <FormattedMessage id="betTypes.wdw,a" defaultMessage="{away}" />
    <FormattedMessage id="columnNames.wdw,a" defaultMessage="2" />
    <FormattedMessage id="betTypes.wdw,sd" defaultMessage="Score Draw" />
    <FormattedMessage id="columnNames.wdw,sd" defaultMessage="SD" />
    <FormattedMessage id="groupNames.dc" defaultMessage="Double Chance" />
    <FormattedMessage id="groupNames.short.dc" defaultMessage="Double" />
    <FormattedMessage id="betTypes.dc,h,d" defaultMessage="{home} or Draw" />
    <FormattedMessage id="columnNames.dc,h,d" defaultMessage="1X" />
    <FormattedMessage id="betTypes.dc,h,a" defaultMessage="{home} or {away}" />
    <FormattedMessage id="columnNames.dc,h,a" defaultMessage="12" />
    <FormattedMessage id="betTypes.dc,a,d" defaultMessage="{away} or Draw" />
    <FormattedMessage id="columnNames.dc,a,d" defaultMessage="X2" />
    <FormattedMessage id="betTypes.dc,d,a" defaultMessage="{away} or Draw" />
    <FormattedMessage id="columnNames.dc,d,a" defaultMessage="X2" />
    <FormattedMessage id="betTypes.dc,d,h" defaultMessage="Draw or {home}" />
    <FormattedMessage id="columnNames.dc,d,h" defaultMessage="X1" />
    <FormattedMessage id="betTypes.dc,a,h" defaultMessage="{away} or {home}" />
    <FormattedMessage id="columnNames.dc,a,h" defaultMessage="21" />
    <FormattedMessage id="groupNames.ml" defaultMessage="Moneyline" />
    <FormattedMessage id="groupNames.short.ml" defaultMessage="1/2" />
    <FormattedMessage id="betTypes.ml,h" defaultMessage="{home}" />
    <FormattedMessage id="columnNames.ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.ml,a" defaultMessage="{away}" />
    <FormattedMessage id="columnNames.ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.ah" defaultMessage="Asian Handicap" />
    <FormattedMessage id="groupNames.short.ah" defaultMessage="Asian Hcp." />
    <FormattedMessage id="betTypes.ah,h" defaultMessage="{home} {handicap} (Asian)" />
    <FormattedMessage id="columnNames.ah,h" defaultMessage="A/1" />
    <FormattedMessage id="betTypes.ah,ir,h" defaultMessage="{home} {handicap} (Asian, {homeScore} - {awayScore})" />
    <FormattedMessage id="betTypes.ah,a" defaultMessage="{away} {handicap} (Asian)" />
    <FormattedMessage id="columnNames.ah,a" defaultMessage="A/2" />
    <FormattedMessage id="betTypes.ah,ir,a" defaultMessage="{away} {handicap} (Asian, {homeScore} - {awayScore})" />
    <FormattedMessage id="groupNames.ahou" defaultMessage="Asian Total" />
    <FormattedMessage id="groupNames.short.ahou" defaultMessage="Asian O/U" />
    <FormattedMessage id="betTypes.ahou,over" defaultMessage="Over {handicap} (Asian)" />
    <FormattedMessage id="columnNames.ahou,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.ahou,ir,over" defaultMessage="Over {handicap} (Asian, {homeScore} - {awayScore})" />
    <FormattedMessage id="betTypes.ahou,under" defaultMessage="Under {handicap} (Asian)" />
    <FormattedMessage id="columnNames.ahou,under" defaultMessage="U" />
    <FormattedMessage id="betTypes.ahou,ir,under" defaultMessage="Under {handicap} (Asian, {homeScore} - {awayScore})" />
    <FormattedMessage id="groupNames.tahou,a" defaultMessage="Away Goals" />
    <FormattedMessage id="groupNames.short.tahou,a" defaultMessage="Away O/U" />
    <FormattedMessage id="betTypes.tahou,a,over" defaultMessage="{away} to score Over {handicap}" />
    <FormattedMessage id="columnNames.tahou,a,over" defaultMessage="2/O" />
    <FormattedMessage id="betTypes.tahou,a,under" defaultMessage="{away} to score Under {handicap}" />
    <FormattedMessage id="columnNames.tahou,a,under" defaultMessage="2/U" />
    <FormattedMessage id="groupNames.tahou,h" defaultMessage="Home Goals" />
    <FormattedMessage id="groupNames.short.tahou,h" defaultMessage="Home O/U" />
    <FormattedMessage id="betTypes.tahou,h,over" defaultMessage="{home} to score Over {handicap}" />
    <FormattedMessage id="columnNames.tahou,h,over" defaultMessage="1/O" />
    <FormattedMessage id="betTypes.tahou,h,under" defaultMessage="{home} to score Under {handicap}" />
    <FormattedMessage id="columnNames.tahou,h,under" defaultMessage="1/U" />
    <FormattedMessage id="groupNames.htft" defaultMessage="Half/Full Time" />
    <FormattedMessage id="groupNames.short.htft" defaultMessage="Half/Full" />
    <FormattedMessage id="betTypes.htft,h,h" defaultMessage="{home} Half, {home} Full" />
    <FormattedMessage id="columnNames.htft,h,h" defaultMessage="1/1" />
    <FormattedMessage id="betTypes.htft,h,d" defaultMessage="{home} Half, Draw Full" />
    <FormattedMessage id="columnNames.htft,h,d" defaultMessage="1/X" />
    <FormattedMessage id="betTypes.htft,h,a" defaultMessage="{home} Half, {away} Full" />
    <FormattedMessage id="columnNames.htft,h,a" defaultMessage="1/2" />
    <FormattedMessage id="betTypes.htft,d,h" defaultMessage="Draw Half, {home} Full" />
    <FormattedMessage id="columnNames.htft,d,h" defaultMessage="X/1" />
    <FormattedMessage id="betTypes.htft,d,d" defaultMessage="Draw Half, Draw Full" />
    <FormattedMessage id="columnNames.htft,d,d" defaultMessage="X/X" />
    <FormattedMessage id="betTypes.htft,d,a" defaultMessage="Draw Half, {away} Full" />
    <FormattedMessage id="columnNames.htft,d,a" defaultMessage="X/2" />
    <FormattedMessage id="betTypes.htft,a,h" defaultMessage="{away} Half, {home} Full" />
    <FormattedMessage id="columnNames.htft,a,h" defaultMessage="2/1" />
    <FormattedMessage id="betTypes.htft,a,d" defaultMessage="{away} Half, Draw Full" />
    <FormattedMessage id="columnNames.htft,a,d" defaultMessage="2/X" />
    <FormattedMessage id="betTypes.htft,a,a" defaultMessage="{away} Half, {away} Full" />
    <FormattedMessage id="columnNames.htft,a,a" defaultMessage="2/2" />
    <FormattedMessage id="groupNames.wg" defaultMessage="Win and score at least" />
    <FormattedMessage id="groupNames.short.wg" defaultMessage="1X2+O" />
    <FormattedMessage id="betTypes.wg,h" defaultMessage="{home} Win and score at least {handicap} Goals" />
    <FormattedMessage id="columnNames.wg,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.wg,a" defaultMessage="{away} Win and score at least {handicap} Goals" />
    <FormattedMessage id="columnNames.wg,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.wmo" defaultMessage="Win by goals and total goals over" />
    <FormattedMessage id="groupNames.short.wmo" defaultMessage="Win by and total over" />
    <FormattedMessage id="betTypes.wmo,h" defaultMessage="{home} Win by goals and Over {handicap} goals" />
    <FormattedMessage id="columnNames.wmo,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.wmo,a" defaultMessage="{away} Win by goals and Over {handicap} goals" />
    <FormattedMessage id="columnNames.wmo,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.moou" defaultMessage="1X2 O/U" />
    <FormattedMessage id="groupNames.short.moou" defaultMessage="1X2 O/U" />
    <FormattedMessage id="betTypes.moou,h,over" defaultMessage="{home} AND Over {handicap}" />
    <FormattedMessage id="columnNames.moou,h,over" defaultMessage="1+O" />
    <FormattedMessage id="betTypes.moou,h,under" defaultMessage="{home} AND Under {handicap}" />
    <FormattedMessage id="columnNames.moou,h,under" defaultMessage="1+U" />
    <FormattedMessage id="betTypes.moou,d,over" defaultMessage="Draw AND Over {handicap}" />
    <FormattedMessage id="columnNames.moou,d,over" defaultMessage="X+O" />
    <FormattedMessage id="betTypes.moou,d,under" defaultMessage="Draw AND Under {handicap}" />
    <FormattedMessage id="columnNames.moou,d,under" defaultMessage="X+U" />
    <FormattedMessage id="betTypes.moou,a,over" defaultMessage="{away} AND Over {handicap}" />
    <FormattedMessage id="columnNames.moou,a,over" defaultMessage="2+O" />
    <FormattedMessage id="betTypes.moou,a,under" defaultMessage="{away} AND Under {handicap}" />
    <FormattedMessage id="columnNames.moou,a,under" defaultMessage="2+U" />
    <FormattedMessage id="groupNames.moBothScore" defaultMessage="1X2 + Both Score" />
    <FormattedMessage id="groupNames.short.moBothScore" defaultMessage="1X2 + Score" />
    <FormattedMessage id="betTypes.moBothScore,h,yes" defaultMessage="{home} AND Both Score" />
    <FormattedMessage id="columnNames.moBothScore,h,yes" defaultMessage="1+Yes" />
    <FormattedMessage id="betTypes.moBothScore,h,no" defaultMessage="{home} AND At least one does not score" />
    <FormattedMessage id="columnNames.moBothScore,h,no" defaultMessage="1+No" />
    <FormattedMessage id="betTypes.moBothScore,d,yes" defaultMessage="Draw AND Both Score" />
    <FormattedMessage id="columnNames.moBothScore,d,yes" defaultMessage="X+Yes" />
    <FormattedMessage id="betTypes.moBothScore,d,no" defaultMessage="Draw AND At least one does not score" />
    <FormattedMessage id="columnNames.moBothScore,d,no" defaultMessage="X+No" />
    <FormattedMessage id="betTypes.moBothScore,a,yes" defaultMessage="{away} AND Both Score" />
    <FormattedMessage id="columnNames.moBothScore,a,yes" defaultMessage="2+Yes" />
    <FormattedMessage id="betTypes.moBothScore,a,no" defaultMessage="{away} AND At least one does not score" />
    <FormattedMessage id="columnNames.moBothScore,a,no" defaultMessage="2+No" />
    <FormattedMessage id="groupNames.aou" defaultMessage="1X2+O" />
    <FormattedMessage id="groupNames.short.aou" defaultMessage="1X2 O" />
    <FormattedMessage id="betTypes.aou,h" defaultMessage="{home} to win AND score MORE than {handicap} goals" />
    <FormattedMessage id="columnNames.aou,h" defaultMessage="1+O" />
    <FormattedMessage id="betTypes.aou,d" defaultMessage="Draw AND each team scores MORE than {handicap} goals" />
    <FormattedMessage id="columnNames.aou,d" defaultMessage="X+O" />
    <FormattedMessage id="betTypes.aou,a" defaultMessage="{away} to win AND score MORE than {handicap} goals" />
    <FormattedMessage id="columnNames.aou,a" defaultMessage="2+O" />
    <FormattedMessage id="groupNames.timeWin,tinnings,1,ml" defaultMessage="Moneyline (1st inning)" />
    <FormattedMessage id="groupNames.short.timeWin,tinnings,1,ml" defaultMessage="1/2 (1I)" />
    <FormattedMessage id="betTypes.timeWin,tinnings,1,ml,h" defaultMessage="{home} (1st inning)" />
    <FormattedMessage id="columnNames.timeWin,tinnings,1,ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tinnings,1,ml,a" defaultMessage="{away} (1st inning)" />
    <FormattedMessage id="columnNames.timeWin,tinnings,1,ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeAh,tinnings,1" defaultMessage="Asian Hcp. (1st inning)" />
    <FormattedMessage id="groupNames.short.timeAh,tinnings,1" defaultMessage="Asian Hcp. (1I)" />
    <FormattedMessage id="betTypes.timeAh,tinnings,1,h" defaultMessage="{home} {handicap} (Asian, 1st inning)" />
    <FormattedMessage id="columnNames.timeAh,tinnings,1,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeAh,tinnings,1,a" defaultMessage="{away} {handicap} (Asian, 1st inning)" />
    <FormattedMessage id="columnNames.timeAh,tinnings,1,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeAhou,tinnings,1" defaultMessage="Asian O/U (1st inning)" />
    <FormattedMessage id="groupNames.short.timeAhou,tinnings,1" defaultMessage="Asian O/U (1I)" />
    <FormattedMessage id="betTypes.timeAhou,tinnings,1,over" defaultMessage="Over {handicap} (Asian, 1st inning)" />
    <FormattedMessage id="columnNames.timeAhou,tinnings,1,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.timeAhou,tinnings,1,under" defaultMessage="Under {handicap} (Asian, 1st inning)" />
    <FormattedMessage id="columnNames.timeAhou,tinnings,1,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeWin,tinnings,2,ml" defaultMessage="Moneyline (2nd inning)" />
    <FormattedMessage id="groupNames.short.timeWin,tinnings,2,ml" defaultMessage="1/2 (2I)" />
    <FormattedMessage id="betTypes.timeWin,tinnings,2,ml,h" defaultMessage="{home} (2nd inning)" />
    <FormattedMessage id="columnNames.timeWin,tinnings,2,ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tinnings,2,ml,a" defaultMessage="{away} (2nd inning)" />
    <FormattedMessage id="columnNames.timeWin,tinnings,2,ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeAh,tinnings,2" defaultMessage="Asian Hcp. (2nd inning)" />
    <FormattedMessage id="groupNames.short.timeAh,tinnings,2" defaultMessage="Asian Hcp. (2I)" />
    <FormattedMessage id="betTypes.timeAh,tinnings,2,h" defaultMessage="{home} {handicap} (Asian, 2nd inning)" />
    <FormattedMessage id="columnNames.timeAh,tinnings,2,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeAh,tinnings,2,a" defaultMessage="{away} {handicap} (Asian, 2nd inning)" />
    <FormattedMessage id="columnNames.timeAh,tinnings,2,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeAhou,tinnings,2" defaultMessage="Asian O/U (2nd inning)" />
    <FormattedMessage id="groupNames.short.timeAhou,tinnings,2" defaultMessage="Asian O/U (2I)" />
    <FormattedMessage id="betTypes.timeAhou,tinnings,2,over" defaultMessage="Over {handicap} (Asian, 2nd inning)" />
    <FormattedMessage id="columnNames.timeAhou,tinnings,2,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.timeAhou,tinnings,2,under" defaultMessage="Under {handicap} (Asian, 2nd inning)" />
    <FormattedMessage id="columnNames.timeAhou,tinnings,2,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeWin,tinnings,3,ml" defaultMessage="Moneyline (3rd inning)" />
    <FormattedMessage id="groupNames.short.timeWin,tinnings,3,ml" defaultMessage="1/2 (3I)" />
    <FormattedMessage id="betTypes.timeWin,tinnings,3,ml,h" defaultMessage="{home} (3rd inning)" />
    <FormattedMessage id="columnNames.timeWin,tinnings,3,ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tinnings,3,ml,a" defaultMessage="{away} (3rd inning)" />
    <FormattedMessage id="columnNames.timeWin,tinnings,3,ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeAh,tinnings,3" defaultMessage="Asian Hcp. (3rd inning)" />
    <FormattedMessage id="groupNames.short.timeAh,tinnings,3" defaultMessage="Asian Hcp. (3I)" />
    <FormattedMessage id="betTypes.timeAh,tinnings,3,h" defaultMessage="{home} {handicap} (Asian, 3rd inning)" />
    <FormattedMessage id="columnNames.timeAh,tinnings,3,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeAh,tinnings,3,a" defaultMessage="{away} {handicap} (Asian, 3rd inning)" />
    <FormattedMessage id="columnNames.timeAh,tinnings,3,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeAhou,tinnings,3" defaultMessage="Asian O/U (3rd inning)" />
    <FormattedMessage id="groupNames.short.timeAhou,tinnings,3" defaultMessage="Asian O/U (3I)" />
    <FormattedMessage id="betTypes.timeAhou,tinnings,3,over" defaultMessage="Over {handicap} (Asian, 3rd inning)" />
    <FormattedMessage id="columnNames.timeAhou,tinnings,3,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.timeAhou,tinnings,3,under" defaultMessage="Under {handicap} (Asian, 3rd inning)" />
    <FormattedMessage id="columnNames.timeAhou,tinnings,3,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeWin,tinnings,4,ml" defaultMessage="Moneyline (4th inning)" />
    <FormattedMessage id="groupNames.short.timeWin,tinnings,4,ml" defaultMessage="1/2 (4I)" />
    <FormattedMessage id="betTypes.timeWin,tinnings,4,ml,h" defaultMessage="{home} (4th inning)" />
    <FormattedMessage id="columnNames.timeWin,tinnings,4,ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tinnings,4,ml,a" defaultMessage="{away} (4th inning)" />
    <FormattedMessage id="columnNames.timeWin,tinnings,4,ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeAh,tinnings,4" defaultMessage="Asian Hcp. (4th inning)" />
    <FormattedMessage id="groupNames.short.timeAh,tinnings,4" defaultMessage="Asian Hcp. (4I)" />
    <FormattedMessage id="betTypes.timeAh,tinnings,4,h" defaultMessage="{home} {handicap} (Asian, 4th inning)" />
    <FormattedMessage id="columnNames.timeAh,tinnings,4,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeAh,tinnings,4,a" defaultMessage="{away} {handicap} (Asian, 4th inning)" />
    <FormattedMessage id="columnNames.timeAh,tinnings,4,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeAhou,tinnings,4" defaultMessage="Asian O/U (4th inning)" />
    <FormattedMessage id="groupNames.short.timeAhou,tinnings,4" defaultMessage="Asian O/U (4I)" />
    <FormattedMessage id="betTypes.timeAhou,tinnings,4,over" defaultMessage="Over {handicap} (Asian, 4th inning)" />
    <FormattedMessage id="columnNames.timeAhou,tinnings,4,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.timeAhou,tinnings,4,under" defaultMessage="Under {handicap} (Asian, 4th inning)" />
    <FormattedMessage id="columnNames.timeAhou,tinnings,4,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeWin,tinnings,5,ml" defaultMessage="Moneyline (5th inning)" />
    <FormattedMessage id="groupNames.short.timeWin,tinnings,5,ml" defaultMessage="1/2 (5I)" />
    <FormattedMessage id="betTypes.timeWin,tinnings,5,ml,h" defaultMessage="{home} (5th inning)" />
    <FormattedMessage id="columnNames.timeWin,tinnings,5,ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tinnings,5,ml,a" defaultMessage="{away} (5th inning)" />
    <FormattedMessage id="columnNames.timeWin,tinnings,5,ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeAh,tinnings,5" defaultMessage="Asian Hcp. (5th inning)" />
    <FormattedMessage id="groupNames.short.timeAh,tinnings,5" defaultMessage="Asian Hcp. (5I)" />
    <FormattedMessage id="betTypes.timeAh,tinnings,5,h" defaultMessage="{home} {handicap} (Asian, 5th inning)" />
    <FormattedMessage id="columnNames.timeAh,tinnings,5,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeAh,tinnings,5,a" defaultMessage="{away} {handicap} (Asian, 5th inning)" />
    <FormattedMessage id="columnNames.timeAh,tinnings,5,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeAhou,tinnings,5" defaultMessage="Asian O/U (5th inning)" />
    <FormattedMessage id="groupNames.short.timeAhou,tinnings,5" defaultMessage="Asian O/U (5I)" />
    <FormattedMessage id="betTypes.timeAhou,tinnings,5,over" defaultMessage="Over {handicap} (Asian, 5th inning)" />
    <FormattedMessage id="columnNames.timeAhou,tinnings,5,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.timeAhou,tinnings,5,under" defaultMessage="Under {handicap} (Asian, 5th inning)" />
    <FormattedMessage id="columnNames.timeAhou,tinnings,5,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.gr" defaultMessage="Total Between" />
    <FormattedMessage id="groupNames.short.gr" defaultMessage="Total Min/Max" />
    <FormattedMessage id="betTypes.gr,gr" defaultMessage="Total Goals {handicap} (inclusive)" />
    <FormattedMessage id="groupNames.teamgr,a" defaultMessage="Away Total Goals Between" />
    <FormattedMessage id="groupNames.short.teamgr,a" defaultMessage="Away Min/Max" />
    <FormattedMessage id="betTypes.teamgr,a" defaultMessage="Away Total Goals {handicap} (inclusive)" />
    <FormattedMessage id="groupNames.teamgr,h" defaultMessage="Home Total Goals Between" />
    <FormattedMessage id="groupNames.short.teamgr,h" defaultMessage="Home Min/Max" />
    <FormattedMessage id="betTypes.teamgr,h" defaultMessage="Home Total Goals {handicap} (inclusive)" />
    <FormattedMessage id="groupNames.awm" defaultMessage="Goal Margin" />
    <FormattedMessage id="groupNames.short.awm" defaultMessage="Margin" />
    <FormattedMessage id="betTypes.awm,awm" defaultMessage="Goal Margin" />
    <FormattedMessage id="groupNames.wm" defaultMessage="Win by" />
    <FormattedMessage id="groupNames.short.wm" defaultMessage="Win by" />
    <FormattedMessage id="betTypes.wm,h" defaultMessage="{home} Win By {handicap} Goals" />
    <FormattedMessage id="columnNames.wm,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.wm,a" defaultMessage="{away} Win By {handicap} Goals" />
    <FormattedMessage id="columnNames.wm,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.swm" defaultMessage="Win Margin" />
    <FormattedMessage id="groupNames.short.swm" defaultMessage="Win Margin" />
    <FormattedMessage id="betTypes.swm,no_goal" defaultMessage="No goals" />
    <FormattedMessage id="columnNames.swm,no_goal" defaultMessage="1" />
    <FormattedMessage id="betTypes.swm,sd" defaultMessage="Score Draw" />
    <FormattedMessage id="columnNames.swm,sd" defaultMessage="2" />
    <FormattedMessage id="groupNames.ko" defaultMessage="Kick Off" />
    <FormattedMessage id="groupNames.short.ko" defaultMessage="Kick Off" />
    <FormattedMessage id="betTypes.ko,h" defaultMessage="{home} to Kick Off" />
    <FormattedMessage id="columnNames.ko,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.ko,a" defaultMessage="{away} to Kick Off" />
    <FormattedMessage id="columnNames.ko,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.eh" defaultMessage="English Handicap" />
    <FormattedMessage id="groupNames.short.eh" defaultMessage="En Hcp." />
    <FormattedMessage id="betTypes.eh,h" defaultMessage="{home} {handicap} (English)" />
    <FormattedMessage id="columnNames.eh,h" defaultMessage="E/1" />
    <FormattedMessage id="betTypes.eh,d" defaultMessage="{home} {handicap} (English)" />
    <FormattedMessage id="columnNames.eh,d" defaultMessage="E/X" />
    <FormattedMessage id="betTypes.eh,a" defaultMessage="{away} {handicap} (English)" />
    <FormattedMessage id="columnNames.eh,a" defaultMessage="E/2" />
    <FormattedMessage id="groupNames.oueq" defaultMessage="Total or Equal" />
    <FormattedMessage id="groupNames.short.oueq" defaultMessage="O/U + Equal" />
    <FormattedMessage id="betTypes.oueq,over" defaultMessage="Over or Equal {handicap}" />
    <FormattedMessage id="columnNames.oueq,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.oueq,under" defaultMessage="Under or Equal {handicap}" />
    <FormattedMessage id="columnNames.oueq,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.ou" defaultMessage="Total Goals (non Asian)" />
    <FormattedMessage id="groupNames.short.ou" defaultMessage="O/U (nA)" />
    <FormattedMessage id="betTypes.ou,over" defaultMessage="Over {handicap}" />
    <FormattedMessage id="columnNames.ou,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.ou,ir,over" defaultMessage="Over {handicap} ({homeScore} - {awayScore})" />
    <FormattedMessage id="betTypes.ou,under" defaultMessage="Under {handicap}" />
    <FormattedMessage id="columnNames.ou,under" defaultMessage="U" />
    <FormattedMessage id="betTypes.ou,ir,under" defaultMessage="Under {handicap} ({homeScore} - {awayScore})" />
    <FormattedMessage id="groupNames.win90" defaultMessage="Win 90 Mins" />
    <FormattedMessage id="groupNames.short.win90" defaultMessage="1/2 (90)" />
    <FormattedMessage id="betTypes.win_90,h" defaultMessage="{home}" />
    <FormattedMessage id="columnNames.win_90,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.win_90,a" defaultMessage="{away}" />
    <FormattedMessage id="columnNames.win_90,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.dnb" defaultMessage="Moneyline (draw no bet)" />
    <FormattedMessage id="groupNames.short.dnb" defaultMessage="1/2 (dnb)" />
    <FormattedMessage id="betTypes.dnb,h" defaultMessage="{home} (draw no bet)" />
    <FormattedMessage id="columnNames.dnb,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.dnb,a" defaultMessage="{away} (draw no bet)" />
    <FormattedMessage id="columnNames.dnb,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.anb" defaultMessage="Away No Bet" />
    <FormattedMessage id="groupNames.short.anb" defaultMessage="1/X (anb)" />
    <FormattedMessage id="betTypes.anb,h" defaultMessage="{home} (away no bet)" />
    <FormattedMessage id="columnNames.anb,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.anb,d" defaultMessage="Draw (away no bet)" />
    <FormattedMessage id="columnNames.anb,d" defaultMessage="X" />
    <FormattedMessage id="groupNames.hnb" defaultMessage="Home No Bet" />
    <FormattedMessage id="groupNames.short.hnb" defaultMessage="X/2 (hnb)" />
    <FormattedMessage id="betTypes.hnb,d" defaultMessage="Draw (home no bet)" />
    <FormattedMessage id="columnNames.hnb,d" defaultMessage="X" />
    <FormattedMessage id="betTypes.hnb,a" defaultMessage="{home} (home no bet)" />
    <FormattedMessage id="columnNames.hnb,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.fg" defaultMessage="First Goal" />
    <FormattedMessage id="groupNames.short.fg" defaultMessage="First Goal" />
    <FormattedMessage id="betTypes.fg,no_goal" defaultMessage="No goals" />
    <FormattedMessage id="columnNames.fg,no_goal" defaultMessage="1" />
    <FormattedMessage id="groupNames.othercs" defaultMessage="Correct Score Outside" />
    <FormattedMessage id="groupNames.short.othercs" defaultMessage="CS+ X/Y" />
    <FormattedMessage id="betTypes.othercs,othercs" defaultMessage="Correct Score Outside {handicap}" />
    <FormattedMessage id="groupNames.awdw" defaultMessage="1X2 IR" />
    <FormattedMessage id="groupNames.short.awdw" defaultMessage="1X2 IR" />
    <FormattedMessage id="betTypes.awdw,h" defaultMessage="{home} after IR offset" />
    <FormattedMessage id="columnNames.awdw,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.awdw,ir,h" defaultMessage="{home} after IR offset ({homeScore} - {awayScore})" />
    <FormattedMessage id="betTypes.awdw,d" defaultMessage="Draw after IR offset" />
    <FormattedMessage id="columnNames.awdw,d" defaultMessage="X" />
    <FormattedMessage id="betTypes.awdw,ir,d" defaultMessage="Draw after IR offset ({homeScore} - {awayScore})" />
    <FormattedMessage id="betTypes.awdw,a" defaultMessage="{away} after IR offset" />
    <FormattedMessage id="columnNames.awdw,a" defaultMessage="2" />
    <FormattedMessage id="betTypes.awdw,ir,a" defaultMessage="{away} after IR offset ({homeScore} - {awayScore})" />
    <FormattedMessage id="groupNames.quatro" defaultMessage="Quatro" />
    <FormattedMessage id="groupNames.short.quatro" defaultMessage="Quatro" />
    <FormattedMessage id="betTypes.quatro,h,o" defaultMessage="Quatro {home}, Over {handicap}" />
    <FormattedMessage id="columnNames.quatro,h,o" defaultMessage="1+O" />
    <FormattedMessage id="betTypes.quatro,h,u" defaultMessage="Quatro {home}, Under {handicap}" />
    <FormattedMessage id="columnNames.quatro,h,u" defaultMessage="1+U" />
    <FormattedMessage id="betTypes.quatro,d,o" defaultMessage="Quatro Draw, Over {handicap}" />
    <FormattedMessage id="columnNames.quatro,d,o" defaultMessage="X+O" />
    <FormattedMessage id="betTypes.quatro,d,u" defaultMessage="Quatro Draw, Under {handicap}" />
    <FormattedMessage id="columnNames.quatro,d,u" defaultMessage="X+U" />
    <FormattedMessage id="betTypes.quatro,a,o" defaultMessage="Quatro {away}, Over {handicap}" />
    <FormattedMessage id="columnNames.quatro,a,o" defaultMessage="2+O" />
    <FormattedMessage id="betTypes.quatro,a,u" defaultMessage="Quatro {away}, Under {handicap}" />
    <FormattedMessage id="columnNames.quatro,a,u" defaultMessage="2+U" />
    <FormattedMessage id="betTypes.quatro,hd,o" defaultMessage="Quatro {home} or DRAW, Over {handicap}" />
    <FormattedMessage id="columnNames.quatro,hd,o," defaultMessage="1X+O" />
    <FormattedMessage id="betTypes.quatro,hd,u" defaultMessage="Quatro {home} or DRAW, Under {handicap}" />
    <FormattedMessage id="columnNames.quatro,hd,u" defaultMessage="1X+U" />
    <FormattedMessage id="betTypes.quatro,ad,o" defaultMessage="Quatro {away} or DRAW, Over {handicap}" />
    <FormattedMessage id="columnNames.quatro,ad,o" defaultMessage="X2+O" />
    <FormattedMessage id="betTypes.quatro,ad,u" defaultMessage="Quatro {away} or DRAW, Under {handicap}" />
    <FormattedMessage id="columnNames.quatro,ad,u" defaultMessage="X2+U" />
    <FormattedMessage id="groupNames.tahou,tp,all,h" defaultMessage="Home Points" />
    <FormattedMessage id="groupNames.short.tahou,tp,all,h" defaultMessage="Home O/U" />
    <FormattedMessage id="betTypes.tahou,tp,all,h,over" defaultMessage="{home} to score Over {handicap}" />
    <FormattedMessage id="columnNames.tahou,tp,all,h,over" defaultMessage="1/O" />
    <FormattedMessage id="betTypes.tahou,tp,all,h,under" defaultMessage="{home} to score Under {handicap}" />
    <FormattedMessage id="columnNames.tahou,tp,all,h,under" defaultMessage="1/U" />
    <FormattedMessage id="groupNames.tahou,tp,all,a" defaultMessage="Away Points" />
    <FormattedMessage id="groupNames.short.tahou,tp,all,a" defaultMessage="Away O/U" />
    <FormattedMessage id="betTypes.tahou,tp,all,a,over" defaultMessage="{away} to score Over {handicap}" />
    <FormattedMessage id="columnNames.tahou,tp,all,a,over" defaultMessage="1/O" />
    <FormattedMessage id="betTypes.tahou,tp,all,a,under" defaultMessage="{away} to score Under {handicap}" />
    <FormattedMessage id="columnNames.tahou,tp,all,a,under" defaultMessage="1/U" />
    <FormattedMessage id="groupNames.tahou,tp,reg,h" defaultMessage="Home Points (Reg)" />
    <FormattedMessage id="groupNames.short.tahou,tp,reg,h" defaultMessage="Home O/U" />
    <FormattedMessage id="betTypes.tahou,tp,reg,h,over" defaultMessage="{home} to score Over {handicap} (Regular Time)" />
    <FormattedMessage id="columnNames.tahou,tp,reg,h,over" defaultMessage="1/O" />
    <FormattedMessage id="betTypes.tahou,tp,reg,h,under" defaultMessage="{home} to score Under {handicap} (Regular Time)" />
    <FormattedMessage id="columnNames.tahou,tp,reg,h,under" defaultMessage="1/U" />
    <FormattedMessage id="groupNames.tahou,tp,reg,a" defaultMessage="Away Points (Reg)" />
    <FormattedMessage id="groupNames.short.tahou,tp,reg,a" defaultMessage="Away O/U" />
    <FormattedMessage id="betTypes.tahou,tp,reg,a,over" defaultMessage="{away} to score Over {handicap} (Regular Time)" />
    <FormattedMessage id="columnNames.tahou,tp,reg,a,over" defaultMessage="1/O" />
    <FormattedMessage id="betTypes.tahou,tp,reg,a,under" defaultMessage="{away} to score Under {handicap} (Regular Time)" />
    <FormattedMessage id="columnNames.tahou,tp,reg,a,under" defaultMessage="1/U" />
    <FormattedMessage id="groupNames.timeWin,tp,all,ml" defaultMessage="Moneyline" />
    <FormattedMessage id="groupNames.short.timeWin,tp,all,ml" defaultMessage="1/2" />
    <FormattedMessage id="betTypes.timeWin,tp,all,ml,h" defaultMessage="{home}" />
    <FormattedMessage id="columnNames.timeWin,tp,all,ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tp,all,ml,a" defaultMessage="{away}" />
    <FormattedMessage id="columnNames.timeWin,tp,all,ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,tp,all,mlssop" defaultMessage="Moneyline (draw half push)" />
    <FormattedMessage id="groupNames.short.timeWin,tp,all,mlssop" defaultMessage="1/2 (dhp)" />
    <FormattedMessage id="betTypes.timeWin,tp,all,mlssop,h" defaultMessage="{home} (draw half push)" />
    <FormattedMessage id="columnNames.timeWin,tp,all,mlssop,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tp,all,mlssop,a" defaultMessage="{away} (draw half push)" />
    <FormattedMessage id="columnNames.timeWin,tp,all,mlssop,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,thalf,1,mlssop" defaultMessage="Moneyline (1st half) (draw half push)" />
    <FormattedMessage id="groupNames.short.timeWin,thalf,1,mlssop" defaultMessage="1/2 (1h) (dhp)" />
    <FormattedMessage id="betTypes.timeWin,thalf,1,mlssop,h" defaultMessage="{home} (1st half) (draw half push)" />
    <FormattedMessage id="columnNames.timeWin,thalf,1,mlssop,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,thalf,1,mlssop,a" defaultMessage="{away} (1st half) (draw half push)" />
    <FormattedMessage id="columnNames.timeWin,thalf,1,mlssop,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,thalf,2,mlssop" defaultMessage="Moneyline (2nd half) (draw half push)" />
    <FormattedMessage id="groupNames.short.timeWin,thalf,2,mlssop" defaultMessage="1/2 (2h) (dhp)" />
    <FormattedMessage id="betTypes.timeWin,thalf,2,mlssop,h" defaultMessage="{home} (2nd half) (draw half push)" />
    <FormattedMessage id="columnNames.timeWin,thalf,2,mlssop,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,thalf,2,mlssop,a" defaultMessage="{away} (2nd half) (draw half push)" />
    <FormattedMessage id="columnNames.timeWin,thalf,2,mlssop,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,tp,reg,ml" defaultMessage="Moneyline (reg. time)" />
    <FormattedMessage id="groupNames.short.timeWin,tp,reg,ml" defaultMessage="1/2 (reg)" />
    <FormattedMessage id="betTypes.timeWin,tp,reg,ml,h" defaultMessage="{home} (regular time)" />
    <FormattedMessage id="columnNames.timeWin,tp,reg,ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tp,reg,ml,a" defaultMessage="{away} (regular time)" />
    <FormattedMessage id="columnNames.timeWin,tp,reg,ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,tp,reg,wdw" defaultMessage="1 X 2 (reg. time)" />
    <FormattedMessage id="groupNames.short.timeWin,tp,reg,wdw" defaultMessage="1/X/2 (reg)" />
    <FormattedMessage id="betTypes.timeWin,tp,reg,wdw,h" defaultMessage="{home} (regular time)" />
    <FormattedMessage id="columnNames.timeWin,tp,reg,wdw,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tp,reg,wdw,d" defaultMessage="Draw (regular time)" />
    <FormattedMessage id="columnNames.timeWin,tp,reg,wdw,d" defaultMessage="X" />
    <FormattedMessage id="betTypes.timeWin,tp,reg,wdw,a" defaultMessage="{away} (regular time)" />
    <FormattedMessage id="columnNames.timeWin,tp,reg,wdw,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,tp,reg,dnb" defaultMessage="Moneyline (reg. time, draw no bet)" />
    <FormattedMessage id="groupNames.short.timeWin,tp,reg,dnb" defaultMessage="1/2 (reg, dnb)" />
    <FormattedMessage id="betTypes.timeWin,tp,reg,dnb,h" defaultMessage="{home} (regular time, draw no bet)" />
    <FormattedMessage id="columnNames.timeWin,tp,reg,dnb,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tp,reg,dnb,a" defaultMessage="{away} (regular time, draw no bet)" />
    <FormattedMessage id="columnNames.timeWin,tp,reg,dnb,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,tp,all,wdw" defaultMessage="1 X 2" />
    <FormattedMessage id="groupNames.short.timeWin,tp,all,wdw" defaultMessage="1/X/2" />
    <FormattedMessage id="betTypes.timeWin,tp,all,wdw,h" defaultMessage="{home}" />
    <FormattedMessage id="columnNames.timeWin,tp,all,wdw,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tp,all,wdw,d" defaultMessage="Draw" />
    <FormattedMessage id="columnNames.timeWin,tp,all,wdw,d" defaultMessage="X" />
    <FormattedMessage id="betTypes.timeWin,tp,all,wdw,a" defaultMessage="{away}" />
    <FormattedMessage id="columnNames.timeWin,tp,all,wdw,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,tp,all,dnb" defaultMessage="1 X 2 (draw no bet)" />
    <FormattedMessage id="groupNames.short.timeWin,tp,all,dnb" defaultMessage="1 X 2 (dnb)" />
    <FormattedMessage id="betTypes.timeWin,tp,all,dnb,h" defaultMessage="{home} (draw no bet)" />
    <FormattedMessage id="columnNames.timeWin,tp,all,dnb,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tp,all,dnb,d" defaultMessage="Draw (draw no bet)" />
    <FormattedMessage id="columnNames.timeWin,tp,all,dnb,d" defaultMessage="X" />
    <FormattedMessage id="betTypes.timeWin,tp,all,dnb,a" defaultMessage="{away} (draw no bet)" />
    <FormattedMessage id="columnNames.timeWin,tp,all,dnb,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,thalf,1,ml" defaultMessage="Moneyline (1st half)" />
    <FormattedMessage id="groupNames.short.timeWin,thalf,1,ml" defaultMessage="1/2 (1H)" />
    <FormattedMessage id="betTypes.timeWin,thalf,1,ml,h" defaultMessage="{home} (1st half)" />
    <FormattedMessage id="columnNames.timeWin,thalf,1,ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,thalf,1,ml,a" defaultMessage="{away} (1st half)" />
    <FormattedMessage id="columnNames.timeWin,thalf,1,ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,thalf,2,ml" defaultMessage="Moneyline (2nd half)" />
    <FormattedMessage id="groupNames.short.timeWin,thalf,2,ml" defaultMessage="1/2 (2H)" />
    <FormattedMessage id="betTypes.timeWin,thalf,2,ml,h" defaultMessage="{home} (2nd half)" />
    <FormattedMessage id="columnNames.timeWin,thalf,2,ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,thalf,2,ml,a" defaultMessage="{away} (2nd half)" />
    <FormattedMessage id="columnNames.timeWin,thalf,2,ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,thalf,1,wdw" defaultMessage="1 X 2 (1st half)" />
    <FormattedMessage id="groupNames.short.timeWin,thalf,1,wdw" defaultMessage="1/X/2 (1H)" />
    <FormattedMessage id="betTypes.timeWin,thalf,1,wdw,h" defaultMessage="{home} (1st half)" />
    <FormattedMessage id="columnNames.timeWin,thalf,1,wdw,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,thalf,1,wdw,d" defaultMessage="Draw (1st half)" />
    <FormattedMessage id="columnNames.timeWin,thalf,1,wdw,d" defaultMessage="X" />
    <FormattedMessage id="betTypes.timeWin,thalf,1,wdw,a" defaultMessage="{away} (1st half)" />
    <FormattedMessage id="columnNames.timeWin,thalf,1,wdw,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,thalf,2,wdw" defaultMessage="1 X 2 (2nd half)" />
    <FormattedMessage id="groupNames.short.timeWin,thalf,2,wdw" defaultMessage="1/X/2 (2H)" />
    <FormattedMessage id="betTypes.timeWin,thalf,2,wdw,h" defaultMessage="{away} (2nd half)" />
    <FormattedMessage id="columnNames.timeWin,thalf,2,wdw,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,thalf,2,wdw,d" defaultMessage="Draw (2nd half)" />
    <FormattedMessage id="columnNames.timeWin,thalf,2,wdw,d" defaultMessage="X" />
    <FormattedMessage id="betTypes.timeWin,thalf,2,wdw,a" defaultMessage="{home} (2nd half)" />
    <FormattedMessage id="columnNames.timeWin,thalf,2,wdw,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeAhou,tp,all" defaultMessage="Asian O/U (inc. overtime)" />
    <FormattedMessage id="groupNames.short.timeAhou,tp,all" defaultMessage="Asian O/U (+ot)" />
    <FormattedMessage id="betTypes.timeAhou,tp,all,over" defaultMessage="Over {handicap} (Asian, including overtime)" />
    <FormattedMessage id="columnNames.timeAhou,tp,all,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.timeAhou,tp,all,under" defaultMessage="Under {handicap} (Asian, including overtime)" />
    <FormattedMessage id="columnNames.timeAhou,tp,all,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,tp,reg" defaultMessage="Asian O/U (reg. time)" />
    <FormattedMessage id="groupNames.short.timeAhou,tp,reg" defaultMessage="Asian O/U (reg)" />
    <FormattedMessage id="betTypes.timeAhou,tp,reg,over" defaultMessage="Over {handicap} (Asian, regular time)" />
    <FormattedMessage id="columnNames.timeAhou,tp,reg,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.timeAhou,tp,reg,under" defaultMessage="Under {handicap} (Asian, regular time)" />
    <FormattedMessage id="columnNames.timeAhou,tp,reg,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,thalf,1" defaultMessage="Asian O/U (1st half)" />
    <FormattedMessage id="groupNames.short.timeAhou,thalf,1" defaultMessage="Asian O/U (1H)" />
    <FormattedMessage id="betTypestimeAhou,thalf,1,over" defaultMessage="Over {handicap} (Asian, 1st half)" />
    <FormattedMessage id="columnNames.timeAhou,thalf,1,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.timeAhou,thalf,1,under" defaultMessage="Under {handicap} (Asian, 1st half)" />
    <FormattedMessage id="columnNames.timeAhou,thalf,1,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,thalf,1,h" defaultMessage="Home Score (1st half)" />
    <FormattedMessage id="groupNames.short.timeAhou,thalf,1,h" defaultMessage="Home O/U (1H)" />
    <FormattedMessage id="betTypes.tahou,thalf,1,h,over" defaultMessage="{home} to score Over {handicap} (1st Half)" />
    <FormattedMessage id="columnNames.timeAhou,thalf,1,h,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tahou,thalf,1,h,under" defaultMessage="{home} to score Under {handicap} (1st Half)" />
    <FormattedMessage id="columnNames.timeAhou,thalf,1,h,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,thalf,1,a" defaultMessage="Away Score (1st half)" />
    <FormattedMessage id="groupNames.short.timeAhou,thalf,1,a" defaultMessage="Away O/U (1H)" />
    <FormattedMessage id="betTypes.tahou,thalf,1,a,over" defaultMessage="{away} to score Over {handicap} (1st Half)" />
    <FormattedMessage id="columnNames.timeAhou,thalf,1,a,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tahou,thalf,1,a,under" defaultMessage="{away} to score Under {handicap} (1st Half)" />
    <FormattedMessage id="columnNames.timeAhou,thalf,1,a,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,thalf,2" defaultMessage="Asian O/U (2nd half)" />
    <FormattedMessage id="groupNames.short.timeAhou,thalf,2" defaultMessage="Asian O/U (2H)" />
    <FormattedMessage id="betTypes.timeAhou,thalf,2,over" defaultMessage="Over {handicap} (Asian, 2nd half)" />
    <FormattedMessage id="columnNames.timeAhou,thalf,2,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.timeAhou,thalf,2,under" defaultMessage="Under {handicap} (Asian, 2nd half)" />
    <FormattedMessage id="columnNames.timeAhou,thalf,2,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,thalf,2,h" defaultMessage="Home Score (2nd half)" />
    <FormattedMessage id="groupNames.short.timeAhou,thalf,2,h" defaultMessage="Home O/U (1H)" />
    <FormattedMessage id="betTypes.tahou,thalf,2,h,over" defaultMessage="{home} to score Over {handicap} (2nd Half)" />
    <FormattedMessage id="columnNames.timeAhou,thalf,2,h,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tahou,thalf,2,h,under" defaultMessage="{home} to score Under {handicap} (2nd Half)" />
    <FormattedMessage id="columnNames.timeAhou,thalf,2,h,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,thalf,2,a" defaultMessage="Away Score (2nd half)" />
    <FormattedMessage id="groupNames.short.timeAhou,thalf,2,a" defaultMessage="Away O/U (1H)" />
    <FormattedMessage id="betTypes.tahou,thalf,2,a,over" defaultMessage="{away} to score Over {handicap} (2nd Half)" />
    <FormattedMessage id="columnNames.timeAhou,thalf,2,a,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tahou,thalf,2,a,under" defaultMessage="{away} to score Under {handicap} (2nd Half)" />
    <FormattedMessage id="columnNames.timeAhou,thalf,2,a,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,tperiod,1" defaultMessage="Asian O/U (1st period)" />
    <FormattedMessage id="groupNames.short.timeAhou,tperiod,1" defaultMessage="Asian O/U (1P)" />
    <FormattedMessage id="betTypes.timeAhou,tperiod,1,over" defaultMessage="Over {handicap} (Asian, 1st period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,1,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.timeAhou,tperiod,1,under" defaultMessage="Under {handicap} (Asian, 1st period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,1,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,tperiod,2" defaultMessage="Asian O/U (2nd period)" />
    <FormattedMessage id="groupNames.short.timeAhou,tperiod,2" defaultMessage="Asian O/U (2P)" />
    <FormattedMessage id="betTypes.timeAhou,tperiod,2,over" defaultMessage="Over {handicap} (Asian, 2nd period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,2,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.timeAhou,tperiod,2,under" defaultMessage="Under {handicap} (Asian, 2nd period)" />
    <FormattedMessage id="columnNamestimeAhou,tperiod,2,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,tperiod,3" defaultMessage="Asian O/U (3rd period)" />
    <FormattedMessage id="groupNames.short.timeAhou,tperiod,3" defaultMessage="Asian O/U (3P)" />
    <FormattedMessage id="betTypes.timeAhou,tperiod,3,over" defaultMessage="Over {handicap} (Asian, 3rd period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,3,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.timeAhou,tperiod,3,under" defaultMessage="Under {handicap} (Asian, 3rd period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,3,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAh,tp,all" defaultMessage="Asian Hcp." />
    <FormattedMessage id="groupNames.short.timeAh,tp,all" defaultMessage="Asian Hcp." />
    <FormattedMessage id="betTypes.timeAh,tp,all,h" defaultMessage="{home} {handicap}" />
    <FormattedMessage id="columnNames.timeAh,tp,all,h" defaultMessage="A/1" />
    <FormattedMessage id="betTypes.timeAh,tp,all,a" defaultMessage="{away} {handicap}" />
    <FormattedMessage id="columnNames.timeAh,tp,all,a" defaultMessage="A/2" />
    <FormattedMessage id="groupNames.timeAh,tp,reg" defaultMessage="Asian Hcp. (reg. time)" />
    <FormattedMessage id="groupNames.short.timeAh,tp,reg" defaultMessage="Asian (reg)" />
    <FormattedMessage id="betTypes.timeAh,tp,reg,h" defaultMessage="{home} {handicap} (Asian, regular time)" />
    <FormattedMessage id="columnNames.timeAh,tp,reg,h" defaultMessage="A/1" />
    <FormattedMessage id="betTypes.timeAh,tp,reg,a" defaultMessage="{away} {handicap} (Asian, regular time)" />
    <FormattedMessage id="columnNames.timeAh,tp,reg,a" defaultMessage="A/2" />
    <FormattedMessage id="groupNames.timeAh,thalf,1" defaultMessage="Asian Hcp. (1st half)" />
    <FormattedMessage id="groupNames.short.timeAh,thalf,1" defaultMessage="Asian (1H)" />
    <FormattedMessage id="betTypes.timeAh,thalf,1,h" defaultMessage="{home} {handicap} (Asian, 1st half)" />
    <FormattedMessage id="columnNames.timeAh,thalf,1,h" defaultMessage="A/1" />
    <FormattedMessage id="betTypes.timeAh,thalf,1,a" defaultMessage="{away} {handicap} (Asian, 1st half)" />
    <FormattedMessage id="columnNames.timeAh,thalf,1,a" defaultMessage="A/2" />
    <FormattedMessage id="groupNames.timeAh,thalf,2" defaultMessage="Asian Hcp. (2nd half)" />
    <FormattedMessage id="groupNames.short.timeAh,thalf,2" defaultMessage="Asian (2H)" />
    <FormattedMessage id="betTypes.timeAh,thalf,2,h" defaultMessage="{home} {handicap} (Asian, 2nd half)" />
    <FormattedMessage id="columnNames.timeAh,thalf,2,h" defaultMessage="A/1" />
    <FormattedMessage id="betTypes.timeAh,thalf,2,a" defaultMessage="{away} {handicap} (Asian, 2nd half)" />
    <FormattedMessage id="columnNames.timeAh,thalf,2,a" defaultMessage="A/2" />
    <FormattedMessage id="groupNames.timeAh,tperiod,1" defaultMessage="Asian Hcp. (1st period)" />
    <FormattedMessage id="groupNames.short.timeAh,tperiod,1" defaultMessage="Asian (1P)" />
    <FormattedMessage id="betTypes.timeAh,tperiod,1,h" defaultMessage="{home} {handicap} (Asian, 1st period)" />
    <FormattedMessage id="columnNames.timeAh,tperiod,1,h" defaultMessage="A/1" />
    <FormattedMessage id="betTypes.timeAh,tperiod,1,a" defaultMessage="{away} {handicap} (Asian, 1st period)" />
    <FormattedMessage id="columnNames.timeAh,tperiod,1,a" defaultMessage="A/2" />
    <FormattedMessage id="groupNames.timeAh,tperiod,2" defaultMessage="Asian Hcp. (2nd period)" />
    <FormattedMessage id="groupNames.short.timeAh,tperiod,2" defaultMessage="Asian (2P)" />
    <FormattedMessage id="betTypes.timeAh,tperiod,2,h" defaultMessage="{home} {handicap} (Asian, 2nd period)" />
    <FormattedMessage id="columnNames.timeAh,tperiod,2,h" defaultMessage="A/1" />
    <FormattedMessage id="betTypes.timeAh,tperiod,2,a" defaultMessage="{away} {handicap} (Asian, 2nd period)" />
    <FormattedMessage id="columnNames.timeAh,tperiod,2,a" defaultMessage="A/2" />
    <FormattedMessage id="groupNames.timeAh,tperiod,3" defaultMessage="Asian Hcp. (3rd period)" />
    <FormattedMessage id="groupNames.short.timeAh,tperiod,3" defaultMessage="Asian (3P)" />
    <FormattedMessage id="betTypes.timeAh,tperiod,3,h" defaultMessage="{home} {handicap} (Asian, 3rd period)" />
    <FormattedMessage id="columnNames.timeAh,tperiod,3,h" defaultMessage="A/1" />
    <FormattedMessage id="betTypes.timeAh,tperiod,3,a" defaultMessage="{away} {handicap} (Asian, 3rd period)" />
    <FormattedMessage id="columnNames.timeAh,tperiod,3,a" defaultMessage="A/2" />
    <FormattedMessage id="groupNames.timeWin,tperiod,1,wdw" defaultMessage="1 X 2 (1st period)" />
    <FormattedMessage id="groupNames.short.timeWin,tperiod,1,wdw" defaultMessage="1X2 (1P)" />
    <FormattedMessage id="betTypes.timeWin,tperiod,1,wdw,h" defaultMessage="{home} (1st period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,1,wdw,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tperiod,1,wdw,d" defaultMessage="Draw (1st period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,1,wdw,d" defaultMessage="X" />
    <FormattedMessage id="betTypes.timeWin,tperiod,1,wdw,a" defaultMessage="{away} (1st period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,1,wdw,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,tperiod,2,wdw" defaultMessage="1 X 2 (2nd period)" />
    <FormattedMessage id="groupNames.short.timeWin,tperiod,2,wdw" defaultMessage="1X2 (2P)" />
    <FormattedMessage id="betTypes.timeWin,tperiod,2,wdw,h" defaultMessage="{home} (2nd period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,2,wdw,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tperiod,2,wdw,d" defaultMessage="Draw (2nd period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,2,wdw,d" defaultMessage="X" />
    <FormattedMessage id="betTypes.timeWin,tperiod,2,wdw,a" defaultMessage="{away} (2nd period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,2,wdw,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,tperiod,3,wdw" defaultMessage="1 X 2 (3rd period)" />
    <FormattedMessage id="groupNames.short.timeWin,tperiod,3,wdw" defaultMessage="1X2 (3P)" />
    <FormattedMessage id="betTypes.timeWin,tperiod,3,wdw,h" defaultMessage="{home} (3rd period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,3,wdw,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tperiod,3,wdw,d" defaultMessage="Draw (3rd period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,3,wdw,d" defaultMessage="X" />
    <FormattedMessage id="betTypes.timeWin,tperiod,3,wdw,a" defaultMessage="{away} (3rd period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,3,wdw,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,tperiod,1,ml" defaultMessage="Moneyline (1st period)" />
    <FormattedMessage id="groupNames.short.timeWin,tperiod,1,ml" defaultMessage="Moneyline (1P)" />
    <FormattedMessage id="betTypes.timeWin,tperiod,1,ml,h" defaultMessage="{home} (1st period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,1,ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tperiod,1,ml,a" defaultMessage="{away} (1st period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,1,ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,tperiod,2,ml" defaultMessage="Moneyline (2nd period)" />
    <FormattedMessage id="groupNames.short.timeWin,tperiod,2,ml" defaultMessage="Moneyline (2P)" />
    <FormattedMessage id="betTypes.timeWin,tperiod,2,ml,h" defaultMessage="{home} (2nd period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,2,ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tperiod,2,ml,a" defaultMessage="{away} (2nd period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,2,ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,tperiod,3,ml" defaultMessage="Moneyline (3rd period)" />
    <FormattedMessage id="groupNames.short.timeWin,tperiod,3,ml" defaultMessage="Moneyline (3P)" />
    <FormattedMessage id="betTypes.timeWin,tperiod,3,ml,h" defaultMessage="{home} (3rd period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,3,ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tperiod,3,ml,a" defaultMessage="{away} (3rd period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,3,ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,tperiod,4,ml" defaultMessage="Moneyline (4th period)" />
    <FormattedMessage id="groupNames.short.timeWin,tperiod,4,ml" defaultMessage="Moneyline (4P)" />
    <FormattedMessage id="betTypes.timeWin,tperiod,4,ml,h" defaultMessage="{home} (4th period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,4,ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tperiod,4,ml,a" defaultMessage="{away} (4th period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,4,ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeWin,tperiod,5,ml" defaultMessage="Moneyline (5th period)" />
    <FormattedMessage id="groupNames.short.timeWin,tperiod,5,ml" defaultMessage="Moneyline (5P)" />
    <FormattedMessage id="betTypes.timeWin,tperiod,5,ml,h" defaultMessage="{home} (5th period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,5,ml,h" defaultMessage="1" />
    <FormattedMessage id="betTypes.timeWin,tperiod,5,ml,a" defaultMessage="{away} (5th period)" />
    <FormattedMessage id="columnNames.timeWin,tperiod,5,ml,a" defaultMessage="2" />
    <FormattedMessage id="groupNames.timeOe,tp,all,oe" defaultMessage="Odd/Even" />
    <FormattedMessage id="groupNames.short.timeOe,tp,all,oe" defaultMessage="Odd/Even" />
    <FormattedMessage id="betTypes.timeOe,tp,all,oe,odd" defaultMessage="Odd Score" />
    <FormattedMessage id="columnNames.timeOe,tp,all,oe,odd" defaultMessage="Odd" />
    <FormattedMessage id="betTypes.timeOe,tp,all,oe,even" defaultMessage="Even Score" />
    <FormattedMessage id="columnNames.timeOe,tp,all,oe,even" defaultMessage="Even" />
    <FormattedMessage id="groupNames.timeAhou,tperiod,1,h" defaultMessage="Home Score (1st period)" />
    <FormattedMessage id="groupNames.short.timeAhou,tperiod,1,h" defaultMessage="Home O/U (1P)" />
    <FormattedMessage id="betTypes.tahou,tperiod,1,h,over" defaultMessage="{home} to score Over {handicap} (1st Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,1,h,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tahou,tperiod,1,h,under" defaultMessage="{home} to score Under {handicap} (1st Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,1,h,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,tperiod,1,a" defaultMessage="Away Score (1st period)" />
    <FormattedMessage id="groupNames.short.timeAhou,tperiod,1,a" defaultMessage="Away O/U (1P)" />
    <FormattedMessage id="betTypes.tahou,tperiod,1,a,over" defaultMessage="{away} to score Over {handicap} (1st Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,1,a,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tahou,tperiod,1,a,under" defaultMessage="{away} to score Under {handicap} (1st Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,1,a,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,tperiod,2,h" defaultMessage="Home Score (2nd period)" />
    <FormattedMessage id="groupNames.short.timeAhou,tperiod,2,h" defaultMessage="Home O/U (2P)" />
    <FormattedMessage id="betTypes.tahou,tperiod,2,h,over" defaultMessage="{home} to score Over {handicap} (2nd Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,2,h,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tahou,tperiod,2,h,under" defaultMessage="{home} to score Under {handicap} (2nd Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,2,h,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,tperiod,2,a" defaultMessage="Away Score (2nd period)" />
    <FormattedMessage id="groupNames.short.timeAhou,tperiod,2,a" defaultMessage="Away O/U (2P)" />
    <FormattedMessage id="betTypes.tahou,tperiod,2,a,over" defaultMessage="{away} to score Over {handicap} (2nd Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,2,a,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tahou,tperiod,2,a,under" defaultMessage="{away} to score Under {handicap} (2nd Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,2,a,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,tperiod,3,h" defaultMessage="Home Score (3rd period)" />
    <FormattedMessage id="groupNames.short.timeAhou,tperiod,3,h" defaultMessage="Home O/U (3P)" />
    <FormattedMessage id="betTypes.tahou,tperiod,3,h,over" defaultMessage="{home} to score Over {handicap} (3rd Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,3,h,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tahou,tperiod,3,h,under" defaultMessage="{home} to score Under {handicap} (3rd Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,3,h,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,tperiod,3,a" defaultMessage="Away Score (3rd period)" />
    <FormattedMessage id="groupNames.short.timeAhou,tperiod,3,a" defaultMessage="Away O/U (3P)" />
    <FormattedMessage id="betTypes.tahou,tperiod,3,a,over" defaultMessage="{away} to score Over {handicap} (3rd Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,3,a,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tahou,tperiod,3,a,under" defaultMessage="{away} to score Under {handicap} (3rd Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,3,a,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,tperiod,4,h" defaultMessage="Home Score (4th period)" />
    <FormattedMessage id="groupNames.short.timeAhou,tperiod,4,h" defaultMessage="Home O/U (4P)" />
    <FormattedMessage id="betTypes.tahou,tperiod,4,h,over" defaultMessage="{home} to score Over {handicap} (4th Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,4,h,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tahou,tperiod,4,h,under" defaultMessage="{home} to score Under {handicap} (4th Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,4,h,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,tperiod,4,a" defaultMessage="Away Score (4th period)" />
    <FormattedMessage id="groupNames.short.timeAhou,tperiod,4,a" defaultMessage="Away O/U (4P)" />
    <FormattedMessage id="betTypes.tahou,tperiod,4,a,over" defaultMessage="{away} to score Over {handicap} (4th Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,4,a,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tahou,tperiod,4,a,under" defaultMessage="{away} to score Under {handicap} (4th Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,4,a,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,tperiod,5,h" defaultMessage="Home Score (5th period)" />
    <FormattedMessage id="groupNames.short.timeAhou,tperiod,5,h" defaultMessage="Home O/U (5P)" />
    <FormattedMessage id="betTypes.tahou,tperiod,5,h,over" defaultMessage="{home} to score Over {handicap} (5th Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,5,h,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tahou,tperiod,5,h,under" defaultMessage="{home} to score Under {handicap} (5th Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,5,h,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeAhou,tperiod,5,a" defaultMessage="Away Score (5th period)" />
    <FormattedMessage id="groupNames.short.timeAhou,tperiod,5,a" defaultMessage="Away O/U (5P)" />
    <FormattedMessage id="betTypes.tahou,tperiod,5,a,over" defaultMessage="{away} to score Over {handicap} (5th Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,5,a,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tahou,tperiod,5,a,under" defaultMessage="{away} to score Under {handicap} (5th Period)" />
    <FormattedMessage id="columnNames.timeAhou,tperiod,5,a,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.timeOe,tp,reg,oe" defaultMessage="Odd/Even" />
    <FormattedMessage id="groupNames.short.timeOe,tp,reg,oe" defaultMessage="Odd/Even" />
    <FormattedMessage id="betTypes.timeOe,tp,reg,oe,odd" defaultMessage="Odd Score (Regular Time)" />
    <FormattedMessage id="columnNames.timeOe,tp,reg,oe,odd" defaultMessage="Odd" />
    <FormattedMessage id="betTypes.timeOe,tp,reg,oe,even" defaultMessage="Even Score (Regular Time)" />
    <FormattedMessage id="columnNames.timeOe,tp,reg,oe,even" defaultMessage="Even" />
    <FormattedMessage id="groupNames.oe" defaultMessage="Odd/Even" />
    <FormattedMessage id="groupNames.short.oe" defaultMessage="Odd/Even" />
    <FormattedMessage id="betTypes.oe,odd" defaultMessage="Odd Goals" />
    <FormattedMessage id="columnNames.oe,odd" defaultMessage="Odd" />
    <FormattedMessage id="betTypes.oe,even" defaultMessage="Even Goals" />
    <FormattedMessage id="columnNames.oe,even" defaultMessage="Even" />
    <FormattedMessage id="groupNames.oe,h" defaultMessage="Odd/Even Home" />
    <FormattedMessage id="groupNames.short.oe,h" defaultMessage="1 O/E" />
    <FormattedMessage id="betTypes.oe,odd,h" defaultMessage="Odd Goals Home" />
    <FormattedMessage id="columnNames.oe,odd,h" defaultMessage="Odd" />
    <FormattedMessage id="betTypes.oe,even,h" defaultMessage="Even Goals Home" />
    <FormattedMessage id="columnNames.oe,even,h" defaultMessage="Even" />
    <FormattedMessage id="groupNames.oe,a" defaultMessage="Odd/Even Away" />
    <FormattedMessage id="groupNames.short.oe,a" defaultMessage="2 O/E" />
    <FormattedMessage id="betTypes.oe,odd,a" defaultMessage="Odd Goals Away" />
    <FormattedMessage id="columnNames.oe,odd,a" defaultMessage="Odd" />
    <FormattedMessage id="betTypes.oe,even,a" defaultMessage="Even Goals Away" />
    <FormattedMessage id="columnNames.oe,even,a" defaultMessage="Even" />
    <FormattedMessage id="groupNames.score,both" defaultMessage="Both To Score" />
    <FormattedMessage id="groupNames.short.score,both" defaultMessage="Both Score" />
    <FormattedMessage id="betTypes.score,both" defaultMessage="Both Teams Score" />
    <FormattedMessage id="columnNames.score,both" defaultMessage="Yes" />
    <FormattedMessage id="betTypes.score,both,no" defaultMessage="Both Teams Will Not Score" />
    <FormattedMessage id="columnNames.score,both,no" defaultMessage="No" />
    <FormattedMessage id="groupNames.score,one" defaultMessage="One To Score" />
    <FormattedMessage id="groupNames.short.score,one" defaultMessage="One Score" />
    <FormattedMessage id="betTypes.score,one" defaultMessage="Only one team to score" />
    <FormattedMessage id="columnNames.score,one" defaultMessage="Yes" />
    <FormattedMessage id="betTypes.score,one,no" defaultMessage="Only one team to not score" />
    <FormattedMessage id="columnNames.score,one,no" defaultMessage="No" />
    <FormattedMessage id="groupNames.score,neither" defaultMessage="Neither To Score" />
    <FormattedMessage id="groupNames.short.score,neither" defaultMessage="One Score" />
    <FormattedMessage id="betTypes.score,neither" defaultMessage="Neither team to score" />
    <FormattedMessage id="columnNames.score,neither" defaultMessage="Yes" />
    <FormattedMessage id="betTypes.score,neither,no" defaultMessage="Neither team to not score" />
    <FormattedMessage id="columnNames.score,neither,no" defaultMessage="No" />
    <FormattedMessage id="groupNames.score,either" defaultMessage="Either To Score" />
    <FormattedMessage id="groupNames.short.score,either" defaultMessage="One Score" />
    <FormattedMessage id="betTypes.score,either" defaultMessage="Either team to score" />
    <FormattedMessage id="columnNames.score,either" defaultMessage="Yes" />
    <FormattedMessage id="betTypes.score,either,no" defaultMessage="Either team to not score" />
    <FormattedMessage id="columnNames.score,either,no" defaultMessage="No" />
    <FormattedMessage id="groupNames.score,a" defaultMessage="Away To Score" />
    <FormattedMessage id="groupNames.short.score,a" defaultMessage="Away Score" />
    <FormattedMessage id="betTypes.score,a" defaultMessage="{away} Will Score" />
    <FormattedMessage id="columnNames.score,a" defaultMessage="Yes" />
    <FormattedMessage id="betTypes.score,a,no" defaultMessage="{away} Will Not Score" />
    <FormattedMessage id="columnNames.score,a,no" defaultMessage="No" />
    <FormattedMessage id="groupNames.score,h" defaultMessage="Home To Score" />
    <FormattedMessage id="groupNames.short.score,h" defaultMessage="Home Score" />
    <FormattedMessage id="betTypes.score,h" defaultMessage="{home} Will Score" />
    <FormattedMessage id="columnNames.score,h" defaultMessage="Yes" />
    <FormattedMessage id="betTypes.score,h,no" defaultMessage="{home} Will Not Score" />
    <FormattedMessage id="columnNames.score,h,no" defaultMessage="No" />
    <FormattedMessage id="groupNames.clean,a" defaultMessage="Away Clean Sheet" />
    <FormattedMessage id="groupNames.short.clean,a" defaultMessage="Away Clean" />
    <FormattedMessage id="betTypes.clean,a" defaultMessage="{away} Will Keep Clean Sheet" />
    <FormattedMessage id="columnNames.clean,a" defaultMessage="Yes" />
    <FormattedMessage id="betTypes.clean,a,no" defaultMessage="{away} Will Not Keep Clean Sheet" />
    <FormattedMessage id="columnNames.clean,a,no" defaultMessage="No" />
    <FormattedMessage id="groupNames.clean,h" defaultMessage="Home Clean Sheet" />
    <FormattedMessage id="groupNames.short.clean,h" defaultMessage="Home Clean" />
    <FormattedMessage id="betTypes.clean,h" defaultMessage="{home} Will Keep Clean Sheet" />
    <FormattedMessage id="columnNames.clean,h" defaultMessage="Yes" />
    <FormattedMessage id="betTypes.clean,h,no" defaultMessage="{home} Will Not Keep Clean Sheet" />
    <FormattedMessage id="columnNames.clean,h,no" defaultMessage="No" />
    <FormattedMessage id="groupNames.clean" defaultMessage="Clean Sheet" />
    <FormattedMessage id="groupNames.short.clean" defaultMessage="Clean" />
    <FormattedMessage id="betTypes.clean,both" defaultMessage="Both Will Keep Clean Sheet" />
    <FormattedMessage id="columnNames.clean,both" defaultMessage="Both" />
    <FormattedMessage id="betTypes.clean,either" defaultMessage="Either Will Keep Clean Sheet" />
    <FormattedMessage id="columnNames.clean,either" defaultMessage="Either" />
    <FormattedMessage id="betTypes.clean,one" defaultMessage="One Will Keep Clean Sheet" />
    <FormattedMessage id="columnNames.clean,one" defaultMessage="One" />
    <FormattedMessage id="betTypes.clean,neither" defaultMessage="Neither Keep Clean Sheet" />
    <FormattedMessage id="columnNames.clean,neither" defaultMessage="Neither" />
    <FormattedMessage id="groupNames.winToNil,a" defaultMessage="Away Win to Nil" />
    <FormattedMessage id="groupNames.short.winToNil,a" defaultMessage="Away to Nil" />
    <FormattedMessage id="betTypes.winToNil,a" defaultMessage="{away} Wins to 0" />
    <FormattedMessage id="columnNames.for,wintonil,a" defaultMessage="Yes" />
    <FormattedMessage id="betTypeswinToNil,a,no" defaultMessage="{away} Not Win to 0" />
    <FormattedMessage id="columnNames.for,wintonil,a,no" defaultMessage="No" />
    <FormattedMessage id="groupNames.winToNil,h" defaultMessage="Home Win to Nil" />
    <FormattedMessage id="groupNames.short.winToNil,h" defaultMessage="Home to Nil" />
    <FormattedMessage id="betTypes.winToNil,h" defaultMessage="{home} Wins to 0" />
    <FormattedMessage id="columnNames.for,wintonil,h" defaultMessage="Yes" />
    <FormattedMessage id="betTypes.winToNil,h,no" defaultMessage="{home} Not Win to 0" />
    <FormattedMessage id="columnNames.for,wintonil,h,no" defaultMessage="No" />
    <FormattedMessage id="groupNames.cs" defaultMessage="Correct Score" />
    <FormattedMessage id="groupNames.short.cs" defaultMessage="Score" />
    <FormattedMessage id="betTypes.cs,cs" defaultMessage="Correct Score {handicap}" />
    <FormattedMessage id="groupNames.exactTotal" defaultMessage="Exact Total" />
    <FormattedMessage id="groupNames.short.exactTotal" defaultMessage="Exact" />
    <FormattedMessage id="betTypes.exactTotal" defaultMessage="Exact Total Goals {handicap}" />
    <FormattedMessage id="groupNames.tennisMatch,all" defaultMessage="Moneyline" />
    <FormattedMessage id="groupNames.short.tennisMatch,all" defaultMessage="1/2" />
    <FormattedMessage id="betTypes.tennisMatch,all,p1" defaultMessage="{home}" />
    <FormattedMessage id="columnNames.tennisMatch,all,p1" defaultMessage="1" />
    <FormattedMessage id="betTypes.tennisMatch,all,p2" defaultMessage="{away}" />
    <FormattedMessage id="columnNames.tennisMatch,all,p2" defaultMessage="2" />
    <FormattedMessage id="groupNames.tennisMatch,1" defaultMessage="Winner (1st set)" />
    <FormattedMessage id="groupNames.short.tennisMatch,1" defaultMessage="Win (1S)" />
    <FormattedMessage id="betTypes.tennisMatch,1,p1" defaultMessage="{home} (1st set)" />
    <FormattedMessage id="columnNames.tennisMatch,1,p1" defaultMessage="1" />
    <FormattedMessage id="betTypes.tennisMatch,1,p2" defaultMessage="{away} (1st set)" />
    <FormattedMessage id="columnNames.tennisMatch,1,p2" defaultMessage="2" />
    <FormattedMessage id="groupNames.tennisMatch,2" defaultMessage="Winner (2nd set)" />
    <FormattedMessage id="groupNames.short.tennisMatch,2" defaultMessage="Win (2S)" />
    <FormattedMessage id="betTypes.tennisMatch,2,p1" defaultMessage="{home} (2nd set)" />
    <FormattedMessage id="columnNames.tennisMatch,2,p1" defaultMessage="1" />
    <FormattedMessage id="betTypes.tennisMatch,2,p2" defaultMessage="{away} (2nd set)" />
    <FormattedMessage id="columnNames.tennisMatch,2,p2" defaultMessage="2" />
    <FormattedMessage id="groupNames.tennisMatch,3" defaultMessage="Winner (3rd set)" />
    <FormattedMessage id="groupNames.short.tennisMatch,3" defaultMessage="Win (3S)" />
    <FormattedMessage id="betTypes.tennisMatch,3,p1" defaultMessage="{home} (3rd set)" />
    <FormattedMessage id="columnNames.tennisMatch,3,p1" defaultMessage="1" />
    <FormattedMessage id="betTypes.tennisMatch,3,p2" defaultMessage="{away} (3rd set)" />
    <FormattedMessage id="columnNames.tennisMatch,3,p2" defaultMessage="2" />
    <FormattedMessage id="groupNames.tennisMatch,4" defaultMessage="Winner (4th set)" />
    <FormattedMessage id="groupNames.short.tennisMatch,4" defaultMessage="Win (4S)" />
    <FormattedMessage id="betTypes.tennisMatch,4,p1" defaultMessage="{home} (4th set)" />
    <FormattedMessage id="columnNames.tennisMatch,4,p1" defaultMessage="1" />
    <FormattedMessage id="betTypes.tennisMatch,4,p2" defaultMessage="{away} (4th set)" />
    <FormattedMessage id="columnNames.tennisMatch,4,p2" defaultMessage="2" />
    <FormattedMessage id="groupNames.tennisAh,all,set" defaultMessage="Set Handicap" />
    <FormattedMessage id="groupNames.short.tennisAh,all,set" defaultMessage="Set 1/2" />
    <FormattedMessage id="betTypes.tennisAh,all,set,p1" defaultMessage="{home} {handicap} Sets" />
    <FormattedMessage id="columnNames.tennisAh,all,set,p1" defaultMessage="A/P1" />
    <FormattedMessage id="betTypes.tennisAh,all,set,p2" defaultMessage="{away} {handicap} Sets" />
    <FormattedMessage id="columnNames.tennisAh,all,set,p2" defaultMessage="A/P2" />
    <FormattedMessage id="groupNames.tennisAh,all,game" defaultMessage="Game Hcp." />
    <FormattedMessage id="groupNames.short.tennisAh,all,game" defaultMessage="Game 1/2" />
    <FormattedMessage id="betTypes.tennisAh,all,game,p1" defaultMessage="{home} Games Handicap {handicap}" />
    <FormattedMessage id="columnNames.tennisAh,all,game,p1" defaultMessage="A/P1" />
    <FormattedMessage id="betTypes.tennisAh,all,game,p2" defaultMessage="{away} Games Handicap {handicap}" />
    <FormattedMessage id="columnNames.tennisAh,all,game,p2" defaultMessage="A/P2" />
    <FormattedMessage id="groupNames.tennisAh,1,game" defaultMessage="Game Hcp. (1st set)" />
    <FormattedMessage id="groupNames.short.tennisAh,1,game" defaultMessage="Game 1/2 (1S)" />
    <FormattedMessage id="betTypes.tennisAh,1,game,p1" defaultMessage="{home} Games Handicap {handicap} (1st set)" />
    <FormattedMessage id="columnNames.tennisAh,1,game,p1" defaultMessage="A/P1" />
    <FormattedMessage id="betTypes.tennisAh,1,game,p2" defaultMessage="{away} Games Handicap {handicap} (1st set)" />
    <FormattedMessage id="columnNames.tennisAh,1,game,p2" defaultMessage="A/P2" />
    <FormattedMessage id="groupNames.tennisAh,2,game" defaultMessage="Game Hcp. (2nd set)" />
    <FormattedMessage id="groupNames.short.tennisAh,2,game" defaultMessage="Game 1/2 (2S)" />
    <FormattedMessage id="betTypes.tennisAh,2,game,p1" defaultMessage="{home} Games Handicap {handicap} (2nd set)" />
    <FormattedMessage id="columnNames.tennisAh,2,game,p1" defaultMessage="A/P1" />
    <FormattedMessage id="betTypes.tennisAh,2,game,p2" defaultMessage="{away} Games Handicap {handicap} (2nd set)" />
    <FormattedMessage id="columnNames.tennisAh,2,game,p2" defaultMessage="A/P2" />
    <FormattedMessage id="groupNames.tennisAh,3,game" defaultMessage="Game Hcp. (3rd set)" />
    <FormattedMessage id="groupNames.short.tennisAh,3,game" defaultMessage="Game 1/2 (3S)" />
    <FormattedMessage id="betTypes.tennisAh,3,game,p1" defaultMessage="{home} Games Handicap {handicap} (3rd set)" />
    <FormattedMessage id="columnNames.tennisAh,3,game,p1" defaultMessage="A/P1" />
    <FormattedMessage id="betTypes.tennisAh,3,game,p2" defaultMessage="{away} Games Handicap {handicap} (3rd set)" />
    <FormattedMessage id="columnNames.tennisAh,3,game,p2" defaultMessage="A/P2" />
    <FormattedMessage id="groupNames.tennisAh,4,game" defaultMessage="Game Hcp. (4th set)" />
    <FormattedMessage id="groupNames.short.tennisAh,4,game" defaultMessage="Game 1/2 (4S)" />
    <FormattedMessage id="betTypes.tennisAh,4,game,p1" defaultMessage="{home} Games Handicap {handicap} (4th set)" />
    <FormattedMessage id="columnNames.tennisAh,4,game,p1" defaultMessage="A/P1" />
    <FormattedMessage id="betTypes.tennisAh,4,game,p2" defaultMessage="{away} Games Handicap {handicap} (4th set)" />
    <FormattedMessage id="columnNames.tennisAh,4,game,p2" defaultMessage="A/P2" />
    <FormattedMessage id="groupNames.tennisAhou,all,game" defaultMessage="Total Games" />
    <FormattedMessage id="groupNames.short.tennisAhou,all,game" defaultMessage="Games O/U" />
    <FormattedMessage id="betTypes.tennisAhou,all,game,over" defaultMessage="Total Games Over {handicap}" />
    <FormattedMessage id="columnNames.tennisAhou,all,game,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tennisAhou,all,game,under" defaultMessage="Total Games Under {handicap}" />
    <FormattedMessage id="columnNames.tennisAhou,all,game,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.tennisAhou,1,game" defaultMessage="Games O/U (1st set)" />
    <FormattedMessage id="groupNames.short.tennisAhou,1,game" defaultMessage="Games O/U (1S)" />
    <FormattedMessage id="betTypes.tennisAhou,1,game,over" defaultMessage="Games Over {handicap} (1st set)" />
    <FormattedMessage id="columnNames.tennisAhou,1,game,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tennisAhou,1,game,under" defaultMessage="Games Under {handicap} (1st set)" />
    <FormattedMessage id="columnNames.tennisAhou,1,game,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.tennisAhou,2,game" defaultMessage="Games O/U (2nd set)" />
    <FormattedMessage id="groupNames.short.tennisAhou,2,game" defaultMessage="Games O/U (2S)" />
    <FormattedMessage id="betTypes.tennisAhou,2,game,over" defaultMessage="Games Over {handicap} (2nd set)" />
    <FormattedMessage id="columnNames.tennisAhou,2,game,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tennisAhou,2,game,under" defaultMessage="Games Under {handicap} (2nd set)" />
    <FormattedMessage id="columnNames.tennisAhou,2,game,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.tennisAhou,3,game" defaultMessage="Games O/U (3rd set)" />
    <FormattedMessage id="groupNames.short.tennisAhou,3,game" defaultMessage="Games O/U (3S)" />
    <FormattedMessage id="betTypes.tennisAhou,3,game,over" defaultMessage="Games Over {handicap} (3rd set)" />
    <FormattedMessage id="columnNames.tennisAhou,3,game,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tennisAhou,3,game,under" defaultMessage="Games Under {handicap} (3rd set)" />
    <FormattedMessage id="columnNames.tennisAhou,3,game,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.tennisAhou,4,game" defaultMessage="Games O/U (4th set)" />
    <FormattedMessage id="groupNames.short.tennisAhou,4,game" defaultMessage="Games O/U (4S)" />
    <FormattedMessage id="betTypes.tennisAhou,4,game,over" defaultMessage="Games Over {handicap} (4th set)" />
    <FormattedMessage id="columnNames.tennisAhou,4,game,over" defaultMessage="O" />
    <FormattedMessage id="betTypes.tennisAhou,4,game,under" defaultMessage="Games Under {handicap} (4th set)" />
    <FormattedMessage id="columnNames.tennisAhou,4,game,under" defaultMessage="U" />
    <FormattedMessage id="groupNames.tennisOe,all,game" defaultMessage="Games Odd/Even" />
    <FormattedMessage id="groupNames.short.tennisOe,all,game" defaultMessage="Odd/Even" />
    <FormattedMessage id="betTypes.tennisOe,all,game,odd" defaultMessage="Odd Games" />
    <FormattedMessage id="columnNames.tennisOe,all,game,odd" defaultMessage="Odd" />
    <FormattedMessage id="betTypes.tennisOe,all,game,even" defaultMessage="Even Games" />
    <FormattedMessage id="columnNames.tennisOe,all,game,even" defaultMessage="Even" />
    <FormattedMessage id="groupNames.tennisOe,1,game" defaultMessage="Games Odd/Even (1st set)" />
    <FormattedMessage id="groupNames.short.tennisOe,1,game" defaultMessage="Odd/Even (1S)" />
    <FormattedMessage id="betTypes.tennisOe,1,game,odd" defaultMessage="Odd Games (1st set)" />
    <FormattedMessage id="columnNames.tennisOe,1,game,odd" defaultMessage="Odd" />
    <FormattedMessage id="betTypes.tennisOe,1,game,even" defaultMessage="Even Games (1st set)" />
    <FormattedMessage id="columnNames.tennisOe,1,game,even" defaultMessage="Even" />
    <FormattedMessage id="groupNames.tennisOe,2,game" defaultMessage="Games Odd/Even (2nd set)" />
    <FormattedMessage id="groupNames.short.tennisOe,2,game" defaultMessage="Odd/Even (2S)" />
    <FormattedMessage id="betTypes.tennisOe,2,game,odd" defaultMessage="Odd Games (2nd set)" />
    <FormattedMessage id="columnNames.tennisOe,2,game,odd" defaultMessage="Odd" />
    <FormattedMessage id="betTypes.tennisOe,2,game,even" defaultMessage="Even Games (2nd set)" />
    <FormattedMessage id="columnNames.tennisOe,2,game,even" defaultMessage="Even" />
    <FormattedMessage id="groupNames.tennisOe,3,game" defaultMessage="Games Odd/Even (3rd set)" />
    <FormattedMessage id="groupNames.short.tennisOe,3,game" defaultMessage="Odd/Even (3S)" />
    <FormattedMessage id="betTypes.tennisOe,3,game,odd" defaultMessage="Odd Games (3rd set)" />
    <FormattedMessage id="columnNames.tennisOe,3,game,odd" defaultMessage="Odd" />
    <FormattedMessage id="betTypes.tennisOe,3,game,even" defaultMessage="Even Games (3rd set)" />
    <FormattedMessage id="columnNames.tennisOe,3,game,even" defaultMessage="Even" />
    <FormattedMessage id="groupNames.tennisOe,4,game" defaultMessage="Games Odd/Even (4th set)" />
    <FormattedMessage id="groupNames.short.tennisOe,4,game" defaultMessage="Odd/Even (4S)" />
    <FormattedMessage id="betTypes.tennisOe,4,game,odd" defaultMessage="Odd Games (4th set)" />
    <FormattedMessage id="columnNames.tennisOe,4,game,odd" defaultMessage="Odd" />
    <FormattedMessage id="betTypes.tennisOe,4,game,even" defaultMessage="Even Games (4th set)" />
    <FormattedMessage id="columnNames.tennisOe,4,game,even" defaultMessage="Even" />
    <FormattedMessage id="groupNames.tennisCs,all,set" defaultMessage="Set Score" />
    <FormattedMessage id="groupNames.short.tennisCs,all,set" defaultMessage="Score" />
    <FormattedMessage id="betTypes.tennisCs,all,set" defaultMessage="Set Score {handicap}" />
    <FormattedMessage id="groupNames.tennisMatch,all,set,total" defaultMessage="Set Total" />
    <FormattedMessage id="groupNames.short.tennisMatch,all,set,total" defaultMessage="Sets" />
    <FormattedMessage id="betTypes.tennisMatch,all,set,total" defaultMessage="Set Total {handicap}" />
    <FormattedMessage id="sports.fb" defaultMessage="Football: Full-Time" />
    <FormattedMessage id="sports.fb.short" defaultMessage="Football" />
    <FormattedMessage id="sports.fb_htft" defaultMessage="Football: Half/Full" />
    <FormattedMessage id="sports.fb_htft.short" defaultMessage="Football [Hl/Fl]" />
    <FormattedMessage id="sports.basket" defaultMessage="Basketball" />
    <FormattedMessage id="sports.basket.short" defaultMessage="Basketball" />
    <FormattedMessage id="sports.tennis" defaultMessage="Tennis" />
    <FormattedMessage id="sports.tennis.short" defaultMessage="Tennis" />
    <FormattedMessage id="sports.af" defaultMessage="American Football" />
    <FormattedMessage id="sports.af.short" defaultMessage="American Football" />
    <FormattedMessage id="sports.ih" defaultMessage="Ice Hockey" />
    <FormattedMessage id="sports.ih" defaultMessage="Ice Hockey" />
    <FormattedMessage id="sports.baseball" defaultMessage="Baseball" />
    <FormattedMessage id="sports.baseball.short" defaultMessage="Baseball" />
    <FormattedMessage id="sports.esports" defaultMessage="eSports" />
    <FormattedMessage id="sports.esports.short" defaultMessage="eSports" />
    <FormattedMessage id="sports.mma" defaultMessage="MMA" />
    <FormattedMessage id="sports.mma.short" defaultMessage="MMA" />
    <FormattedMessage id="sports.cricket" defaultMessage="Cricket" />
    <FormattedMessage id="sports.cricket.short" defaultMessage="Cricket" />
    <FormattedMessage id="sports.ru" defaultMessage="Rugby Union" />
    <FormattedMessage id="sports.ru.short" defaultMessage="Rugby Union" />
    <FormattedMessage id="sports.boxing" defaultMessage="Boxing" />
    <FormattedMessage id="sports.boxing.short" defaultMessage="Boxing" />
    <FormattedMessage id="sports.rl" defaultMessage="Rugby League" />
    <FormattedMessage id="sports.rl.short" defaultMessage="Rugby League" />
    <FormattedMessage id="sports.arf" defaultMessage="Australian Football" />
    <FormattedMessage id="sports.arf.short" defaultMessage="Ausie Rules" />
    <FormattedMessage id="sports.hand" defaultMessage="Handball" />
    <FormattedMessage id="sports.hand.short" defaultMessage="Handball" />
    <FormattedMessage id="sports.darts" defaultMessage="Darts" />
    <FormattedMessage id="sports.darts.short" defaultMessage="Darts" />
  </>
)
