/** @format */

import './Order.sass';

import { actions } from '../../../reducers/app';

import { withRouter } from 'react-router';

import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Link } from 'react-router-dom';

import ExpandMore from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';

import { SettingsContext } from '../../../components/shared/SettingsContext';
import TranslatedBetType from '../../../components/shared/TranslatedBetType';
import { formatPrice, formatAmount } from '@mollybet/frontend-common/dist/lib/formatters';
import { inferTextStatus } from '../../../lib/orders';
import BetBarStatusBar from './BetBarStatusBar';
import { ReactComponent as Tick } from '../../../media/xena-icons/check.svg';
import { ReactComponent as Cancel } from '../../../media/xena-icons/cancel.svg';
import { ReactComponent as Open } from '../../../media/xena-icons/pending.svg';
import { ReactComponent as Reconciled } from '../../../media/xena-icons/reconciled.svg';
import { mdiArrowRight } from '@mdi/js';
import { Icon } from '@mdi/react';
import { IconButton, Button } from '@mollybet/ui';

import { XenaDiv } from '../../shared/Common';

const dangerify = (props) => ({
  theme: {
    ...props.theme,
    primary: '#ff6161', //TODO don't hardcode colours
  },
});

const CancelButton = styled(Button).attrs(dangerify)`
  margin-left: -90px;
  margin-right: 12px;
  font-size: 10px;
  flex: 0 0 auto;
`;

const Order = (props) => {
  const context = useContext(SettingsContext);

  const cancel = () => {
    props.actions.orderCancel({
      orderId: props.orderId,
      actions: props.actions,
    });
  };

  const goToOrder = () => {
    props.history.push(`/orders/${props.orderId}`);
  };

  const shouldShowCloseButton = () => {
    //can't close someone else's order
    if (props.placer !== context.currentUser) {
      return false;
    }

    return !props.closed;
  };

  let calc;
  if (props.calc) {
    calc = props.calc.toJS();
  } else {
    calc = {
      openOrderBetWidth: {},
      calcStake: {},
      betsStatus: 'bets-unknown',
      averagePrice: 1,
    };
  }

  //try to use what API says
  if (context.featureBetbarFromAPI && props.betBarValues) {
    calc = {
      openOrderBetWidth: {},
      calcStake: {},
      betsStatus: calc.betsStatus,
      averagePrice: props.price,
    };

    let betBarValues = props.betBarValues.toJS();
    for (let status in betBarValues) {
      calc.calcStake[`${status}-stake`] = betBarValues[status][1];
      let st = (betBarValues[status][1] / props.wantStake) * 100;
      if (st >= 99) {
        st = 100;
      } else if (st < 1) {
        st = 0;
      }
      calc.openOrderBetWidth[`${status}-stake`] = st;
    }
  }

  let status = props.status;
  //if it has pending bets the order is pending
  if (status !== 'placing' && calc.betsStatus !== 'bets-final') {
    status = 'pending';
  }
  const isAcca = props.orderType === 'accas' ? true : false;
  let accaSize = 0;
  if (isAcca) {
    let entries = props.accasInfo.get('entries');
    if (entries) {
      accaSize = entries.size;
    }
  }
  const bookies = [];
  const isFailed = status === 'failed';
  if (props.bets && props.bets.size) {
    props.bets
      .filter((bet) => bet !== undefined)
      .forEach((bet) => {
        bookies.push(bet.get('bookie'));
      });
  }
  return (
    <XenaDiv
      background={'xenaColorTwo'}
      className={`betbar-order narrow${props.closed ? ' closed' : ' open'} ${calc.betsStatus}`}
    >
      <div className="bet-status-container">
        <span className="status">
          {props.status === 'done' && (
            <div className="order-icon">
              <Tick />
            </div>
          )}
          {props.status === 'failed' && (
            <div className="order-icon-cancel">
              <Cancel />
            </div>
          )}
          {props.status === 'open' && (
            <div className="order-icon-cancel">
              <Open />
            </div>
          )}
          {props.status === 'reconciled' && (
            <div className="order-icon-cancel">
              <Reconciled />
            </div>
          )}
          <span className="status-description">{inferTextStatus(status)}</span>
        </span>
        {!isAcca && !isFailed && status !== 'open' && status !== 'pending' && (
          <Link to={`/position/${props.sport}/${props.eventId}`} className="position">
            <FormattedMessage id="xena.order.position" defaultMessage="Position" />
          </Link>
        )}
      </div>
      <div onClick={goToOrder} className="bet-info-container">
        <div className="bet-description-container">
          {isAcca ? (
            <>
              {`${accaSize}x`}
              <FormattedMessage id="xena.position.accumulator" defaultMessage="Accumulator" />
            </>
          ) : (
            <span className="description">
              <TranslatedBetType
                betTypeDescription={props.betTypeDescription}
                betType={props.betType}
                away={props.away}
                home={props.home}
                orderType={props.orderType}
                showOrderType={true}
                exchangeMode={props.exchangeMode}
              />
            </span>
          )}
        </div>
        <div className="stake-price-container">
          <span className="got-container">
            <span className="got">
              {`${formatAmount(
                props.wantStake,
                props.ccy,
                context.displayCcy,
                props.xrates
              )} @ ${
                calc.averagePrice
                  ? formatPrice(calc.averagePrice, context.priceType)
                  : formatPrice(props.wantPrice, context.priceType)
              }`}
            </span>
            <span className="bookie">
              {bookies.length > 0 && `: ${[...new Set(bookies)].toString()}`}
            </span>
          </span>
          <span className="view-order">
            <FormattedMessage id="xena.betBarOrder.orderDetails" defaultMessage="Order details" />
            <IconButton className="arrow-icon">
              <Icon style={{ color: 'currentColor', width: '1em' }} path={mdiArrowRight} />
            </IconButton>
          </span>
        </div>
        <BetBarStatusBar orderId={props.orderId} />
      </div>
      <div className="action-container">
        {shouldShowCloseButton() && (
          <CancelButton variant="secondary" onClick={cancel}>
            <FormattedMessage id="xena.xenaOrderPage.cancelOrder" defaultMessage="Cancel Order" />
          </CancelButton>
        )}
        <ExpandMore onClick={props.toggle} />
      </div>
    </XenaDiv>
  );
};

const mapDispatchToProps = (dispatch) => {
  let _actions = {};
  let neededActions = [
    'orderCancel',
    'orderCancelResponse',
    'eventOpenPosition',
    'eventOpenPositionResponse',
    'eventOpenPositionEventResponse',
    'betslipOrderShowHover',
    'betslipOrderHideHover',
    'getOrders',
    'loadOrders',
  ];
  for (let act of neededActions) {
    _actions[act] = actions[act];
  }

  return {
    actions: bindActionCreators(_actions, dispatch),
  };
};

// turn state of combined reducers into state required by component
const makeMapStateToProps = (initialState, initialProps) => {
  const mapStateToProps = (state) => {
    let order = state.getIn(['betbar', 'orders', initialProps.orderId], null);
    return order
      ? {
          orderType: order.getIn(['orderType']),
          exchangeMode: order.get('exchangeMode', ''),
          accasInfo: order.getIn(['accasInfo']),
          eventId: order.getIn(['eventInfo', 'eventId'], null),
          sport: order.get('sport', '?'),
          shell: order.get('shell', false),
          bets: order.get('bets', null),
          userData: order.get('userData', ''),
          closed: order.get('closed', false),
          status: order.get('status', ''),
          wantStake: order.getIn(['wantStake', '1'], 0),
          wantPrice: order.get('wantPrice', null),

          placer: order.get('placer', state.getIn(['base', 'profile', 'username'], '?')),

          away: order.getIn(['eventInfo', 'awayTeam'], 'Away'),
          home: order.getIn(['eventInfo', 'homeTeam'], 'Home'),
          betType: order.get('betType', '?'),
          betTypeDescription: order.get('betTypeDescription', '?'),

          //calculated stuff
          calc: order.get('calc'),
          price: order.get('price'),
          betBarValues: order.get('betBarValues', null),

          ccy: order.getIn(['wantStake', '0'], '?').toLowerCase(),
          //ccyRate: order.get('ccyRate', 1),
          xrates: state.getIn(['base', 'xrates'], null), //need this
        }
      : {};
  };

  return mapStateToProps;
};

export default (orderId) =>
  connect(makeMapStateToProps, mapDispatchToProps, null, {
    areStatesEqual: (next, prev) => {
      return (
        prev.getIn(['betbar', 'orders', orderId], null) ===
          next.getIn(['betbar', 'orders', orderId], null) &&
        prev.getIn(['betbar', 'xrates'], null) === next.getIn(['betbar', 'xrates'], null)
      );
    },
  })(withRouter(injectIntl(Order)));
