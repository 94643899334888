/** @format */

import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { SettingsContext } from './SettingsContext';
import { translateBetType } from '../../lib/orders';
import { isCustomBet } from '@mollybet/frontend-common/dist/lib/betTypes';

const TranslatedBetType = (props) => {
  const context = useContext(SettingsContext);

  if (isCustomBet(props.betType)) {
    return 'Cash Out';
  }

  let betTypeDescription = props.betTypeDescription;
  if (!betTypeDescription || betTypeDescription === '?' || betTypeDescription === 'None' || context.featureTranslatedBetTypes) {
    let _betTypeDescription = translateBetType(props.betType, props.home, props.away, props.intl);
    if (_betTypeDescription) {
      betTypeDescription = _betTypeDescription;
    }
  }

  // if orderType is lay then we want to remove the prefix AGAINST
  if (props.orderType === 'lay') {
    betTypeDescription = betTypeDescription.split(' ').slice(1).join(' ');
  }

  // if there isnt an explicit orderType display then preface the bet description with Lay
  if (props.showOrderType === true && props.orderType === 'lay') {
    betTypeDescription = 'Lay ' + betTypeDescription;
  }

  if (props.exchangeMode === 'dark') {
    betTypeDescription = betTypeDescription + ' (dark)';
  }

  return (
    <span
      title={`${betTypeDescription || ''} ${
        context.featureShowInternalInfo ? `[${props.betType}]` : ''
      }`}
    >
      {betTypeDescription}
    </span>
  );
};

export default injectIntl(TranslatedBetType);
