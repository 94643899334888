/** @format */

import config from '../../config';
import { actions } from '../../reducers/app';

import React from 'react';
import { injectIntl } from 'react-intl';
import Fuse from 'fuse.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withRouter } from 'react-router-dom';

import { formatEventTime } from '@mollybet/frontend-common/dist/lib/formatters';
import { SettingsContext } from '../../components/shared/SettingsContext';
import { XenaDiv } from '../shared/Common';
import { mdiStar, mdiStarOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { IconButton } from '@mollybet/ui';
import Telemetry from '@mollybet/frontend-common/dist/lib/Telemetry';
import styled from 'styled-components';

const StyledSearchBoxContainer = styled.div`
  width: 100%;

  .search-box {
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 100%;
    display: flex;
    align-items: center;
    border: 0.2px solid ${(props) => props.theme.borderColor};

    .search-text-field {
      width: 100%;
      height: 22px;
      background: ${(props) => props.theme.xenaColorTwo};
      border-radius: 2.5px;
      border: solid 0.5px white;
      font-size: 14px;
      color: ${(props) => props.theme.fontColorContrast};
      resize: none;
      outline-color: white;
      outline-width: 2px;
    }
  }

  .search-results-container {
    min-width: 215px;
    width: 100%;

    .search-results {
      display: flex;
      flex-direction: column;
      overflow: scroll;
      border: 0.2px solid #d0d0d0;
      padding-bottom: 10px;
      padding-top: 10px;

      .search-result-event,
      .search-result-competition {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        min-height: 60px;
        margin-left: 6px;
        margin-top: 1px;

        .comp-fav-icon {
          svg {
            height: 1.3rem;
            margin-left: -2px;
          }
        }

        &.early {
          border-left: ${(props) => props.theme.early} 3px solid;
        }

        &.today {
          border-left: ${(props) => props.theme.today} 3px solid;
        }

        &.ir {
          border-left: ${(props) => props.theme.ir} 3px solid;
        }

        &.fav {
          border-left: ${(props) => props.theme.favs} 3px solid;
        }

        .search-result-name {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex-grow: 1;
          font-size: 12px;
          margin-left: 6px;
          color: white;
          margin-right: 4px;
        }

        .competition-name {
          margin-left: 6px;
          color: white;
          font-size: 12px;
          margin-right: 10px;
        }

        .time-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-left: -5px;
          margin-top: -12px;

          .fav-icon {
            svg {
              height: 1.6em;
            }
          }

          .search-result-time {
            font-size: 10px;
            color: #a4adde;
          }
        }
      }
    }
  }
`;

class SearchBox extends React.Component {
  static contextType = SettingsContext;

  state = {
    results: [],
    fuse: null,
    lastFuseUpdate: null,
    searchBarFocusState: null,
    searchToggled: false,
    searchTerm: '',
  };

  searchBar = React.createRef();
  searchContainer = React.createRef();

  toggleSearch = () => {
    this.setState({ searchToggled: !this.state.searchToggled, searchTerm: '' });
  };

  updateSearchTerm = (event) => {
    let results = [];
    this.setState({ searchTerm: event.target.value });

    if (event.target.value && event.target.value.length >= config.tradeMinSearch) {
      if (this.state.fuse) {
        results = this.state.fuse.search(event.target.value);
      }

      this.setState({ results: results });
    } else {
      this.closeSearch();
    }
  };

  popFuse() {
    let shouldUpdateFuse = false;
    let now = new Date();
    if (
      !this.state.lastFuseUpdate ||
      now - this.state.lastFuseUpdate > config.timings.tradePageSearchStaleness
    ) {
      shouldUpdateFuse = true;
    }

    if (shouldUpdateFuse) {
      //kleen up
      let searchSet = [];
      this.props.events.forEach((event, eventId) => {
        searchSet.push({
          eventId,
          away: event.get('away'),
          home: event.get('home'),
          marketId: event.get('marketId'),
          sport: event.get('sport'),
          competitionId: event.get('competitionId'),
        });
      });

      //this is kinda very expensive
      if (this.props.markets) {
        this.props.competitions.forEach((competition, competitionId) => {
          for (let marketId of config.markets) {
            let sport = competition.get('sport');
            let _events = this.props.markets.getIn([sport, marketId, competitionId], null);
            if (_events && _events.size) {
              searchSet.push({
                competitionId,
                name: competition.get('name'),
                sport,
                marketId,
              });
            }
          }
        });
      }

      this.setState({
        fuse: new Fuse(searchSet, {
          ...config.tradeSearchOptions,
          keys: ['home', 'away', 'name'],
        }),
        lastFuseUpdate: now,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.popFuse();
  }

  closeSearch = (removeSearchTerm) => {
    this.setState({
      results: [],
    });
    if (removeSearchTerm) {
      this.setState({ searchTerm: '' });
    }
  };

  catchEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  goToSearchResult = (route, isEvent = false) => {
    Telemetry.pageView(
      `/search?term=${this.state.searchTerm}&cat=${isEvent ? 'event' : 'competition'}`
    );
    Telemetry.recordGA(
      `search_${isEvent ? 'event' : 'competition'}`,
      'search',
      'search',
      this.state.searchTerm.length
    );

    this.props.actions.toggleSearchBar();
    this.setState({ searchTerm: '' });
    this.props.history.push(route);
  };

  addCompFav = (competitionId, marketId, sport) => () => {
    this.props.actions.competitionAddFav({
      competitionId: competitionId,
      marketId: marketId,
      sport: sport,
    });
  };

  removeCompFav = (competitionId, marketId) => () => {
    this.props.actions.competitionRemoveFav({
      competitionId: competitionId,
      marketId: marketId,
    });
  };

  addEventFav = (eventId, competitionId, eventSport) => () => {
    this.props.actions.eventAddFav({
      eventId: eventId,
      competitionId: competitionId,
      sport: eventSport,
    });
  };

  removeEventFav = (eventId) => () => {
    this.props.actions.eventRemoveFav({
      eventId: eventId,
    });
  };

  render() {
    let searchResults = [];
    for (let idx in this.state.results) {
      let _item = this.state.results[idx];
      let eventId = _item['eventId'];
      let competitionId = _item['competitionId'];
      let sport = _item['sport'];

      let item;
      if (eventId) {
        item = this.props.events.get(eventId);
      } else if (competitionId) {
        item = this.props.competitions.get(competitionId);
      }

      //handle event
      if (eventId) {
        //let event = this.state.results[idx]
        let marketId = item.get('marketId', '');
        let isInFavs = item.get('isInFavs', '');
        let evTime = formatEventTime(
          eventId,
          item.get('startTime', ''),
          null,
          null,
          marketId,
          this.context.timezone
        );

        searchResults.push(
          <div key={eventId} className={`search-result-event ${marketId}`}>
            <div className="time-container">
              <span className="fav-icon">
                {isInFavs ? (
                  <IconButton
                    className="fav-icon"
                    color="#FFDC00"
                    onClick={this.removeEventFav(eventId)}
                  >
                    <Icon path={mdiStar} />
                  </IconButton>
                ) : (
                  <IconButton
                    className="fav-icon"
                    color="#838DFF"
                    onClick={this.addEventFav(eventId, competitionId, sport)}
                  >
                    <Icon path={mdiStarOutline} />
                  </IconButton>
                )}
              </span>
              <span className="search-result-time">{evTime}</span>
            </div>
            <span
              className="search-result-name"
              onClick={() =>
                this.goToSearchResult(`/trade/${sport}/${competitionId}/${eventId}`, true)
              }
            >
              <span>{item.get('home', 'Home')}</span>
              <span>{item.get('away', 'Away')}</span>
            </span>
          </div>
        );
        //handle competition
      } else if (competitionId) {
        let marketId = _item['marketId'];
        let isInFavs =
          this.props.competitionFavouritedList &&
          this.props.competitionFavouritedList.getIn([competitionId]);
        searchResults.push(
          <div key={`${marketId}_${competitionId}`} className={`search-result-competition`}>
            <span className="comp-fav-icon">
              {isInFavs ? (
                <IconButton
                  className="comp-fav-icon"
                  color="#FFDC00"
                  onClick={this.removeCompFav(competitionId, marketId)}
                >
                  <Icon path={mdiStar} />
                </IconButton>
              ) : (
                <IconButton
                  className="comp-fav-icon"
                  color="#838DFF"
                  onClick={this.addCompFav(competitionId, marketId, sport)}
                >
                  <Icon path={mdiStarOutline} />
                </IconButton>
              )}
            </span>
            <span
              className="competition-name"
              onClick={() => this.goToSearchResult(`/trade/${sport}/${competitionId}`)}
            >
              {item.get('name', 'Competition')}
            </span>
          </div>
        );
      }
    }

    return (
      <StyledSearchBoxContainer>
        <div className="search-box">
          <input
            type="text"
            className="search-text-field"
            onChange={this.updateSearchTerm}
            onClick={this.catchEvent}
            value={this.state.searchTerm}
            color="primary"
            inputRef={(el) => {
              this.searchBar = el;
            }}
            autoFocus
          />
        </div>
        {
          searchResults.length && this.state.searchTerm !== '' ? (
            <XenaDiv background={'xenaColorHeader'} className="search-results-container">
              <div className="search-results" ref={this.searchContainer}>
                {searchResults}
              </div>
            </XenaDiv>
          ) : null //need an empty holder state here
        }
      </StyledSearchBoxContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// turn state of combined reducers into state required by component
const mapStateToProps = (state) => ({
  favedCompetitions: state.getIn(['base', 'settings', 'trade', 'faved', 'competitions'], null),
  competitions: state.getIn(['trade', 'competitions'], null),
  events: state.getIn(['trade', 'events'], null),
  markets: state.getIn(['trade', 'markets'], null),
  competitionFavouritedList: state.getIn(
    ['base', 'settings', 'trade', 'faved', 'competitions'],
    false
  ),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual: (next, prev) => {
    return (
      prev.getIn(['base', 'settings', 'trade', 'faved', 'competitions'], null) ===
        next.getIn(['base', 'settings', 'trade', 'faved', 'competitions'], null) &&
      // prev.getIn(['trade', 'events'], null) === next.getIn(['trade', 'events'], null) &&
      // prev.getIn(['trade', 'competitions'], null) === next.getIn(['trade', 'competitions'], null) &&
      prev.getIn(['trade', 'markets'], null) === next.getIn(['trade', 'markets'], null)
    );
  },
})(injectIntl(withRouter(SearchBox)));
