/** @format */

import React, { useContext } from 'react';

import { actions } from '../reducers/app';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { XenaDiv } from './shared/Common';
import styled from 'styled-components';

import { formatAmount } from '@mollybet/frontend-common/dist/lib/formatters';

import { SettingsContext } from '../components/shared/SettingsContext';

const BalanceContainer = styled(XenaDiv)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .balance {
    margin-right: 10px;
    font: Regular 13px/15px Roboto;
    letter-spacing: 0;
    opacity: 1;
    color: white;
  }
`;

const BalanceOverlay = styled.div`
  height: 180px;
  width: 160px;
  position: absolute;
  top: 55px;
  right: 16px;
  background-color: white;
  z-index: 40;
  border-radius: 3px;

  .triangle {
    position: absolute;
    right: 16px;
    top: -15px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid white;
    z-index: 50;
  }

  .balance-overlay-contents {
    color: black;
    z-index: 60;
    height: 160px;
    width: 160px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;

    .contents {
      font-size: 13px;
      font-weight: bold;
    }

    .smart {
        color: #34ac70;
    }

    .info {
      display: flex;
      flex-direction: column;
      margin-left: 14px;
      justify-content: flex-start;
      align-items: flex-start;

      .title {
        font-size: 12px;
      }

      .profit {
        font-size: 14px;
        color: #3b834c;
      }

      .loss {
        font-size: 14px;
      }
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: solid 1px #d3d3d3;
`;

const BalanceHolder = (props) => {
  const context = useContext(SettingsContext);
  const smartCredit =
      context.featureSmartCredit &&
      props.smartCredit != null &&
      !isNaN(props.smartCredit)
        ? props.smartCredit
        : 0;
  const showSmartCredit = props.smartCredit !== null && context.featureSmartCredit;

  return (
    <>
      <BalanceContainer color={'fontColorWhite'} onClick={props.actions.toggleBalance}>
        <div className="balance">
          {context.hideCredit ? (
            'Credit'
          ) : props.creditLimit !== null ? (
            formatAmount(
              props.credit + smartCredit,
              props.ccyCode,
              props.displayCcy,
              props.xrates,
              undefined,
              undefined,
              props.credit > 1000
            )
          ) : props.totalBookieBalance ? (
            formatAmount(
              props.totalBookieBalance,
              props.ccyCode,
              props.displayCcy,
              props.xrates,
              undefined,
              undefined,
              props.totalBookieBalance > 1000
            )
          ) : (
            <FormattedMessage id="xena.balanceHolder.creditUnlimited" defaultMessage="Unlimited" />
          )}
        </div>
      </BalanceContainer>
      {props.balanceOpen && (
        <BalanceOverlay>
          <div className="triangle"></div>
          <div className="balance-overlay-contents">
            <div className="info">
              <div className="title">
                <FormattedMessage id="xena.balanceHolder.credit" defaultMessage="Balance" />
              </div>
              <div className="contents">
                {props.creditLimit !== null ? (
                  formatAmount(
                    props.credit,
                    props.ccyCode,
                    props.displayCcy,
                    props.xrates,
                    undefined,
                    undefined,
                    props.credit > 1000
                  )
                ) : props.totalBookieBalance ? (
                  formatAmount(
                    props.totalBookieBalance,
                    props.ccyCode,
                    props.displayCcy,
                    props.xrates,
                    undefined,
                    undefined,
                    props.totalBookieBalance > 1000
                  )
                ) : (
                  <FormattedMessage
                    id="xena.balanceHolder.creditUnlimited"
                    defaultMessage="Unlimited"
                  />
                )}
              </div>
            </div>
             {showSmartCredit && (
              <div className="info">
                <div className="title">
                  <FormattedMessage
                    id="xena.balanceHolder.smartCredit"
                    defaultMessage="Smart Credit"
                  />
                </div>
                <div className="contents smart">
                  + {formatAmount(smartCredit, props.ccyCode, props.displayCcy, props.xrates)}
                </div>
              </div>
            )}

            <div className="info">
              <div className="title">
                <FormattedMessage id="xena.balanceHolder.openStakes" defaultMessage="Open Stakes" />
              </div>
              <div className="contents">
                - {formatAmount(props.openStakes, props.ccyCode, props.displayCcy, props.xrates)}
              </div>
            </div>

            <Divider/>

            <div className="info">
              <div className="title">
                <FormattedMessage
                  id="xena.balanceHolder.todayPl"
                  defaultMessage="Today's profit/ loss"
                />
              </div>
              <div className={`contents ${props.todayPl > 0 ? 'profit' : 'loss'}`}>
                {formatAmount(props.todayPl, props.ccyCode, props.displayCcy, props.xrates)}
              </div>
            </div>
           
           
          </div>
        </BalanceOverlay>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => ({
  ccyCode: state.getIn(['base', 'profile', 'ccyCode'], '?').toLowerCase(),
  credit: state.getIn(['base', 'profile', 'credit'], 0),
  creditLimit: state.getIn(['base', 'profile', 'creditLimit'], null),
  totalBookieBalance: state.getIn(['base', 'profile', 'totalBookieBalance'], null),

  balance: state.getIn(['base', 'profile', 'balance'], 0),
  yesterdayPl: state.getIn(['base', 'profile', 'yesterdayPl'], 0),
  todayPl: state.getIn(['base', 'profile', 'todayPl'], 0),
  openStakes: state.getIn(['base', 'profile', 'openStakes'], 0),
  smartCredit: state.getIn(['base', 'profile', 'smartCredit'], 0),

  xrates: state.getIn(['base', 'xrates'], null),
  displayCcy: state
    .getIn(
      ['base', 'settings', 'general', 'displayCcy'],
      state.getIn(['base', 'profile', 'ccyCode'], '?')
    )
    .toLowerCase(),

  balanceOpen: state.getIn(['ui', 'balanceOpen'], false),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BalanceHolder));
