/**
 * This file combines the reducers...
 * you may or may not need/want to do this since each view will probably have its own reducer
 *
 * @format
 */

import combineReducers from 'redux-immutable-combine-reducers';

import base from '@mollybet/frontend-common/dist/reducers/base';
import { actions as baseActions } from '@mollybet/frontend-common/dist/reducers/base';

import login from '@mollybet/frontend-common/dist/reducers/login';
import { actions as loginActions } from '@mollybet/frontend-common/dist/reducers/login';

import reset from '@mollybet/frontend-common/dist/reducers/reset';
import { actions as resetActions } from '@mollybet/frontend-common/dist/reducers/reset';

import ui from './ui';
import { actions as uiActions } from './ui';

import trade from '@mollybet/frontend-common/dist/reducers/trade/trade';
import { actions as tradeActions } from '@mollybet/frontend-common/dist/reducers/trade/trade';

import betbar from '@mollybet/frontend-common/dist/reducers/trade/betbar';
import { actions as betbarActions } from '@mollybet/frontend-common/dist/reducers/trade/betbar';

import bets from '@mollybet/frontend-common/dist/reducers/history/bets';
import { actions as betsActions } from '@mollybet/frontend-common/dist/reducers/history/bets';

import orders from '@mollybet/frontend-common/dist/reducers/history/orders';
import { actions as ordersActions } from '@mollybet/frontend-common/dist/reducers/history/orders';

import activePositions from '@mollybet/frontend-common/dist/reducers/history/activePositions';
import { actions as activePositionsActions } from '@mollybet/frontend-common/dist/reducers/history/activePositions';

import orderLogs from '@mollybet/frontend-common/dist/reducers/shared/orderLogs';
import { actions as orderLogsActions } from '@mollybet/frontend-common/dist/reducers/shared/orderLogs';

import placers from '@mollybet/frontend-common/dist/reducers/shared/placers';
import { actions as placersActions } from '@mollybet/frontend-common/dist/reducers/shared/placers';

import parlays from '@mollybet/frontend-common/dist/reducers/shared/parlays';
import { actions as parlaysActions } from '@mollybet/frontend-common/dist/reducers/shared/parlays';

import positions from '@mollybet/frontend-common/dist/reducers/shared/positions';
import { actions as positionsActions } from '@mollybet/frontend-common/dist/reducers/shared/positions';

import priceHistories from '@mollybet/frontend-common/dist/reducers/shared/priceHistories';
import { actions as priceHistoriesActions } from '@mollybet/frontend-common/dist/reducers/shared/priceHistories';

//in this file we're just combining the reducers
//we're using the immutable combiner so we get actual immmutable sub-states
//otherwise you get a mix of immutable and non-immutable things

const rootReducer = combineReducers({
  //core
  base,
  ui,

  //login
  login,
  reset,

  //trade
  trade,
  betbar,

  //history
  orders,
  bets,
  activePositions,

  //windows
  orderLogs,
  placers,
  parlays,
  positions,
  priceHistories,
});

export default rootReducer;

export const actions = {
  ...baseActions,
  ...loginActions,
  ...resetActions,
  ...uiActions,
  ...tradeActions,
  ...betbarActions,
  ...betsActions,
  ...ordersActions,
  ...activePositionsActions,
  ...orderLogsActions,
  ...placersActions,
  ...parlaysActions,
  ...positionsActions,
  ...priceHistoriesActions,
};
