/** @format */

import React from 'react';

import { actions } from '../reducers/app';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { mdiChevronUp } from '@mdi/js';
import { Icon } from '@mdi/react';
import { IconButton } from '@mollybet/ui';
import styled from 'styled-components';

const BetBarButtonContainer = styled.div`
  position: fixed;
  bottom: 0px;
  right: 50%;
  left: 50%;
  margin-left: -32px;
  background: #838dff;
  color: black;
  z-index: 1000;
  height: 22px;
  width: 64px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const BetBarButton = (props) => {
  if (!props.orders || props.orders.size < 1) {
    return null;
  }
  return (
    <BetBarButtonContainer onClick={props.actions.toggleBetbarExpanded}>
      <IconButton className="chevron-icon" color="#DDDDDD">
        <Icon style={{ color: 'black' }} path={mdiChevronUp} />
      </IconButton>
    </BetBarButtonContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => ({
  orders: state.getIn(['betbar', 'orders'], null),
  isAuth: state.getIn(['base', 'isAuth'], 'pending'),
  searchBarOpen: state.getIn(['ui', 'searchBarOpen'], false),
});

export default connect(mapStateToProps, mapDispatchToProps)(BetBarButton);
