/** @format */

import React, { useEffect } from 'react';

import { actions } from '../reducers/app';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';
import { ReactComponent as SearchGlass } from '../media/xena-icons/magnify.svg';
import { injectIntl } from 'react-intl';
import { mdiDotsVertical } from '@mdi/js';
import { Icon } from '@mdi/react';
import routes from '../routes';

import MarketSelector from './nav/MarketSelector';
import BalanceHolder from './BalanceHolder';
import SearchBox from './trade/SearchBox';
import { XenaDiv } from './shared/Common';
import styled from 'styled-components';

const XenaHeader = styled(XenaDiv)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background: ${(props) => props.sudoer && 'purple'};

  svg {
    fill: white;
  }

  .header-menu-log-in {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
  }

  .header-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 80px;

    .molly-icon {
      fill: white;
      height: 25px;
      width: 25px;
      margin-left: -7px;
    }
    .dots-icon {
      fill: white;
      height: 25px;
      width: 25px;
    }

    .header-search-icon {
      margin-left: 1em;
      color: white;
    }
  }

  .search-field-container {
    width: 100%;
    z-index: 50;
    height: 30px;
    margin-right: 15px;
    margin-left: 10px;
  }

  .center-container {
    width: 140px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .balance-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
`;

const Header = (props) => {
  // called after Component has mounted
  useEffect(() => {
    // Fix for iOS hiding parts of the UI
    // See:
    // - https://bugs.webkit.org/show_bug.cgi?id=141832
    // - https://medium.com/@susiekim9/how-to-compensate-for-the-ios-viewport-unit-bug-46e78d54af0d
    // - https://nicolas-hoizey.com/articles/2015/02/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers/

    if (!window.__HORRIBLE_SCROLLED) {
      window.__HORRIBLE_SCROLLED = true;
      window.scrollTo(0, 1);
    }
  }, []);
  const toggleXenaSideMenu = () => {
    props.actions.toggleXenaSideMenu();
  };
  const toggleSearchBar = () => {
    props.actions.toggleSearchBar();
  };
  if (props.isAuth === 'no') {
    return (
      <XenaHeader background={'xenaColorHeader'} color={'fontColorWhite'}>
        <div className="header-menu-log-in">
          <img
            style={{ height: '40px' }}
            alt="logo"
            src={`${routes.basename}${Whitelabel.logos ? Whitelabel.logos['wide']['dark'] : ''}`}
          />
        </div>
      </XenaHeader>
    );
  }
  return (
    <XenaHeader background={'xenaColorHeader'} sudoer={props.sudoer}>
      <div className="header-menu">
        <div className="header-menu-icon" onClick={toggleXenaSideMenu}>
          <Icon className="dots-icon" path={mdiDotsVertical} />
          <img
            alt="logo"
            className="molly-icon"
            src={`${routes.basename}${Whitelabel.logos ? Whitelabel.logos['coin']['dark'] : ''}`}
          />
        </div>
        <div className="header-search-icon" onClick={toggleSearchBar}>
          <SearchGlass />
        </div>
      </div>
      {props.searchBarOpen ? (
        <div className="search-field-container">
          <SearchBox />
        </div>
      ) : (
        <>
          <div className="center-container">
            <MarketSelector />
          </div>
          <div className="balance-container">
            <BalanceHolder />
          </div>
        </>
      )}
    </XenaHeader>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => ({
  isAuth: state.getIn(['base', 'isAuth'], 'pending'),
  searchBarOpen: state.getIn(['ui', 'searchBarOpen'], false),
  sudoer: state.getIn(['base', 'profile', 'sudoer'], ''),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Header));
