/** @format */

import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import Session from '@mollybet/frontend-common/dist/lib/Session';
import { fromJS } from 'immutable';

let initialState = fromJS({
  sideMenuOpen: false,
  marketSelectorOpen: false,
  searchBarOpen: false,
  balanceOpen: false,
  parlayMode: false,
  focusedMarket: 'ir',
  scrollPositionTradePage: undefined,
  scrollPositionEventPage: undefined,
  placementConfirmation: {
    parlayId: null,
    betslipId: null,
    placerId: null,
  },
});

const functions = {
  //change currently focused sport
  changeSport: (state, action) => {
    Session.set(['settings', 'trade', 'sport'], action.data.sport);
    return state.setIn(['settings', 'trade', 'sport'], action.data.sport);
  },

  recordTradePageScrollPosition: (state, action) => {
    state = state.set('scrollPositionTradePage', action.data);
    return state;
  },

  recordEventPageScrollPosition: (state, action) => {
    state = state.set('scrollPositionEventPage', action.data);
    return state;
  },

  //change currently focused market
  changeMarketInFocus: (state, action) => {
    Session.set(['settings', 'trade', 'focusedMarket'], action.data.market);
    state = state.set('focusedMarket', action.data.market);
    return state.setIn(['settings', 'trade', 'focusedMarket'], action.data.market);
  },

  //toggle side navigation mentu
  toggleXenaSideMenu: (state, action) => {
    state = state.set('marketSelectorOpen', false);
    state = state.set('searchBarOpen', false);
    state = state.set('balanceOpen', false);
    if (state.get('sideMenuOpen')) {
      state = state.set('sideMenuOpen', false);
    } else {
      state = state.set('sideMenuOpen', true);
    }
    return state;
  },

  //toggle sport market selector
  toggleMarketSelector: (state, action) => {
    state = state.set('sideMenuOpen', false);
    state = state.set('searchBarOpen', false);
    state = state.set('balanceOpen', false);
    if (state.get('marketSelectorOpen')) {
      state = state.set('marketSelectorOpen', false);
    } else {
      state = state.set('marketSelectorOpen', true);
    }
    return state;
  },

  //toggle search  bar
  toggleSearchBar: (state, action) => {
    state = state.set('marketSelectorOpen', false);
    state = state.set('sideMenuOpen', false);
    state = state.set('balanceOpen', false);
    if (state.get('searchBarOpen')) {
      state = state.set('searchBarOpen', false);
    } else {
      state = state.set('searchBarOpen', true);
    }
    return state;
  },

  //toggle balance
  toggleBalance: (state, action) => {
    state = state.set('marketSelectorOpen', false);
    state = state.set('sideMenuOpen', false);
    state = state.set('searchBarOpen', false);
    if (state.get('balanceOpen')) {
      state = state.set('balanceOpen', false);
    } else {
      state = state.set('balanceOpen', true);
    }
    return state;
  },

  //close all other toggles
  closeAllXenaModals: (state, action) => {
    state = state.set('marketSelectorOpen', false);
    state = state.set('sideMenuOpen', false);
    state = state.set('searchBarOpen', false);
    state = state.set('balanceOpen', false);
    return state;
  },

  toggleAccBar: (state, action) => {
    let isExpanded = state.get('accBarOpen', false);
    state = state.set('accBarOpen', !isExpanded);
    return state;
  },

  expandAccBar: (state, action) => {
    state = state.set('accBarOpen', true);
    return state;
  },

  //switch between single bet and parlay mode
  togglePlacementMode: (state, _action) => {
    let parlayMode = state.get('parlayMode', false);
    return state.set('parlayMode', !parlayMode);
  },

  ////// BETBAR STATE

  //expand or contract the betbar
  toggleBetbarExpanded: (state, _action) => {
    let isExpanded = state.getIn(['settings', 'trade', 'betbar', 'expanded'], false);
    Session.set(['settings', 'trade', 'betbar', 'expanded'], !isExpanded);
    state = state.setIn(['settings', 'trade', 'betbar', 'expanded'], !isExpanded);
    return state;
  },

  //expand or contract the betbar
  openBetbar: (state, _action) => {
    Session.set(['settings', 'trade', 'betbar', 'expanded'], true);
    state = state.setIn(['settings', 'trade', 'betbar', 'expanded'], true);
    return state;
  },

  ////// PLACER CONFIRMATION POPUP

  placerPopConfirm: (state, action) => {
    let placerId = action.data.placerId;
    let betslipId = action.data.betslipId;
    let parlayId = action.data.parlayId;
    state = state.setIn(['placementConfirmation', 'parlayId'], parlayId);
    state = state.setIn(['placementConfirmation', 'placerId'], placerId);
    return state.setIn(['placementConfirmation', 'betslipId'], betslipId);
  },

  placerAcceptConfirm: (state, _action) => {
    //do something else?
    state = state.removeIn(['placementConfirmation', 'parlayId']);
    state = state.removeIn(['placementConfirmation', 'placerId']);
    return state.removeIn(['placementConfirmation', 'betslipId']);
  },

  placerRejectConfirm: (state, _action) => {
    state = state.removeIn(['placementConfirmation', 'parlayId']);
    state = state.removeIn(['placementConfirmation', 'placerId']);
    return state.removeIn(['placementConfirmation', 'betslipId']);
  },

  ////// SETTINGS

  //this is a generic way of applying interface and settings changes
  applySettings: (state, action) => {
    //basically overwrite all the settings
    if (action.data) {
      state = state.mergeDeepIn(['settings'], action.data);
    }

    //wonderful exceptions
    if (action.data && action.data['trade']) {
      if (action.data['trade']['pricesBookies']) {
        state = state.setIn(
          ['settings', 'trade', 'pricesBookies'],
          fromJS(action.data['trade']['pricesBookies'])
        );
      }
      if (action.data['trade']['disabledBookies']) {
        state = state.setIn(
          ['settings', 'trade', 'disabledBookies'],
          fromJS(action.data['trade']['disabledBookies'])
        );
      }
    }

    if (action.data && action.data['history']) {
      if (action.data['history']['bets'] && action.data['history']['bets']['views']) {
        state = state.setIn(
          ['settings', 'history', 'bets', 'views'],
          fromJS(action.data['history']['bets']['views'])
        );
      }
    }

    //base already updates Session

    return state;
  },

  ////// DUMMY

  //dummy action so that GA records it
  useMobileInterface: (state, _action) => {
    return state;
  },

  //dummy action so that GA records it
  useDesktopInterface: (state, _action) => {
    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
