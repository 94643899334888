/**
 * /*
 * This file contains all the routing and menu generation setup.
 * If you want to add another route + view , you have to do it in here.
 * `title`: title of item
 * `route`: route to assign
 * `url`: if you don't specify a local route, use a url to redirect to an external site
 * `component`: what to render
 * `needsAuth`: does the user need to have authentication
 * `menu`: show in top menu
 * `onlyShowIf`: only show if a certain key is true (looks in this.props.features and session.config)
 * `triggerAction`: also triggers an action in redux
 *
 * @format
 */

import React from 'react';

const Login = React.lazy(() => import('./views/Login'));
const ResetPassword = React.lazy(() => import('./views/ResetPassword'));
const Trade = React.lazy(() => import('./views/Trade'));
const Dashboard = React.lazy(() => import('./views/Dashboard'));
const Feedback = React.lazy(() => import('./views/Feedback'));
const AccountPage = React.lazy(() => import('./views/Account'));
const SettingsPage = React.lazy(() => import('./views/Settings'));
const XenaOrderPage = React.lazy(() => import('./views/XenaOrderPage'));
const PriceHistoryPage = React.lazy(() => import('./views/PriceHistoryPage'));
const HistoryOrders = React.lazy(() => import('./views/history/Orders'));
const PositionsPage = React.lazy(() => import('./views/history/PositionsPage'));
const Terms = React.lazy(() => import('./views/Terms'));
const TermsAndConditions = React.lazy(() => import('./views/TermsAndConditions'));
const SudoPage = React.lazy(() => import('./views/SudoPage'));
const BetslipView = React.lazy(() => import('./views/BetslipView'));
const ActivePositionView = React.lazy(() => import('./views/ActivePositionView'));

const routes = {
  items: [
    {
      title: { id: 'nav.routes.login_reset', defaultMessage: 'Reset Password' },
      route: '/reset/:resetToken',
      component: ResetPassword,
      needsAuth: false,
      menu: false,
    },
    {
      title: { id: 'nav.routes.feedback', defaultMessage: 'Feedback' },
      route: '/feedback',
      component: Feedback,
      needsAuth: true,
      menu: false,
    },
    {
      title: { id: 'nav.routes.sudoUsers', defaultMessage: 'Sudo Users' },
      route: '/sudo',
      component: SudoPage,
      needsAuth: true,
      menu: false,
    },
    {
      title: { id: 'nav.routes.terms', defaultMessage: 'Terms' },
      route: '/terms/:termType?/',
      component: Terms,
      needsAuth: true,
      menu: false, //under user menu
    },
    {
      title: { id: 'nav.routes.account', defaultMessage: 'Account' },
      route: '/account',
      component: AccountPage,
      needsAuth: true,
      menu: false,
    },
    {
      title: { id: 'nav.routes.TermsAndConditions', defaultMessage: 'TermsAndConditions' },
      route: '/TermsAndConditions',
      component: TermsAndConditions,
      needsAuth: true,
      menu: false,
    },
    {
      title: { id: 'nav.routes.settings', defaultMessage: 'Settings' },
      route: '/settings',
      component: SettingsPage,
      needsAuth: true,
      menu: false,
    },
    {
      title: { id: 'nav.routes.login', defaultMessage: 'Login' },
      route: '/login',
      component: Login,
      needsAuth: false,
      menu: false,
    },
    {
      title: { id: 'nav.routes.priceHistory', defaultMessage: 'Price History' },
      route: '/priceHistory/:sport?/:eventId?/:betType?',
      component: PriceHistoryPage,
      needsAuth: true,
      onlyShowIf: 'narrow',
    },
    {
      title: { id: 'nav.routes.trade', defaultMessage: 'Trade' },
      route: '/trade/:sport?/:competitionId?/:eventId?',
      component: Trade,
      needsAuth: true,
      onlyShowIf: 'narrow',
    },
    {
      title: { id: 'nav.routes.trade', defaultMessage: 'Trade' },
      route: '/beta',
      url: '/beta',
      component: Trade,
      needsAuth: true,
      menu: false,
    },
    {
      title: { id: 'nav.routes.betslip', defaultMessage: 'Betslip' },
      route: '/betslip/:sport/:eventId/:betType/:isExchange?',
      component: BetslipView,
      needsAuth: true,
    },
    {
      title: { id: 'nav.routes.ActivePositionView', defaultMessage: 'ActivePositionView' },
      route: '/position/:sport/:eventId',
      component: ActivePositionView,
      needsAuth: true,
    },
    {
      title: { id: 'nav.routes.orders', defaultMessage: 'Individual Order Page' },
      route: '/orders/:orderId',
      component: XenaOrderPage,
      needsAuth: true,
      //menu: true
    },
    {
      title: { id: 'nav.routes.history.statement', defaultMessage: 'My Orders' },
      route: '/orders',
      component: HistoryOrders,
      needsAuth: true,
      menu: true,
      hash: ['base', 'settings', 'history', 'orders', 'lastQuery'],
    },
    {
      title: { id: 'nav.routes.history.positions', defaultMessage: 'Current Positions' },
      route: '/positions',
      component: PositionsPage,
      needsAuth: true,
      hash: ['base', 'settings', 'history', 'orders', 'lastQuery'],
    },
    {
      title: { id: 'nav.routes.mobileMenu', defaultMessage: 'Dashboard' },
      route: '/dashboard/:dashboardOption?',
      component: Dashboard,
      needsAuth: true,
      menu: true,
    },
    {
      title: { id: 'nav.routes.history', defaultMessage: 'History' },
      menu: false,
      needsAuth: true,
      items: [
        {
          title: { id: 'nav.routes.history.orders', defaultMessage: 'My Orders' },
          route: '/history/orders',
          component: HistoryOrders,
          needsAuth: true,
          menu: true,
          hash: ['base', 'settings', 'history', 'orders', 'lastQuery'],
        },
      ],
    },
  ],
  userMenuItems: [
    {
      title: { id: 'nav.routes.logout', defaultMessage: 'Logout' },
      triggerAction: 'logout',
      menu: true,
    },
  ],
  notAuthenticated: '/login',
  default: '/trade',
  admin: '/admin',
  basename: '', //the react router basename
};

export default routes;
