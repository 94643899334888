/** @format */

import { actions } from '../../reducers/app';

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { withTheme } from 'styled-components';

import { SettingsContext } from '../../components/shared/SettingsContext';

const AccaWarningContainer = styled.div`
  background: ${(props) => props.theme.foreground};
  color: ${(props) => props.theme.background};
  position: fixed;
  bottom: 50px;
  right: 10%;
  left: 10%;
  z-index: 1000;
  height: 60px;
  width: 300px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    font-size: 14px;
    padding: 10px;
    text-align: center;
  }
`;

class AccaWarning extends React.PureComponent {
  static contextType = SettingsContext;

  componentDidUpdate = (_prevProps) => {
    if (this.props.error === 'acc_not_found') {
      this.clearAllParlays();
    }
  };

  clearAllParlays = () => {
    this.props.actions.closeAllParlays({
      actions: this.props.actions,
    });
  };

  render() {
    if (this.props.error === 'missing_price') {
      return (
        <AccaWarningContainer>
          <FormattedMessage
            id="trade.parlay.selectionNotAvailable"
            defaultMessage="Selection not currently available for accumulator bet"
          />
        </AccaWarningContainer>
      );
    } else if (this.props.error === 'too_many_selections') {
      return (
        <AccaWarningContainer>
          <FormattedMessage
            id="trade.parlay.cannotAddMoreSelections"
            defaultMessage="No more bets can be added to the Acca"
          />
        </AccaWarningContainer>
      );
    }
    return null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// turn state of combined reducers into state required by component
const mapStateToProps = (state, ownProps) => {
  let parlay = state.getIn(['parlays', 'parlays', ownProps.parlayId], null);

  return parlay
    ? {
        entries: parlay.get('entries', null),
        error: parlay.get('error', null),
        prices: parlay.get('prices', null),
        isPlacing: parlay.get('isPlacing', false),
        hasPlaced: parlay.get('hasPlaced', false),
        parlayCcy: parlay.get('parlayCcy', ''),
        maxStakeGbp: parlay.get('maxStakeGbp', 0),
        priceIsMaxed: parlay.get('priceIsMaxed', false),
        x: parlay.get('x', 100),
        y: parlay.get('y', 100),
        stake: parlay.get('stake', ''),
      }
    : {};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual: (next, prev) => {
    return (
      prev.getIn(['base'], null) === next.getIn(['base'], null) &&
      prev.getIn(['betbar'], null) === next.getIn(['betbar'], null)
    );
  },
})(injectIntl(withTheme(AccaWarning)));
