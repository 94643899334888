/** @format */

import React from 'react';
import styled from 'styled-components';

import loader from '../../media/loading-circle.svg';

const GenericLoader = styled.div`
  justify-content: center;
  margin: 2em auto;
  width: 100%;
  display: flex;

  img {
    width: 3em;
    height: 3em;
  }
`;

export default () => (
  <GenericLoader>
    <img alt="loading" src={loader} />
  </GenericLoader>
);
