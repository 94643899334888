/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';
import routes from '../routes';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { XenaDiv } from '../components/shared/Common';

const sticker =
  Whitelabel.logos && Whitelabel.logos.coin && Whitelabel.logos.coin.light ? (
    <img
      alt="logo"
      className="fourOughFourLogo"
      src={`${routes.basename}${Whitelabel.logos.coin.light}`}
    />
  ) : (
    <h1 className="fourOughFourErrorCode">404</h1>
  );

const FourOughFourContainer = styled(XenaDiv)`
  display: flex;
  height: 100%;
  width: 100%;

  .displayBox {
    margin: auto;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    height: 100%;
    width: 100%;

    .errorInfo {
      width: 30em;
      padding-left: 2em;
      padding-top: 2em;
    }

    .fourOughFourLogo {
      display: none;
      height: 8em;
    }

    .fourOughFourErrorCode {
      margin: auto;
      font-size: 12em;
    }
  }
`;

const FourOughFour = () => (
  <FourOughFourContainer background={'xenaColorDark'} color={'fontColorContrast'}>
    <Link className="displayBox" to={routes.default}>
      {sticker}
      <XenaDiv background={'xenaColorDark'} color={'fontColorContrast'} className="errorInfo">
        <h1>
          <FormattedMessage id="404.pageNotFound" defaultMessage="Page not found" />
        </h1>
        <p>
          <FormattedMessage
            id="404.cantFind"
            defaultMessage="We can't find the page you've specified."
          />
        </p>
        <p>
          <FormattedMessage
            id="404.clickToReturn"
            defaultMessage="Click here to return to the Home page"
          />
        </p>
      </XenaDiv>
    </Link>
  </FourOughFourContainer>
);

export default FourOughFour;
